import { dateFormat, dateTimeFormat, loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import React, { useEffect, useState } from "react";
import "./my.raffle.orders.css";
import { Navigate, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { modalServiceSubject } from "../../services/modal.service";
import { isMobile } from "../../App";
import paths from "../../paths";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { raffleDraws, raffleDrawSubject } from "../../store/raffle.store";
import { RaffleModel } from "../../models/raffle.model";
import { openRaffleDetail } from "../../services/raffle.service";
import { auth } from "../../store/auth.store";

let OrderStatusMap: any = {
  0: {
    class: "warning",
    label: "Oynanıyor",
  },
  1: {
    class: "warning",
    label: "Beklemede",
  },
  2: {
    class: "success",
    label: "Kazanan",
  },
  3: {
    class: "danger",
    label: "Kaybetti",
  },
};
let myRaffleListPage: number = 0;
let xhrMetaData: any = {};

export function MyRaffleOrders() {
  const api = new ApiService();
  const [dataList, setDataList] = useState<any[]>([]);
  const [dataFilterTab, setDataFilterTab] = useState<any>(0);
  const [loading, setLoading] = useState<any>(true);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    loadData(0);
  }, []);

  useEffect(() => {
    loadData(0);
  }, [pathname, dataFilterTab]);

  const loadData = async (forcePage: any = null) => {
    loadingAnimation(true);
    setLoading(true);

    let pageN = forcePage !== null ? forcePage : myRaffleListPage;
    if (pageN === 0 && dataList && dataList.length > 0) {
      setDataList([]);
    }

    let payload: any = {
      page: pageN,
      status: dataFilterTab === 0 ? "0,1" : dataFilterTab,
      type: "get",
    };

    return await api
      .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true)
      .then((res: any) => {
        // console.log(res)
        loadingAnimation(false);
        setLoading(false);
        let tmp = [];
        if (res && res.status) {
          if (pageN === 0 && dataList && dataList.length > 0) {
            dataList.splice(0, dataList.length);
            tmp.push(...res.data);
          } else {
            if (dataList === null) {
              tmp.push(...res.data);
            } else {
              tmp = [...dataList];
              tmp.push(...res.data);
            }
          }
        }
        if (res && res.metadata) {
          xhrMetaData = res.metadata;
          let id_datalist_loadmore: any = document.getElementById("id_datalist_loadmore");
          if (res.metadata.count === 0 || res.metadata.count <= tmp.length) {
            id_datalist_loadmore.style.display = "none";
          } else {
            id_datalist_loadmore.style.display = "unset";
          }
        }
        setDataList(tmp);
        forceUpdate();
      });
  };

  const loadMore = (e: any) => {
    e.target.disabled = true;
    loadData().finally(() => {
      e.target.disabled = false;
    });
  };

  const showRaffleTickets = (el: any) => {
    let payload: any = {
      draw_id: String(el.draw_id),
      type: "get_draw",
    };

    api.start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true).then((res: any) => {
      if (res && res.status) {
        const groupByCategory = res.data.reduce((group: any, product: any) => {
          const { create_date } = product;
          group[create_date.substring(0, 16)] = group[create_date.substring(0, 16)] ?? [];
          group[create_date.substring(0, 16)].push(product);
          return group;
        }, {});

        modalServiceSubject.next({
          title: `Biletlerim - ${el.draw.title_abbreviated}`,
          content: `<p>
              <div class="row mx-0">
                  ${Object.keys(groupByCategory)
                    .map((itm: any) => {
                      return `<div class="container-fluid border rounded mt-2">
                <div class="row group-header">
                Alım Zamanı: ${dateTimeFormat(itm)}
                </div>
                <div class="row m-2">
                ${groupByCategory[itm]
                  .map((c: any) => {
                    return `<div class= "col-3 p-1 text-center"> <div class="border  rounded-2 ${
                      c.status === 2
                        ? "bg-success-subtle  border-success"
                        : c.status === 3
                        ? "bg-danger-subtle border-danger"
                        : "bg-secondary-subtle border-secondary"
                    }" >${c.approve_code} </div></div > `;
                  })
                  .join("")}</div>
                
                </div>`;
                    })
                    .join("")}
              </div>
            </p>`,
          confirm: {
            cancel: {
              label: "Kapat",
              class: "btn btn-secondary w-100",
            },
          },
        });
      }
    });
  };

  if (!auth.member?.id) {
    return <Navigate to={paths.login.url} />;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className={"draw-filter-wrapper"}>
            {!isMobile && (
              <div className="float-start p-3">
                <h4>
                  Çekiliş filtreleri
                  <span>
                    {raffleDraws.length} çekiliş, {dataList?.length} adet gösterilen.
                  </span>
                </h4>
              </div>
            )}
            <div className="float-lg-end">
              <button
                className={`btn btn-sm me-1 btn-outline-primary  ${dataFilterTab === 0 && "active"}`}
                onClick={() => {
                  setDataFilterTab(0);
                }}
              >
                Bekleyen
              </button>
              <button
                className={`btn btn-sm me-1 btn-outline-primary  ${dataFilterTab === 2 && "active"} `}
                onClick={() => {
                  setDataFilterTab(2);
                }}
              >
                Kazanan
              </button>
              <button
                className={`btn btn-sm me-1 btn-outline-primary ${dataFilterTab === 3 && "active"} `}
                onClick={() => {
                  setDataFilterTab(3);
                }}
              >
                Kaybeden
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={` my-raffle-order-item px-1 px-lg-0`}>
        {dataList.map((el: any, key: number) => {
          if (Number(el.lost_count) + Number(el.won_count) === 0) {
            el.status = 1;
          } else if (Number(el.won_count) > 0) {
            el.status = 2;
          } else if (Number(el.pending_count) > 0) {
            el.status = 0;
          } else if (Number(el.lost_count) > 0) {
            el.status = 3;
          }
          return !isMobile ? (
            <div key={`item-my-raffle-order-${key}`} className="row item p-2 m-1 mt-3">
              {el.draw && (
                <>
                  <div className="col-3 border-0">
                    <img className="img-thumbnail" src={el.draw?.photo} />
                  </div>
                  <div className="col-3 d-flex flex-column justify-content-center">
                    <h3>{el.draw?.title_abbreviated}</h3>
                    <span className="d-block">Çekiliş Tarihi: {dateFormat(el.draw?.draw_date)}</span>
                    <span className="d-block">Çekiliş No: {el.draw.no}</span>
                    <span
                      className="d-block link-primary cursor-pointer mt-4"
                      onClick={() => {
                        let draw: any = raffleDraws.find((x: RaffleModel) => x.no === el.draw.no);
                        if (draw) {
                          openRaffleDetail(draw);
                        } else {
                          el.draw.detail_photo = el.draw.detail_photo.replace("/media/", "");
                          el.status = el.status === true ? 1 : 0;
                          raffleDrawSubject.next({
                            action: "history",
                            draw: new RaffleModel(el.draw),
                          });
                        }
                      }}
                    >
                      Çekiliş Detayları
                    </span>
                  </div>
                  <div className="col-3 d-flex flex-column justify-content-center">
                    {/*<h3>{el.name}</h3>*/}
                    <span className="d-block">Bilet ücreti: {Number(el.draw.ticket_price).toFixed(2)} ₺</span>
                    <span className="d-block">
                      Alınan bilet:
                      {el.status === 0 ? (
                        <> Satın alma devam ediyor</>
                      ) : (
                        <> {el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count} adet</>
                      )}
                    </span>
                    <span className="d-block fw-bold mt-2">
                      Toplam Tutar:
                      {el.status === 0 ? (
                        <> Satın alma devam ediyor</>
                      ) : (
                        <>
                          {" "}
                          {(
                            Number(el.draw.ticket_price) *
                            Number(el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count)
                          ).toFixed(2)}
                          ₺
                        </>
                      )}
                    </span>
                    <span
                      className="d-block link-primary cursor-pointer mt-4"
                      onClick={() => {
                        showRaffleTickets(el);
                      }}
                    >
                      Biletlerimi Gör
                    </span>
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <div className={`status ${OrderStatusMap[el.status].class}`}>{OrderStatusMap[el.status].label}</div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div key={`item-my-raffle-order-${key}`} className="row item ">
              {el.draw && (
                <>
                  <div className="col-4 p-2 gap-2  d-flex flex-column align-items-center justify-content-center">
                    <img className="img-thumbnail row-auto" src={el.draw?.photo} />
                    <span
                      className="d-block link-primary pointer text-center"
                      onClick={() => {
                        let draw: any = raffleDraws.find((x: RaffleModel) => x.no === el.draw.no);
                        if (draw) {
                          openRaffleDetail(draw);
                        } else {
                          el.draw.detail_photo = el.draw.detail_photo.replace("/media/", "");
                          el.status = el.status === true ? 1 : 0;
                          raffleDrawSubject.next({
                            action: "history",
                            draw: new RaffleModel(el.draw),
                          });
                        }
                      }}
                    >
                      Çekiliş Detayları
                    </span>
                  </div>
                  <div className="col-5 p-2">
                    <h3 className="m-0 p-0  text-nowrap text-truncate">{el.draw?.title_abbreviated}</h3>
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Çekiliş Tarihi:
                      <span style={{ color: "blue" }}>{dateFormat(el.draw?.draw_date)}</span>
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Çekiliş No:
                      <span style={{ color: "blue" }}>{el.draw.no}</span>
                    </span>
                    <div className="raffle-order-mobile">
                      <span className="d-block" style={{ fontSize: "10px" }}>
                        Bilet ücreti: {Number(el.draw.ticket_price).toFixed(2)}₺
                      </span>
                      <span className="d-block" style={{ fontSize: "10px" }}>
                        Alınan bilet:
                        {el.status === 0 ? <>Satın alma devam ediyor</> : <>{el.played_count} adet</>}
                      </span>
                      <span className="d-block fw-bold" style={{ fontSize: "10px" }}>
                        Toplam Tutar:{" "}
                        {el.status === 0 ? (
                          <>Satın alma devam ediyor</>
                        ) : (
                          <>{(Number(el.draw.ticket_price) * Number(el.played_count)).toFixed(2)}₺</>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center p-1 flex-column">
                    <div className={`status ${OrderStatusMap[el.status].class} p-1`}>
                      <p className="m-1" style={{ fontSize: "10px" }}>
                        {OrderStatusMap[el.status].label}
                      </p>
                    </div>
                    <span
                      className="d-block link-primary pointer text-center mt-2"
                      onClick={() => {
                        showRaffleTickets(el);
                      }}
                    >
                      Biletlerimi Gör
                    </span>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {dataList && dataList.length === 0 && !loading && (
        <div className={"bg-white py-4 mt-2 mt-lg-0"} style={{ borderRadius: "10px" }}>
          {dataFilterTab === 0 && (
            <div className="text-center py-5">Henüz çekilişe katılmamışsınız 🤨 Hemen alın, şansınızı deneyin.</div>
          )}
          {dataFilterTab === 2 && (
            <div className="text-center py-5">Henüz kazanan biletiniz yok. Hemen alın, şansınızı deneyin.</div>
          )}
          {dataFilterTab === 3 && (
            <div className="text-center py-5">Henüz kaybeden biletiniz yok. Hemen alın, şansınızı deneyin.</div>
          )}
          <div className={"d-flex justify-content-center"}>
            <button
              className="mx-auto btn btn-primary w-50 mt-5"
              onClick={() => {
                navigate(`${paths.raffles.url}/cekilisler`);
              }}
              id="btn_submit"
            >
              Çekilişler
            </button>
          </div>
        </div>
      )}

      <div className="text-center">
        <div id="id_loaderAnimation" className="loader"></div>
        <button
          id="id_datalist_loadmore"
          onClick={loadMore}
          type="button"
          className="btn rounded-pill btn-outline-primary"
        >
          Devamını Yükle
        </button>
      </div>
    </>
  );
}
