import {useNavigate, useParams} from "react-router";
import {agreements} from "../../constants/global.vars";
import paths from "../../paths";
import React, {useEffect} from "react";
import {agreementSubject} from "../../store/misc.store";
import {useForceUpdate} from "../../services/misc.functions";
import "./agreement.css";
import {isMobile} from "../../App";
import {Link, useLocation} from "react-router-dom";

export function Agreement() {

    const param = useParams();
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const {pathname} = useLocation();


    useEffect(() => {
        let agreementSubject_subscriber = agreementSubject.subscribe(() => {
            forceUpdate();
        });
        return () => {
            agreementSubject_subscriber.unsubscribe();
        }

    }, [])

    if (!param.slug || !agreements[param.slug]) {

        return <></>
    }

    const content = agreements[param.slug]

    return <div className={`page-widget dark  ${isMobile ? ' ' : 'p-4'}`}>
        <div className="row">
            {!isMobile && <div className="col-3 pt-3">

                <div className={`page-widget side-menu mt-4 py-2 ${isMobile ? 'liner-tabs' : ''}`}>
                    <ul>
                        {agreements && Object.entries(agreements).map((el: any) => {
                            return <li key={el[0]} className={String(pathname).includes(el[0]) ? 'active' : ''}>
                                <Link to={`${paths.agreement.url}/${el[0]}/`} className="fw-bold">
                                    {el[1].title}
                                </Link>
                            </li>
                        })}
                    </ul>
                </div>

            </div>}

            <div className="col-12 col-lg-9">
                <h1 className={`${isMobile ? 'py-2 ' : ''}`}>{content.title}</h1>
                <div className="page-widget agreement-page">
                    <div className="p-3">
                        <p dangerouslySetInnerHTML={{__html: content.content.replace(/\n\r/g, "").replace(/\r\n/g, "")}}></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

}
