import "./deposit.css";
import React, {useEffect, useState} from "react";
import {env} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import bankIcon from "../../assets/icons/bank.svg";
import copyIcon from "../../assets/feather/copy.svg";
import {auth} from "../../store/auth.store";
import iconCheck from "../../assets/icons/check-star-circle.png";
import {copyMemberNumber} from "../../services/misc.functions";
import {isMobile} from "../../App";
import Modal from "react-modal";

export default function Deposit() {
    const [bankAccounts, setBankAccounts] = useState<any>([]);
    const [selected, setSelected] = useState<any>(null);
    const [defaultBank, setDefaultBank] = useState<any>(null);

    useEffect(() => {
        getBankAccounts();
    }, []);

    const getBankAccounts = async () => {
        const api = new ApiService();
        api
            .start("get", env.accounting_api + "/accounting/api/list-bank-account/", null, false)
            .then((res: any) => {
                setBankAccounts(res.data);
                for (const bank of res.data) {
                    if (bank.bank.code === "10") {
                        setDefaultBank(bank);
                    }
                }
            })
            .catch((e: any) => console.log(e));
    };

    return (
        <>
            <h1>Para Yatır</h1>
            <div className="page-widget">

                <div className={"p-2 p-md-4"}>


                    <div className="mt-1">
                        <h6 className="fw-bold">EFT - HAVALE İLE PARA YATIR</h6>

                        <p>
                            Tüm bankalardan 4nala.com'a para göndermek için IBAN bilgisini kullanarak kendinize ait
                            4nala.com
                            bilgilerinizle EFT, Havale ve FAST(7x24) işleminizi yapabilirsiniz.
                        </p>


                        <div className="p-1 p-md-3" style={{border: '1px solid #646BDD55', borderRadius: 15}}>

                            <div className="row" style={{borderBottom: '1px solid #646BDD55'}}>
                                <div className="col-3 p-2 col-md-2 fw-semibold fs-7">IBAN :</div>
                                <div className="col-8 p-2 col-md-10 fw-bold fs-7" onClick={() => {
                                    copyMemberNumber("TR720001002136978165555003", 'IBAN kopyalandı')
                                }}>TR72 0001 0021 3697 8165 5550 03 <img className="ms-2" width={16} alt={"copyIcon"}
                                                                         src={copyIcon}/>
                                </div>
                            </div>
                            <div className="row" style={{borderBottom: '1px solid #646BDD55'}}>
                                <div className="col-3 p-2 col-md-2 fw-semibold fs-7">ALICI :</div>
                                <div className="col-8 p-2 col-md-10 fw-bold fs-7" onClick={() => {
                                    copyMemberNumber("Joker Şans Oyunları A.Ş.", 'Alıcı adı kopyalandı')
                                }}>Joker Şans Oyunları A.Ş. <img className="ms-2" width={16} alt={"copyIcon"}
                                                                 src={copyIcon}/></div>
                            </div>
                            <div className="row">
                                <div className="col-3 p-2 col-md-2 fw-semibold fs-7">AÇIKLAMA :</div>
                                <div className="col-8 p-2 col-md-10 fw-bold fs-7" onClick={() => {
                                    copyMemberNumber(auth.member.tc_id, 'Açıklama kopyalandı')
                                }}>{auth.member.tc_id} <img className="ms-2" width={16} alt={"copyIcon"}
                                                            src={copyIcon}/></div>
                            </div>

                        </div>
                    </div>


                    <p className="my-3 alert alert-warning fs-7 mb-4">
                        Aşağıdaki adımları takip ederek EFT ve FAST ile para transferini gerçekleştirebilirsiniz.
                        <br/><br/>
                        Banka Bilgisi: Ziraat Bankası<br/>
                        IBAN: TR720001002136978165555003<br/>
                        Alıcı İsmi: ”Joker Şans Oyunları Anonim Şirketi” yazılması gerekmektedir.<br/>
                        Açıklama alanına "11 haneli TC Kimlik Numaranızı" yazmanız gerekmektedir.<br/>
                    </p>

                    <h6 className="fw-bold">ANLAŞMALI BANKALAR</h6>
                    <p>
                        Anlaşmalı bankaların, mobil bankacılık, ATM kartlı işlem, ATM kartsız işlem ve telefon
                        bankacılığı ile
                        4nala.com hesabınıza üye numaranızı kullanarak şans oyunu ödemeleri kanalı ile bakiye
                        yükleyebilirsiniz.
                    </p>

                    <div className="p-1 p-md-3 mb-3" style={{border: '1px solid #646BDD55', borderRadius: 15}}>
                        <div className="row">
                            <div className="col-3 p-2 col-md-2 fw-semibold fs-7">ÜYE NO :</div>
                            <div className="col-8 p-2 col-md-10 fw-bold fs-7" onClick={() => {
                                copyMemberNumber(auth.member.id, 'Üye numarası kopyalandı')
                            }}>{auth.member.id} <img className="ms-2" width={16} alt={"copyIcon"} src={copyIcon}/></div>
                        </div>
                    </div>

                    {!isMobile && <table className="table table-bordered rounded">
                        <thead>
                        <tr>
                            {!isMobile && <th className={"text-center"}></th>}
                            <th className={"text-center"}>Banka Adı</th>
                            <th className={"text-center"}>Mobil Bankacılık</th>
                            <th className={"text-center"}>ATM Kartlı İşlem</th>
                            {/*<th className={"text-center"}>ATM Kartsız İşlem</th>*/}
                            <th className={"text-center"}>Telefon Bankacılığı</th>
                        </tr>
                        </thead>


                        <tbody>

                        {bankAccounts.map((bank: any) => {
                            return <tr key={"banklist-"+bank.id}>
                                {!isMobile && <td className={"text-center align-middle p-0"}>
                                    <img alt={"bank"} width={75}
                                         src={bank.bank.logo !== "" ? `${env.cdn_host}${bank.bank.logo}` : bankIcon}/>
                                </td>}
                                <td className={"fw-bold align-middle"}>
                                    {bank.bank.name}
                                </td>
                                <td className={"text-center"}>
                                    <img alt={"iconCheck"} width={30} src={iconCheck}/>
                                </td>
                                <td className={"text-center"}>
                                    <img alt={"iconCheck"} width={30} src={iconCheck}/>
                                </td>
                                {/*<td className={"text-center"}>*/}
                                {/*    <img alt={"iconCheck"} width={30} src={iconCheck}/>*/}
                                {/*</td>*/}
                                <td className={"text-center"}>
                                    <img alt={"iconCheck"} width={30} src={iconCheck}/>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>}


                    {isMobile && <div className="row">
                        {bankAccounts.map((bank: any) => {
                            return <div key={"bank-" + bank.id} className="col-6" onClick={() => {
                                setSelected(bank);
                            }}>
                                <div className={"align-items-center d-flex justify-content-center mt-1"}
                                     style={{
                                         border: '1px solid #646BDD55',
                                         textAlign: 'center',
                                         height: 60,
                                         borderRadius: 15,
                                         width: '100%'
                                     }}>
                                    <img alt={"bank"} style={{maxHeight: 50, maxWidth: '95%'}}
                                         src={bank.bank.logo !== "" ? `${env.cdn_host}${bank.bank.logo}` : bankIcon}/>
                                </div>
                            </div>;
                        })}
                    </div>}

                </div>

            </div>


            <Modal
                isOpen={selected}
                onRequestClose={() => {
                    setSelected(null);
                }}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        maxWidth: 'calc(100vw - 80px)',
                        width: '460px',
                        maxHeight: 'calc(100vh - 100px)',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '1rem',
                    }, overlay: {
                        zIndex: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        backdropFilter: 'blur(10px)',
                    }
                }}
                contentLabel="">

                {selected && <>
                    <h3>{selected.bank.name}</h3>
                    <p>Bankaya ait şans oyunu ödemesi yolu ile hesabınıza bakiye yükleyebileceğiniz ödeme kanalları
                        aşağıda listelenmiştir. </p>
                    <table className="table table-bordered rounded">
                        <tbody>
                        <tr>
                            <th className={"text-left"}>Mobil Bankacılık</th>
                            <td className={"text-center"}>
                                <img alt={"iconCheck"} width={30} src={iconCheck}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={"text-left"}>ATM Kartlı İşlem</th>
                            <td className={"text-center"}>
                                <img alt={"iconCheck"} width={30} src={iconCheck}/>
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <th className={"text-left"}>ATM Kartsız İşlem</th>*/}
                        {/*    <td className={"text-center"}>*/}
                        {/*        <img alt={"iconCheck"} width={30} src={iconCheck}/>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <th className={"text-left"}>Telefon Bankacılığı</th>
                            <td className={"text-center"}><img alt={"iconCheck"} width={30} src={iconCheck}/></td>
                        </tr>

                        </tbody>
                    </table>
                </>}

            </Modal>

        </>
    );
}
