import { MemberMenu } from "./components/member.menu";
import {isMobile} from "../../App";
import {auth, authSubject} from "../../store/auth.store";
import {useNavigate} from "react-router";
import paths from "../../paths";
import {useForceUpdate} from "../../services/misc.functions";
import {useEffect} from "react";

export function MemberWrapper({ children }: any) {

  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe((res: any) => {
      forceUpdate();
    })
    return () => {
      auth_subscriber.unsubscribe();
    }
  }, [])

  if(!auth.member || !auth.member.id ){
    navigate(paths.login.url);
    return <></>
  }

  return (
    <div className={"page-widget member-wrapper dark p-4"}>

      <div className="row">
        {!isMobile &&
            <div className="col-lg-3 pt-3">
              <div className="page-widget mt-4">
                <MemberMenu/>
              </div>
            </div>
        }
        <div className="col-lg-9 p-3 p-lg-4 px-lg-3 py-lg-0">
          {children}
        </div>
      </div>
    </div>
  );
}
