import React, { useEffect } from "react";
import { raffleDraws } from "../../store/raffle.store";
import { loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import { getRaffleDraws } from "../../services/raffle.service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Ticket from "../raffles/widgets/ticket";
import paths from "../../paths";
import { useNavigate } from "react-router-dom";
import cekilis from "../../assets/icons/cekilis.svg";
import oyunlar from "../../assets/icons/oyunlar.svg";

export default function HomeRaffles() {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    if (raffleDraws?.length === 0) {
      loadingAnimation(true);
      getRaffleDraws().then(() => {
        loadingAnimation(false);
        forceUpdate();
      });
    }

    return () => {};
  }, []);

  return (
    <div className={"col-12"}>
      <div className={"home-raffle-slider"}>
        <div className={"d-flex justify-content-between align-items-center px-3 py-3"}>
          <div className={"d-flex"}>
            <img src={"../../assets/icons/esans-icon.png"} width={21} height={21} style={{ marginRight: "5px" }} />
            <h4 style={{ fontSize: "16px" }} className={"mb-0"}>
              ŞANS OYUNLARI
            </h4>
          </div>
          <div
            className={"text-decoration-underline"}
            onClick={() => {
              navigate(paths.games.url);
            }}
          >
            Tümünü Gör
          </div>
        </div>
        <div className={"row px-3 pb-3"}>
          <div
            className={"col-6"}
            onClick={() => {
              navigate(paths.raffles.url);
            }}
          >
            <div className="home-raffle-tab-item border">
              <img alt={"program"} src={cekilis} style={{ opacity: "1" }} />
              <div style={{ opacity: "1" }}>
                <h2>
                  EŞYA
                  <br />
                  PİYANGOSU
                </h2>
              </div>
            </div>
          </div>
          <div
            className={"col-6"}
            onClick={() => {
              navigate(paths.games.url);
            }}
          >
            <div className="home-raffle-tab-item border">
              <img alt={"program"} src={oyunlar} />
              {/*<span className={"raffle-tab-item-badge"}>Yakında</span>*/}
              <div>
                <h2>
                  DİJİTAL
                  <br />
                  OYUNLAR
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/*<Swiper
                spaceBetween={0}
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    948: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1400: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1900: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                }}
                navigation
                modules={[Navigation]}
            >
                {raffleDraws.map((item: any, key: number) => {
                    return (
                        <SwiperSlide key={`home-popular-raffles-${item.id}-${key}`}>
                            <Ticket
                                item={item}
                                maskColor={"#BBE2EE"}
                                showBuyButton={false}
                            ></Ticket>
                        </SwiperSlide>
                    );
                })}
            </Swiper>*/}

        {/*<div className={"home-raffle-footer"} onClick={()=>{*/}
        {/*    navigate(paths.raffles.url)*/}
        {/*}}>*/}
        {/*    <p>Tümünü Gör</p>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
