import React from "react";
import { env } from "../../constants/global.vars";

export function ProductDetail(props: any) {
  const product = props.product;
  // console.log(product)

  return (
    <>
      <div className="card product-detail-widget">
        <div className="card-header">
          <h2>{product.name}</h2>
        </div>
        <div className="card-body">
          <img className="picture" src={env.cdn_host + "/media/" + product.ProductPicture} alt={product.code} />

          <div
            className="info"
            dangerouslySetInnerHTML={{ __html: product.description.replaceAll("\n", "<br/>") }}
          ></div>
        </div>
      </div>
    </>
  );
}
