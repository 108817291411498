import { auth } from "../../../store/auth.store";
import { sendEmailValidationLink } from "../../../services/auth.service";
import iconCheck from "../../../assets/icons/check-star-circle.png";
import iconCopy from "../../../assets/feather/copy.svg";
import iconDeposit from "../../../assets/feather/download.svg";
import iconWithdraw from "../../../assets/feather/upload.svg";
import iconNotifications from "../../../assets/feather/bell.svg";
import iconTransactions from "../../../assets/feather/list.svg";
import iconPocket from "../../../assets/feather/server.svg";
import iconTickets from "../../../assets/feather/clipboard.svg";
import iconPassword from "../../../assets/feather/lock.svg";
import iconMember from "../../../assets/feather/user.svg";
import iconPhone from "../../../assets/feather/phone.svg"
import { copyMemberNumber } from "../../../services/misc.functions";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../../paths";

export function MemberMenu() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <ul className="member-menu pb-2">
      <li>
        <div className="head">
          <div className="row bg-dashed">
            <div className="col member-info">
              <div className="p-3">
                <span className="d-block fw-bold fs-5 align-items-center text-nowrap">
                  {auth.member.getFullName()}{" "}
                  {auth.member.email_validation !== false && <img alt={"onaylı"} src={iconCheck} width={28} />}
                </span>
                <span className="d-block">{auth.member.email}</span>
                <span
                  className="d-inline-block"
                  onClick={() => copyMemberNumber(auth.member.id, "Üye numaranız kopyalandı!")}
                >
                  Üye Numarası: <span className="fw-bold">{auth.member.id}</span> <img alt={"kopyala"} src={iconCopy} width={14} />{" "}
                </span>
              </div>
            </div>
           {/* <div className="col-auto text-end">
              <div className="p-3">
                {auth.member.email_validation === false && (
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      sendEmailValidationLink();
                    }}
                  >
                    Doğrula
                  </button>
                )}
              </div>
            </div>*/}
          </div>

          <div className="row">
            <div className="col text-center">
              <div className="p-3">
                <span className="d-block">TL Bakiye</span>
                <span className="d-block fw-bold">{auth.member.balance_debit}₺</span>
              </div>
            </div>
            <div className="col text-center">
              <div className="p-3">
                <span className="d-block">Puan Bakiye</span>
                <span className="d-block fw-bold">{auth.member.balance_point}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className={pathname.includes(paths.memberInfo.url) ? "active" : ""}>
        <Link to={paths.memberInfo.url}>
          <img alt={"hesabım"} className="svgcolor" src={iconMember} /> HESABIM
        </Link>
      </li>
      <li className={pathname.includes(paths.notificationInfo.url) ? "active" : ""}>
        <Link to={paths.notificationInfo.url}>
          <img alt={"bildirimler"} className="svgcolor" src={iconPhone} />
          BİLDİRİM TERCİHLERİ
        </Link>
      </li> <li className={pathname.includes(paths.memberTickets.url) ? "active" : ""}>
        <Link to={paths.memberTickets.url}>
          <img alt={"biletlerim"} className="svgcolor" src={iconTickets} />
          BİLETLERİM
        </Link>
      </li>

      <li className={pathname.includes(paths.deposit.url) ? "active" : ""}>
        <Link to={paths.deposit.url}>
          <img alt={"para yatır"} className="svgcolor" src={iconDeposit} />
          PARA YATIR
        </Link>
      </li>
      <li className={pathname.includes(paths.withdraw.url) ? "active" : ""}>
        <Link to={paths.withdraw.url}>
          <img alt={"para çek"} className="svgcolor" src={iconWithdraw} />
          PARA ÇEK
        </Link>
      </li>

      <li className={pathname.includes(paths.changePassword.url) ? "active" : ""}>
        <Link to={paths.changePassword.url}>
          <img alt={"şifre"} className="svgcolor" src={iconPassword} />
          ŞİFRE DEĞİŞTİR
        </Link>
      </li>

      <li className={pathname.includes(paths.memberBankAccounts.url) ? "active" : ""}>
        <Link to={paths.memberBankAccounts.url}>
          <img alt={"banka"} className="svgcolor" src={iconPocket} />
          BANKA HESAPLARIM
        </Link>
      </li>
      <li className={pathname.includes(paths.memberTransactions.url) ? "active" : ""}>
        <Link to={paths.memberTransactions.url}>
          <img alt={"hareketlerim"} className="svgcolor" src={iconTransactions} />
          HESAP HAREKETLERİ
        </Link>
      </li>
    </ul>
  );
}
