import "./member.css";
import { SubmitHandler, useForm } from "react-hook-form";
import EyeIcon from "../../assets/feather/eye.svg";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { toast } from "react-toastify";
import { isMobile } from "../../App";
import { userLogout } from "../../services/auth.service";
import paths from "../../paths";
import { useNavigate } from "react-router";
import React from "react";

export function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    const api = new ApiService();

    if (payload.new_password === payload.new_password2) {
      api
        .start("post", env.accounting_api + "/member/api/change-password/", payload, true)
        .then((res: any) => {
          if (res && res.status) {
            toast.success(res.message);
            userLogout();
            navigate(paths.login.url);
          } else {
            toast.error(res.message);
          }
        })
        .catch((e: any) => {
          toast.error(e.message);
          // exceptionModal(e);
        });
    } else {
      toast.error("Belirlediğiniz şifreler eşleşmiyor.");
    }
  };

  const isPasswordVisible = (inputId: any, iconId: any) => {
    let password: any = document.getElementById(inputId);
    let eyeIcon: any = document.getElementById(iconId);

    if (password.type === "password") {
      password.type = "text";
      eyeIcon.src = EyeIcon;
    } else {
      password.type = "password";
      eyeIcon.src = EyeOffIcon;
    }
  };

  return (
    <>
      <h1>Şifre Değiştirme</h1>
      <div className="page-widget">
        <div className="p-3">
          {/*<div className="row">*/}
          {/*  <span>Şifreniz en az 8 karakterden oluşmalı ve aşağıdaki öğeleri içermelidir:</span>*/}

          {/*  <ul>*/}
          {/*    <li>En az bir büyük harf</li>*/}
          {/*    <li>En az bir küçük harf</li>*/}
          {/*    <li>En az bir rakam</li>*/}
          {/*    <li>En az bir özel karakter (!, @, #, vb.)</li>*/}
          {/*  </ul>*/}

          {/*  <span>*/}
          {/*  Şifrenizi oluştururken kişisel bilgilerinizden kaçının ve düzenli aralıklarla güncelleyin. Güvenliğiniz için*/}
          {/*  önemlidir.*/}
          {/*</span>*/}
          {/*</div>*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className={"p-0"}>Şifre Değişikliği</h2>

            <p className="white-space-normal">
              Şifreniz en az 8 karakter, en fazla 20 karakterden oluşmalı ve aşağıdaki öğeleri içermelidir:
              <ul>
                <li>En az bir büyük harf</li>
                <li>En az bir küçük harf</li>
                <li>En az bir rakam</li>
                <li>En az bir özel karakter (!, @, #, vb.)</li>
              </ul>
            </p>

            <div className="row mb-3 justify-content-center">
              <div className="col-lg-6 col-sm-12">
                <label htmlFor="is_current_password" className="form-label">
                  Mevcut Şifreniz
                </label>
                <input
                  type="password"
                  {...register<any>("current_password", { required: true })}
                  className="form-control"
                  id="is_current_password"
                />
                <div
                  className="cursor-pointer password-eye float-right mr-2"
                  onClick={() => isPasswordVisible("is_current_password", "current-eye-icon")}
                >
                  <img alt={"göster"} id="current-eye-icon" height={16} width={16} src={EyeOffIcon} />
                </div>
                {errors.current_password && <div className="form-text text-danger">Mevcut şifrenizi giriniz.</div>}
              </div>
            </div>
            <div className="row mb-3 justify-content-center">
              <div className={`col-lg-6 col-sm-12`}>
                <label htmlFor="is_new_password" className="form-label">
                  Yeni Şifreniz
                </label>
                <input
                  type="password"
                  {...register<any>("new_password", { required: true })}
                  className="form-control"
                  id="is_new_password"
                />
                <div
                  className="cursor-pointer password-eye float-right mr-2"
                  onClick={() => isPasswordVisible("is_new_password", "new-eye-icon")}
                >
                  <img id="new-eye-icon" height={16} width={16} src={EyeOffIcon} alt="i" />
                </div>
                {errors.new_password && <div className="form-text text-danger">Yeni şifrenizi belirleyiniz.</div>}
              </div>
            </div>
            <div className={"row mb-3 justify-content-center"}>
              <div className="col-lg-6 col-sm-12">
                <label htmlFor="is_new_password2" className="form-label">
                  Yeni Şifreniz (Tekrar)
                </label>
                <input
                  type="password"
                  {...register<any>("new_password2", { required: true })}
                  className="form-control"
                  id="is_new_password2"
                />
                <div
                  className="cursor-pointer password-eye float-right mr-2"
                  onClick={() => isPasswordVisible("is_new_password2", "new-eye-icon2")}
                >
                  <img alt={"göster"} id="new-eye-icon2" height={16} width={16} src={EyeOffIcon} />
                </div>
                {errors.new_password2 && <div className="form-text text-danger">Yeni şifrenizi tekrar girin.</div>}
              </div>
            </div>

            <div className={"row mb-3 justify-content-center"}>
              <div className="col-lg-6 col-sm-12">
                <button className="btn btn-warning w-100" id="btn_submit">
                  Güncelle
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
