import DatePicker, {registerLocale} from "react-datepicker";
import React, {useEffect, useState} from "react";
import {tr} from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import {programUrlPrefix} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {courseColors, DailyRaces, racesActionSubject} from "../../store/bulletin.store";
import iconInfo from "../../assets/feather/info.svg";
import iconClose from "../../assets/feather/x-circle.svg";
import iconCheck from "../../assets/feather/check-circle.svg";
import {getPrettyFormatJewelry, getPrettyFormatLastSix} from "../../services/bulletin.service";
import jokeyIcon from "../../assets/img/jokey.png";
import Modal from "react-modal";
import {CurrencyFormat, ServerDate, useForceUpdate} from "../../services/misc.functions";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {isMobile} from "../../App";
import {GetStablemateIcon} from "../betting/components/betting-datatable";


registerLocale('tr', tr);


export function Program() {

    const params = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [resultsData, setResultsData] = useState<any>([]);

    const [hippodrome, setHippodrome] = useState<any>(null);
    const [shownRun, setShownRun] = useState<any>(null);
    const [showRunInfo, setShowRunInfo] = useState<any>(false);
    const forceUpdate = useForceUpdate();


    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });

    useEffect(() => {
        let api = new ApiService();
        api.start('get', `${programUrlPrefix}/${String(startDate.getDate()).padStart(2, '0')}${String(startDate.getMonth() + 1).padStart(2, '0')}${startDate.getFullYear()}/`, null, true).then(
            (result: any) => {
                if (result.status) {
                    let hip: any = null;
                    if (params.hippodrome) {
                        hip = result.data.find((h: any) => h.keyword.toLowerCase() === params.hippodrome)
                        if (hip) {
                            setHippodrome(hip);
                        } else {
                            hip = result.data[0]
                            setHippodrome(result.data[0]);
                        }
                    } else {
                        hip = result.data[0]
                        setHippodrome(result.data[0]);
                    }

                    setResultsData(result.data);

                    if (result.data && result.data.length > 0 && result.data[0].runs) {

                        if (params.run && hip) {

                            let rn = hip.runs.find((r: any) => Number(r.no) === Number(params.run))
                            var bultenData = DailyRaces.find((h: any) => h.keyword === hip.keyword);

                            if (rn) {
                                if (bultenData != null) {
                                    for (const hors of bultenData.runsMap[rn.no].horses) {
                                        for (const hor of rn.horses) {
                                            if (hor.stablemate === undefined || hor.stablemate === null) {
                                                if (hors.no === hor.no) {
                                                    hor.stablemate = hors.stablemate
                                                }
                                            }
                                        }
                                    }
                                }
                                setShownRun(rn)
                            } else {
                                if (bultenData != null) {
                                    for (const hors of bultenData.runsMap[hip.runs[0].no].horses) {
                                        for (const hor of hip.runs[0].horses) {
                                            if (hor.stablemate === undefined || hor.stablemate === null) {
                                                if (hors.no === hor.no) {
                                                    hor.stablemate = hors.stablemate
                                                }
                                            }
                                        }
                                    }
                                }
                                setShownRun(hip.runs[0]);
                            }
                        } else {
                            var bultenData = DailyRaces.find((h: any) => h.keyword === result.data[0].keyword);
                            if (bultenData != null) {
                                for (const hors of bultenData.runsMap[result.data[0].runs[0].no].horses) {
                                    for (const hor of result.data[0].runs[0].horses) {
                                        if (hor.stablemate === undefined || hor.stablemate === null) {
                                            if (hors.no === hor.no) {
                                                hor.stablemate = hors.stablemate
                                            }
                                        }

                                    }
                                }
                            }
                            setShownRun(result.data[0].runs[0]);
                        }

                    } else {
                        setShownRun(null);
                    }

                }

            }
        )
    }, [startDate]);


    const changeHippodrome = (hippodrome: any) => {
        setHippodrome(hippodrome);
        if (hippodrome.runs) {
            var bultenData = DailyRaces.find((h: any) => h.keyword === hippodrome.keyword);
            if (bultenData != null) {
                for (const hors of bultenData.runsMap[hippodrome.runs[0].no].horses) {
                    for (const hor of hippodrome.runs[0].horses) {
                        if (hor.stablemate === undefined || hor.stablemate === null) {
                            if (hors.no === hor.no) {
                                hor.stablemate = hors.stablemate
                            }
                        }
                    }
                }
            }
            setShownRun(hippodrome.runs[0]);

        } else {
            setShownRun(null);
        }
    }


    if (hippodrome && hippodrome.runs.length === 0 && DailyRaces.length > 0) {
        let dailyRace = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword)
        hippodrome.runs = dailyRace.runs;
        hippodrome.continuous = true;
        var bultenData = DailyRaces.find((h: any) => h.keyword === hippodrome.keyword);
        if (bultenData != null) {
            for (const hors of bultenData.runsMap[hippodrome.runs[0].no].horses) {
                for (const hor of hippodrome.runs[0].horses) {
                    if (hor.stablemate === undefined || hor.stablemate === null) {
                        if (hors.no === hor.no) {
                            hor.stablemate = hors.stablemate
                        }
                    }
                }
            }
        }
        setShownRun(hippodrome.runs[0]);
    }

    let today = ServerDate()
    if (hippodrome && hippodrome._date === `${String(today.getDate()).padStart(2, '0')}${String(today.getMonth() + 1).padStart(2, "0")}${today.getFullYear()}` && DailyRaces.length > 0) {

        let dailyRace = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword)
        if (dailyRace && hippodrome && hippodrome.runs && hippodrome.runs.length !== dailyRace.runs.length) {
            let finishedRuns = hippodrome.runs.map((r: any) => Number(r.no))
            let continuousRuns = dailyRace.runs.filter((r: any) => {
                if (!finishedRuns.includes(Number(r.no))) {
                    r.continuous = true;
                    return r
                }
            })
            hippodrome.runs = _.orderBy(hippodrome.runs.concat(continuousRuns), [(e: any) => Number(e.no)])
        }
    }


    return (<>
        <div className="bettingtype-options p-3">
            <div className="row">
                <div className="col-lg-4">
                    <label>TARİH</label>
                    <DatePicker className={"date-input"} dateFormat={"dd/MM/yyyy"} selected={startDate} locale={'tr'}
                                onChange={(date: any) => setStartDate(date)}
                                minDate={new Date("29-10-2018")}
                                maxDate={new Date(new Date(new Date().setDate(new Date().getDate() + 1)))}
                    />
                </div>
                <div className={`col-lg-4 mt-2 mt-sm-0`}>
                    <label>HİPODROM</label>
                    {hippodrome ? <select value={hippodrome.keyword} onChange={(e: any) => {
                        changeHippodrome(resultsData.find((data: any) => data.keyword === e.target.value));
                    }}>
                        {resultsData.map((data: any, key: any) => {
                            return (<option value={data.keyword}
                                            key={`available-hippodrome-${key}`}>{data.location}</option>)
                        })}
                    </select> : null}

                </div>
                <div className={`col-lg-4 mt-2 mt-sm-0`}>
                    <label>HAVA DURUMU</label>
                    {hippodrome && hippodrome.weather &&
                        <p className="input mb-0 d-flex align-items-center" style={{lineHeight: "14px"}}>
                            {hippodrome.weather.forecast}{hippodrome.weather.forecast && <br/>}
                            Nem: {hippodrome.weather.humidity}% - Sıcaklık: {hippodrome.weather.temperature}°C
                        </p>}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <div className="page-widget">

                    <svg style={{display: 'none'}}>
                        <defs>
                            <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                        </defs>
                    </svg>

                    <div className={"betting-run-tabs"} id={"program-tabs"}>
                        <ul className="nav nav-tabs">
                            {hippodrome && hippodrome.runs && hippodrome.runs.map((run: any, keyRun: number) => {
                                let columnNo = keyRun + 1;
                                return (<li key={`runItem-${keyRun}`}
                                            className={`nav-item ${shownRun === run ? 'active' : ''}`}>
                                    <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                        <use xlinkHref="#tabshape"></use>
                                    </svg>
                                    <a className="nav-link d-flex align-items-center nowrap-ellipsis" onClick={() => {
                                        var bultenData = DailyRaces.find((h: any) => h.keyword === hippodrome.keyword);
                                        if (bultenData != null) {
                                            for (const hors of bultenData.runsMap[run.no].horses) {
                                                for (const hor of run.horses) {
                                                    if (hor.stablemate === undefined || hor.stablemate === null) {
                                                        if (hors.no === hor.no) {
                                                            hor.stablemate = hors.stablemate
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        setShownRun(run)
                                        if (isMobile) {
                                            // @ts-ignore
                                            document.getElementById('program-tabs').scroll({
                                                top: 1,
                                                left: (100 / hippodrome.runs.length) * keyRun,
                                                behavior: "smooth",
                                            })
                                        }
                                    }}>


                                        {run.no}{isMobile ? shownRun.no === run.no ? '. Koşu' : '' : '. Koşu'}


                                        {run.continuous === true &&
                                            <img alt={"check"} src={iconCheck} width={14} height={14}
                                                 className={`ms-1 ${shownRun === run ? '' : 'invert-color'}`}/>}
                                    </a>
                                    <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                        <use xlinkHref="#tabshape"></use>
                                    </svg>
                                </li>)
                            })}
                        </ul>
                    </div>
                    <div className="p-3">
                        {shownRun && <div className="betting-run-info text-light"
                                          style={{backgroundColor: courseColors[shownRun.course]}}>


                            {hippodrome && hippodrome.continuous !== true && <span className="d-inline-flex float-end">
                  <img src={iconInfo} alt="i" onClick={() => setShowRunInfo(true)} className={'invert-color ms-2 mt-2'}
                       width={24} height={24}/>
              </span>}

                            <span>{shownRun.no}.Koşu</span>
                            <span>{shownRun.time}</span>
                            <span>{shownRun.course}</span>
                            <span>{shownRun.distance}m</span>
                            <span>{shownRun.group}</span>
                            {shownRun.type_detail ? <><span>{shownRun.type_detail}</span></> : null}

                            {shownRun.gender ? <><span>{shownRun.gender}</span></> : null}
                            {shownRun.gender_detail ? <><span>{shownRun.gender_detail}</span></> : null}


                            {hippodrome && hippodrome.continuous !== true ? <>
                                <br/>{shownRun.wagers}
                            </> : <>
                                <br/>{[...Array.from(new Set(shownRun.bets.map((e: any) => e.name)))].join(', ')}
                            </>}

                        </div>}


                        {hippodrome && shownRun ? <>
                            <div className={"table-responsive"}>
                                <table className="table betting-table table-borderless">
                                    <thead>
                                    <tr>
                                        <th className="text-center">No</th>
                                        <th>Atın İsmi / Yaş</th>
                                        <th className="text-center">Kilo</th>
                                        <th>Orijin</th>
                                        <th className="text-start">Jokey</th>
                                        <th className="text-start">Sah./Antr.</th>
                                        <th className="text-center">St</th>
                                        <th className="text-center">Son 6</th>
                                        <th className="text-center">S20</th>
                                        <th className="text-center">EİD</th>
                                        <th className="text-center">Ganyan</th>
                                        <th className="text-center">KGS</th>
                                        <th className="text-center">Hp</th>
                                        <th className="text-center">AGF</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {shownRun && shownRun.horses && shownRun.horses.map((horse: any, keyHorse: number) => {
                                        return (<React.Fragment key={`DataTable-horses-${keyHorse}`}>
                                            <tr>
                                                <th className="seperator"></th>
                                            </tr>
                                            <tr key={`Tjk-races-${keyHorse}`}
                                                style={!horse.run_status ? {opacity: 0.4} : {}}>
                                                <td>
                                                    <div className="add-to-betslip">
                                                        {horse.no}
                                                    </div>
                                                </td>
                                                <td>
                                                  <span className="d-block fw-bold nowrap-ellipsis tooltip"
                                                        style={{width: 100}}>
                                                    <GetStablemateIcon
                                                        horse={horse}/>
                                                      {horse.name} {horse.result === null && horse.degree === null ?
                                                      <span
                                                          className="text-danger">({isMobile ? 'K' : 'KOŞMAZ'})</span> : <></>}

                                                      <div className="tooltip-text">{horse.name}</div></span>
                                                    {horse.age ? horse.age : '-'} {horse.jewelry && horse.jewelry !== "" &&
                                                    <span className="horse-jewelry text-danger">
                    / {horse.jewelry}


                                                        <div className="text-dark"
                                                             dangerouslySetInnerHTML={{__html: getPrettyFormatJewelry(horse.jewelry)}}></div>
            </span>}
                                                </td>
                                                <td className="text-nowrap">
                                                    {horse.weight || '-'}
                                                    {Number(horse.extra_weight) > 0 &&
                                                        <sup
                                                            className={"text-danger tooltip"}>+{horse.extra_weight}<span
                                                            className="tooltip-text text-light">Fazla Kilo</span></sup>}
                                                </td>
                                                <td className="fw-medium">
                                                    <span className="d-block origin-father nowrap-ellipsis"
                                                          style={{width: 150}}>{horse.father}</span>
                                                    <span className="origin-mother nowrap-ellipsis float-start"
                                                          style={{width: 75}}>{horse.mother}</span>
                                                    {horse.mother_father &&
                                                        <span className="origin-mother nowrap-ellipsis float-start"
                                                              style={{width: 75}}> / {horse.mother_father}</span>}
                                                </td>
                                                <td className="fw-medium">
                                                    {horse.jokey ? <div style={{width: 135}}>
                                                        {horse.form && <img
                                                            src={horse.form}
                                                            onError={({currentTarget}) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = jokeyIcon;
                                                            }}
                                                            width={24} alt={horse.jokey?.name || horse.jokey}
                                                            className="jockey-uniform float-start "/>}
                                                        <div className="float-start nowrap-ellipsis"
                                                             style={{
                                                                 width: 90,
                                                                 paddingTop: 7
                                                             }}>{horse.jokey?.short_name || horse.jokey}</div>
                                                    </div> : '-'}
                                                </td>

                                                <td className="">

                          <span className="owner nowrap-ellipsis d-block"
                                style={{width: 80}}>{horse.owner ? (horse.owner?.name || horse.owner) : '-'}</span>

                                                    <span className="coach nowrap-ellipsis d-block "
                                                          style={{width: 80}}>{horse.trainer ? (horse.trainer?.name || horse.trainer) : '-'}</span>
                                                </td>

                                                <td className="text-center">{horse.start ? horse.start : '-'}</td>
                                                <td className="text-center"
                                                    dangerouslySetInnerHTML={{__html: getPrettyFormatLastSix(horse.last6)}}></td>
                                                <td className="text-center">{horse.last20}</td>
                                                <td className="text-center">{horse.best_time}</td>
                                                <td className="text-center">{horse.ganyan}</td>
                                                <td className={`text-center`}>{horse.kgs ? horse.kgs : '-'}</td>
                                                <td className="text-center">{horse.handicap}</td>
                                                <td className={`${horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? 'text-success fw-bold' : ''}`}>
                                                    {horse.agf_ratio1 ? `${horse.agf_ratio1} (${horse.agf_sort_no1})` : null}
                                                    {horse.agf_ratio2 ? <> {horse.agf_ratio1 ?
                                                        <br/> : ''} {`${horse.agf_ratio2} (${horse.agf_sort_no2})`}</> : null}
                                                </td>
                                            </tr>
                                        </React.Fragment>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </> : <>

                            <p className="text-center p-4">
                                Bu yarış henüz koşulmadı ya da koşuların resmi sonuçlar açıklanmadı.<br/>
                                Resmi sonuç bilgileri TJK’dan temin edilmiştir, bu bilgilerin doğruluğundan 4nala.com
                                sorumlu değildir.

                            </p>


                        </>}


                    </div>
                </div>

            </div>
        </div>


        <Modal
            isOpen={showRunInfo !== false}
            onRequestClose={() => {
                setShowRunInfo(false);
            }}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    width: '940px',
                    maxHeight: 'calc(100% - 40px)',
                    marginRight: '-50%',
                    maxWidth: ' calc( 100% - 40px )',
                    padding: '15px',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                    borderRadius: 'var(--bs-border-radius-xl)',
                    border: 0
                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }} contentLabel="">

            <img alt={"kapat"} src={iconClose} className="modal-close" onClick={() => {
                setShowRunInfo(false)
            }}/>
            <br/>


            {shownRun && <div className={'row'}>
                <div className="col-7">
                    <h3><span>{shownRun.no}. Koşu</span> - <span>{shownRun.time}</span></h3>
                    {shownRun.gender_detail ? <><span>{shownRun.gender_detail}, </span></> : null}
                    <span className="course">{shownRun.course}, </span>
                    <span>{shownRun.distance}m</span>
                    <span>{shownRun.group}</span>
                    {shownRun.gender ? <><span>{shownRun.gender}, </span></> : null}
                    {shownRun.type_detail ? <><span>{shownRun.type_detail}, </span></> : null}

                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-right">İkramiyeler</th>
                            <th className="text-right">Primler</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shownRun.prices && shownRun.prices.map((prices: any, key: any) => {
                            return (
                                <tr key={`iprices${key}`}>
                                    <td>{key + 1}</td>
                                    <td className="text-right">{CurrencyFormat(prices)}{shownRun.currency}</td>
                                    <td className="text-right">{CurrencyFormat(shownRun.bonuses[key])} {shownRun.currency}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
                <div className="col">
                    <video controls style={{width: '100%'}}>
                        <source src={shownRun && shownRun.video ? shownRun.video : null} type="video/mp4"/>
                    </video>

                    {shownRun.photo_finish ? <>
                        <h3>Fotofiniş</h3>
                        <a href={shownRun.photo_finish} target="_blank"><img alt={"fotofiniş"} src={shownRun.photo_finish}
                                                                             style={{width: '100%'}}/></a>
                    </> : <></>}

                </div>
            </div>}

        </Modal>


    </>)

}
