import "./game_select_widget.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Storage } from "../../../services/localstorege.service";
import { getGameRemains } from "../../../services/auth.service";
import { ApiService } from "../../../services/http.service";
import { env } from "../../../constants/global.vars";
import _ from "lodash";
import { gameNavigationMiddleware, gtmSelectItem } from "../../../services/misc.functions";

export default function GameSelectWidget({ game_name }: { game_name: string }) {
  let params = useParams();
  const api = new ApiService();
  const navigate = useNavigate();

  const [gamesList, setGamesList] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    getGames().then((res: any) => {
      getGameRemains().then(() => {
        setGamesList(res);
      });
    });
  }, [params]);

  const getGames = async () => {
    let games: any = Storage.get("games");
    if (games) {
      if (params.subpage === "yeni-oyunlar") {
        return games.filter((game: any) => game.is_active === true);
      } else if (params.subpage === "gelecek-oyunlar") {
        return games.filter((game: any) => game.is_active === false);
      } else {
        return games;
      }
    }
    return await api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then((res: any) => {
      if (res && res.status) {
        let d: any = _.orderBy(res.data, ["is_active", "ordering"], ["desc", "asc"]);
        Storage.set("games", d, 60 * 60 * 5);
        if (params.subpage === "yeni-oyunlar") {
          return d.filter((game: any) => game.is_active === true);
        } else if (params.subpage === "gelecek-oyunlar") {
          return d.filter((game: any) => game.is_active === false);
        } else {
          return d;
        }
      } else {
        return [];
      }
    });
  };

  return (
    <div className="game-select-dropdown">
      {isDropdownOpen && <div className="overlay" onClick={toggleDropdown}></div>}
      <button className="btn dropdown-toggle p-0" type="button" id="dropdownMenuButton" onClick={toggleDropdown}>
        {game_name}
      </button>
      {isDropdownOpen && (
        <div className="game-select-dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div className="games-grid">
            {gamesList.map((game: any, key: number) => {
              if (game.is_active) {
                return (
                  <div
                    key={`dropdown-game-${key}`}
                    className="dropdown-item"
                    onClick={() => {
                      gameNavigationMiddleware(game, navigate);
                      gtmSelectItem([game], "Oyunlar");
                    }}
                  >
                    <img className="game-img" alt={game.name} src={env.cdn_host + "/media/" + game.image} />
                    <div className="badge bg-success">Oyna</div>
                  </div>
                );
              } else {
                return (
                  <div key={`dropdown-game-${key}`} className="dropdown-item">
                    <img className="game-img" alt={game.name} src={env.cdn_host + "/media/" + game.image} />
                    <div className="badge bg-warning">Yakında</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}
