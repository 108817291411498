import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "./slider.css";
import React, { useEffect } from "react";
import { flatPageSubject, homeSliderSubject, homeSlides, path } from "../../store/misc.store";
import { useForceUpdate } from "../../services/misc.functions";
import { useLocation, useNavigate } from "react-router-dom";
import { env, flatPages } from "../../constants/global.vars";
import { ApiService } from "../../services/http.service";
import { isMobile } from "../../App";
import rightSlide from "../../assets/img/right-slide.jpeg";
import paths from "../../paths";

export function Slider() {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let agreementSubject_subsciber = flatPageSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      agreementSubject_subsciber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let homeSliderSubject_subscriber = homeSliderSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      homeSliderSubject_subscriber.unsubscribe();
    };
  });

  useEffect(() => {
    if (homeSlides.length === 0) {
      let api = new ApiService();
      api.start("get", env.accounting_api + "/misc/api/list-slide/", null, false).then((res: any) => {
        homeSlides.splice(0, homeSlides.length);
        homeSlides.push(...res.data);
        forceUpdate();
      });
    }
  }, []);

  useEffect(() => {
    forceUpdate();
  }, [pathname]);

  /*if (path.root !== "/") {
        return <div className="slider-wrapper" style={isMobile ? {} : {height: 50}}></div>;
    }*/

  return (
    <div className={"row"}>
      <div className={`col-12  ${flatPages["home-slider"] ? "col-lg-9" : ""}  slider-wrapper`}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          autoplay={{ delay: 5000 }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={isMobile ? false : true}
        >
          {/*
            <SwiperSlide><div className="slide"><img src="/assets/slider/slide3.jpg" alt={"slide 2"} /></div></SwiperSlide>
    */}

          {homeSlides.map((slide: any, key: number) => {
            return (
              <SwiperSlide className={"me-0 cursor-pointer"} key={`slider-${key}`}>
                <div
                  className="slide"
                  onClick={() => {
                    if (slide.web_url) {
                      navigate(`${slide.web_url}`);
                    }
                  }}
                >
                  <img
                    src={env.accounting_api + "/media/" + (isMobile ? slide.photo_mobile : slide.photo_web)}
                    alt={slide.title}
                  />

                  {slide.slide_type !== 1 && (
                    <div className="caption">
                      {slide.title && slide.title !== "-" && <h2>{slide.title}</h2>}
                      {slide.content && <p dangerouslySetInnerHTML={{ __html: slide.content }}></p>}
                      {/*{slide.web_url && !isMobile &&*/}
                      {/*    <Link className={"btn btn-warning btn-lg float-end"} to={slide.web_url}>BAHİS YAP</Link>}*/}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className={"col-12 col-lg-3 right-slide"}>
        {!isMobile && flatPages["home-slider"] && (
          <div
            dangerouslySetInnerHTML={{
              __html: flatPages["home-slider"].content.replace(/\n\r/g, "<br />").replace(/\r\n/g, "<br />"),
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
