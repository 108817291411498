import React, {useEffect} from "react";
import {SupportMenu} from "./support";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {modalServiceSubject} from "../../services/modal.service";
import {SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import axios from "axios";
import {Storage} from "../../services/localstorege.service";
import {exceptionModal} from "../../services/misc.functions";
import {useNavigate} from "react-router";
import paths from "../../paths";
import {isMobile} from "../../App";

export function SupportTicket() {

  const [subjects, setSubjects] = React.useState<any[]>([])
  const [issues, setIssues] = React.useState<any[]>([])
  const [ticketForm, showTicketForm] = React.useState<boolean>(false)
  const [hint, setHint] = React.useState<any>(null)


  useEffect(() => {
    let api = new ApiService();
    api.start('get', env.accounting_api + '/misc/api/list-questions/', null, false)
      .then((res: any) => {
        setSubjects(res.data);
      })
  }, [])

  useEffect(() => {
    if (ticketForm) {
      let textarea: any = document.getElementById('id_support_textArea');
      if (textarea) {
        textarea.focus();
      }
    }
  }, [ticketForm])

  const getIssues = (e: any) => {

    if (Number(e.target.value) === -1) {
      setIssues([]);
      setHint(null);
      return null;
    }
    let api = new ApiService();
    api.start('post', env.accounting_api + '/misc/api/list-questions/', {'question_type': e.target.value}, false)
      .then((res: any) => {
        setIssues(res.data);
      })
  }

  const showHint = (e: any) => {
    if (Number(e.target.value) === -1) {
      setIssues([]);
      setHint(null);
      return null;
    }

    let hint: any = issues.find((el: any) => Number(el.id) === Number(e.target.value))
    setHint(hint);
  }


  return (<>

    <div className="page-widget dark p-4 support-wrapper">
      <div className="row">
        <div className={`col-lg-3 col-sm-12 ${isMobile ? '' : 'pt-3'}`}>
          <SupportMenu></SupportMenu>
        </div>
        <div className="col-lg-9 col-sm-12">

          <h1 className={`${isMobile ? 'mt-2' : ''}`}>Yardım</h1>

          <div className="mb-4 rounded-4">

            <div className="page-widget">

              <h2 className="pb-1">Size hangi konuda yardımcı olabiliriz?</h2>
              <p className="px-3">
                Size yardımcı olmamızı istediğiniz konuyu seçerek hızlı cevap alabilir ya da size sunulan cevap
                yetersiz kalırsa müşteri hizmetlerine ulaşabilmek için destek talebi oluşturabilirsiniz
              </p>


              <form className="p-3">
                <div className="mb-3">
                  {/*<label htmlFor="id_support_ticket_subject" className="form-label">Konu</label>*/}
                  <select id="id_support_ticket_subject" className="w-100" onChange={getIssues}>
                    <option value={-1}>Konu Seçiniz</option>
                    {subjects && subjects.length > 0 && subjects.map((el: any, key: number) => {
                      return (<option key={`subject-option-${key}`}
                                      value={el.question_type}>{el.question_type_display}</option>)
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <select id="id_support_ticket_subject" className="w-100" onChange={showHint}>
                    <option value={-1}>Sorunu Seçiniz</option>
                    {issues && issues.length > 0 && issues.map((issue: any, ikey: number) => {
                      return (<option key={`issues-option-${ikey}`} value={issue.id}>{issue.title}</option>)
                    })}
                  </select>
                </div>

                {!hint &&<div className="hint-text pt-5">
                  <h3>TJK Bahis Yap & Oyna - Online TJK Bülteni | 4nala.com</h3>
                  <p>
                    At yarışları, dünya genelinde en köklü ve geniş katılımlı sporlardan biri olarak kabul edilir.
                    Binicilik ve at yarışları, insan ile hayvanın rekabet ettiği tek spor dalı olma özelliğine sahiptir
                    ve ülkemizde de oldukça popüler bir spor dalıdır. At yarışı bahisleri ve bahis sitelerine geçmeden
                    önce Türkiye'deki at yarışlarının gelişimi hakkında da bilgi vermek gerekebilir.
                    Türkiye'de at yarışları geleneği, Osmanlı Dönemi'ne kadar uzanmaktadır.
                    Edirne ve İstanbul'un Yıldız Köşkü bahçesinde 17. yüzyılda düzenlenen at yarışları, bu sporun
                    tarihinde önemli bir kilometre taşıdır. 19. yüzyılda Makriköy'deki Veliefendi'nin arazisi ile
                    Kâğıthane'de de at yarışlarına rastlanmaktaydı.
                  </p>

                  <p>
                    Cumhuriyet döneminde, 1924 yılında düzenlenen ilk resmi yarışlarla birlikte at yarışlarına
                    düzenli bir yapı kazandırıldı. Türk atçılığının sembolü olan Gazi Koşusu ise 1927'de başlatıldı ve
                    o günden bu yana kesintisiz olarak Türkiye'de gerçekleştirilen en uzun soluklu spor etkinliği olma
                    özelliğini taşımaktadır. Türkiye Jokey Kulübü (TJK) tarafından İstanbul, Ankara, İzmir, Adana,
                    Bursa, Kocaeli, Elazığ, Diyarbakır, Şanlıurfa ve Antalya gibi şehirlerin hipodromlarında düzenlenen
                    yarışlar, at yarışlarının geniş bir coğrafyada sevilen bir spor dalı olmasını sağlamıştır.
                    Ayrıca at yarışları, TJK tarafından düzenlenen ve üzerine bahis yapılabilen bir oyundur.
                  </p>

                  <p>TJK tarafından düzenlenen diğer prestijli at yarışı koşuları arasında Cumhurbaşkanlığı Kupası,
                    Cumhuriyet Kupası, Fatih Sutan Mehmet Koşusu, Erkek Tay Deneme, Dişi Tay Deneme, Çaldıran ve
                    Türkiye Büyük Millet Meclisi gibi önemli yarışlar bulunmaktadır. TJK'nın bahis listesine aldığı
                    bu yarışlar oldukça popülerdir. Günümüzde at yarışları, heyecan verici bahis seçenekleri sunan ve
                    en çok tercih edilen bahis türü olan altılı ganyan ile de bilinir. At yarışında bahis yapmak ve
                    altılı ganyan oynamak isteyenler için temel bilgileri öğrenmek, bu heyecanlı sporun keyfini
                    artırmak açısından önemlidir. Peki at yarışı bahis siteleri nelerdir? At yarışı bahsi oynamak ve
                    at yarışı kuponu yapmak nasıl olur? At yarışı 4nala.com'da nasıl oynanır? At yarışı bahisi
                    nasıl yapılıyor? Altılı nasıl oynanır? At yarışına dair sorulara yazımızda yer vereceğiz
                  </p>

                  <h3>At Yarışı Bahis Nedir?</h3>
                  <p>Türkiye Jokey Kulübü (TJK) tarafından düzenlenen at yarışları ülkemizde üzerine en çok oynanan
                    bahis türlerinden biridir. At yarışı bahisleri Türkiye'de son derece popülerdir. At yarışında bahis
                    yaparken temel amaç koşularda birinci gelen atı tahmin etmektir. At yarışlarında hangi atların
                    birinci geleceğini bilmek çok önemlidir. TJK bahisleri arasında en popüleri Altılı Ganyan (6'lı
                    ganyan) bahsidir. Türkiye'deki her hipodromda altılı ganyan başta olmak üzere birçok bahis TJK
                    tarafından yarışseverlere sunulur. At yarışlarında ki en popüler ve en çok oynanan bahis türü altılı
                    ganyandır. At yarışı bahisinin önemli bir kısmını oluşturan altılı ganyan bahsi sanal bayilerde ve
                    ganyan bayilerde oynanır. At yarışı bahislerinde altılı ganyan dışında oynanabilecek diğer at yarışı
                    bahisleri başlıca 5'li ganyan, 4'lü ganyan, 3'lü ganyan, ganyan, çifte bahis, sıralı ikili, sırasız
                    ikili, sıralı üçlü bahis tabela bahis, tabela bahis (virgüllü), plase, 7'li plase, plase 2'li,
                    sıralı beşli bahis, sıralı beşli bahis (virgüllü), ganyan & plasedir. Peki TJK bahislerinde at
                    yarışlarında kazanma stratejileri neler? At yarışı kuponu nasıl tutturulur? Yazımızın devamında bu
                    detayı açıklayacağız.</p>

                  <h3>TJK Bahis: Kazanmak İçin Stratejiler</h3>
                  <p>At yarışlarında altılı ganyan başta olmak üzere tüm TJK bahisleri göründüğü kadar kolay olmayan
                    ancak sürpriz sonuçlar olması durumunda yüksek ikramiyeler sunan bir bahis türüdür. Her yarışta
                    kazananı tahmin etmek için birçok kriter bulunmaktadır. Atlar genellikle başrolü oynar ve kazanma
                    başarısı genellikle atın performansına ve istikrarına dayanır ancak, yüksek performans gösteren
                    atlara ulaşmak her zaman sınırlıdır ve at yarışlarından kar elde etmek isteyenler, sürpriz sonuçlara
                    karşı hazırlıklı olmalıdır.</p>

                  <p>Bir atın birkaç kez üst üste kazanmasının ardından, aynı atın bir sonraki yarışta da galip gelme
                    olasılığı arttırabilir ama at yarışlarında birçok değişken vardır. Bir at üzerine bahis yapmak her
                    zaman en iyi strateji olmayabilir. Bazı durumlarda çok sayıda atı içeren kuponlar oluşturmak
                    mantıklı olabilirken bazı durumlarda güven veren bir at varsa tek at üzerine bahis yapmak da tercih
                    edilebilir. At yarışlarının en popüler oyun türü olan altılı ganyanda bir ayağı tek geçmek, kupon
                    maliyetini artırmadan potansiyel kazancı artırma stratejisinin bir parçasıdır.</p>

                  <p>At yarışlarında bilgi, analiz ve gözlem kadar şans da önemli bir faktördür. Altılı ganyanı
                    tutturabilmek için günün yarış programını detaylıca incelemek, bahis sitelerini takip etmek ve at
                    yarışı bülteni, canlı at yarışı programlarını izlemek, gazetelerin at yarışı eklerindeki bilgileri
                    analiz etmek önemlidir. Geçmiş performanslar, handikap puanları, jokey uyumu, sıklet, pist durumu
                    gibi faktörleri değerlendirerek kazanabilecek atları belirlemek doğru bir reçete olabilir ancak, at
                    yarışları sürprizlere açık olduğu için favori atları kuponlara eklemek her zaman doğru bir strateji
                    değildir. Altılı ganyanda tüm ayaklarda favori atların gelmesi durumunda ikramiye miktarı düşebilir.
                    Bu nedenle, sürpriz atları belirleyerek kazanç şansınızı artırabilirsin. At yarışı bahis sitelerinin
                    at yarışı oynarken avantajları nelerdir? At yarışı sanal bayileri at yarışı oynayanlara neler
                    sağlar? Bahis yaparken sitelerin kattığı olumlu yönler nelerdir?</p>

                  <h3>Canlı At Yarışı Bahis Sitelerinin Avantajları</h3>
                  <p>Türkiye Jokey Kulübü (TJK) tarafından düzenlenen at yarışı bültenleri, at yarışı üzerine yayın
                    yapan gazete ve internet sitelerinde de yer alır. Canlı at yarışı bahis sitelerinin birbirinden
                    farklı avantajları vardır. Mobil uygulama ve internetin sağladığı olanakla son ana kadar bahis
                    kuponu yapabilme, para yatırma ve para çekme konularında kolaylık, at yarışı istatistikleri ve
                    içerik anlamında zenginlik gibi birçok avantaj bahis sitelerinde yer alabilir. 4nala.com'da at
                    yarışı bahsi yaparken uzman yorumları, zengin istatistikler ve kullanıcı dostu uygulama gibi birçok
                    avantaja sahip olabilirsin. At yarışı bahsi yaparken bu avantajları değerlendirmek kuponların tutma
                    ihtimalini de arttırabilir. Canlı at yarışı bahsi yaparken bir yandan uzman yorumlarına bakılabilir
                    ve diğer yandan da istatistikleri, ganyanlar ve at yarışı AGF tablosu incelenebilir.</p>

                  <h3>Güvenilir Bahis Sitelerinin Özellikleri</h3>
                  <p>At yarışı bahisleri yaparken en önemli detaylardan biri de oynanılan sitenin güvenli olmasıdır.
                    Türkiye Jokey Kulübü tarafından organize edilen at yarışları tamamen güvenilir ortamda oynanmak
                    zorundadır. Güvenilir bahis sitelerinin en önemli özelliği yarışseverlerin memnuniyetini
                    sağlamaktır. Güvenilir bahis sitelerinde at yarışı bahsi yapmak için birtakım özellikleri çok iyi
                    analiz etmek gerekir. At yarışını yasal bir şekilde oynamak çok önemlidir. Yasal at yarışı siteleri
                    dışında bahis yapmak kanunen yasaktır. At yarışı bahsi yapılan bir sitede en temel konulardan biri
                    üyelerin para yatırırken ve para çekerken zorluk yaşamamasıdır. Bununla beraber müşteri
                    memnuniyetini en üst düzeyde tutmak, at yarışı oynayan yarışseverlere kolaylık sağlamak için müşteri
                    hizmetleri çağrı merkezi departmanı mutlaka olmalıdır. Müşteri hizmetleri çağrı merkezi departmanı
                    üyelerin tüm sorunlarını çözebilmelidir. Bu güvenli ortam olduğu sürece at yarışı bahsi yapmakta bir
                    sıkıntı bulunmamaktadır. At yarışında bahis yaparken en önemli konulardan biri güven olmakla beraber
                    dikkat edilmesi gereken başka detaylar vardır.</p>

                  <h3>Bahis Yaparken Dikkat Edilmesi Gerekenler</h3>
                  <p>At yarışında bahis yaparken birçok dikkat edilmesi gereken ayrıntılar vardır. At yarışı bahsi,
                    futbol bahisleri, basketbol bahislerine oranla daha teknik bilgi gerektiren bahisleri kapsar.
                    Dolayısıyla at yarışı kuponu oluştururken atların durumuna son derece hakim olmak çok avantajlıdır.
                    Canlı bahis yaparken atları tanımak ve takip etmek çok önemlidir. At yarışlarında başarılı bir iddaa
                    sonucu elde etmek için altılı bülten veya diğer bahis oyunları için kupon hazırlarken tek bir veriye
                    odaklanmak yeterli değildir. Başarılı tahminlerde bulunmak ve olumlu sonuçlar elde etmek için ciddi
                    bir araştırma ve detaylı bir hazırlık yapmak önemlidir. Verileri dikkatlice incelemek ve analiz
                    etmek, tahminlerinizi güçlendirebilir ve daha bilinçli bahis kararları almanıza yardımcı olabilir.
                    Özellikle altılı bülten hazırlarken, atların performansları, geçmiş yarış sonuçları, jokey
                    performansları, pist durumu, sıklet, antrenör bilgisi gibi birçok faktörü göz önünde bulundurmak
                    önemlidir. Bu verileri bir araya getirerek doğru tahminlerde bulunmak ve potansiyel kazançları
                    arttırmak at yarışında çok mümkündür. Aynı prensipler, diğer at yarışı bahis oyunları için de
                    geçerlidir. Basit verilere dayanmak yerine detaylı bir hazırlık yapmak, bahis stratejinizi
                    güçlendirecek ve daha olumlu sonuçlar elde etmenizi sağlayacaktır. Başarılı iddaa sonuçlarına
                    ulaşmak için sıkı bir çalışma ve detaylı analiz, yarışseverlere daha güvenli ve bilinçli bir
                    yaklaşım sunacaktır.</p>


                </div>}

              </form>

            </div>
          </div>


          {hint && <div className="page-widget mb-4 rounded-4 support-hints">
            <div className="card-header">
              <h2>{hint.title}</h2>
            </div>
            <div className="px-3 pb-4">
              {hint.image && <img src={hint.image} alt={hint.title} className="img-fluid mb-3"/>}
              <div className="content" dangerouslySetInnerHTML={{__html:hint.content}}>
              </div>

              {!ticketForm && <div className="text-center">
                <p className="my-4">Bu içerik size yardımcı oldu mu?</p>
                <button className="btn rounded-pill btn-success me-1" onClick={() => {
                  modalServiceSubject.next({
                    case: 'success',
                    title: 'Teşekkürler',
                    content: '<p>Geri bildirim için teşekkür ederiz. Sizlere daha iyi hizmet verebilmek için biz buradayız.</p>',
                    confirm: {
                      cancel: {
                        class: "btn btn-success",
                        label: 'Kapat',
                        action: () => {
                          setHint(null);
                        }
                      }
                    }
                  })
                }}>Evet
                </button>
                <button className="btn rounded-pill btn-secondary ms-1" onClick={() => {
                  showTicketForm(true);
                }}>Hayır
                </button>
              </div>}

            </div>
          </div>}
          {ticketForm && <SupportTicketForm hintId={hint?.id}></SupportTicketForm>}
        </div>
      </div>
    </div>
  </>)
}


export function SupportTicketForm(props: any) {

  const navigate = useNavigate();
  const hintId = props.hintId;

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    let acceptedFiles: any[] = [];
    for (let f of payload.file) {
      if ((f.type.includes('image') || f.type.includes('pdf')) && f.size < 1024 * 1024 * 5) {
        acceptedFiles.push(f);
      } else {
        toast.error('Lütfen 5MB dan küçük ve resim/pdf formatında dosya yükleyiniz.');
        return null;
      }
    }
    let formData: any = new FormData()
    Object.entries(payload).forEach(([key, value]) => {
        if (key !== 'file') {
          formData.append(key, value)
        }
      }
    )
    formData.append('file', payload.file[0])
    formData.append('subject', hintId)
    let post = new ApiService();
    post.setHeaders({'Content-Type': 'multipart/form-data'})
    post.start('post', env.accounting_api + '/support/api/create-ticket/', formData, true).then((res: any) => {
      reset({message: '', file: null});
      modalServiceSubject.next({
        case: 'success', title: 'Talebiniz Alındı',
        content: '<p>Destek talebiniz başarıyla oluşturuldu. En kısa sürede size dönüş yapılacaktır.</p>',
        confirm: {
          cancel: {
            class: 'btn btn-success',
            label: 'Tamam',
            action: () => {
              navigate(paths.support.url + '/destek-taleplerim/');
            }
          }
        }
      })
    }).catch((err: any) => {
      exceptionModal(err);
    })
  }


  return (<>
    <div className="page-widget mb-4 support-ticket-form">
      <div className="card-header">
        <h2>Sorununuzu bize bildirin</h2>
      </div>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea id={"id_support_textArea"} {...register<any>("message", {
            required: true,
            minLength: 10,
            maxLength: 600
          })} placeholder={"Mesajınızı giriniz"}></textarea>
          {errors.message &&
            <span className="text-danger">Bu alan zorunludur. En az 40, en çok 600 karakter girmelisiniz.</span>}
          <div className="my-3">
            <label htmlFor="id_supportTicketFile" className="form-label">Dosya veya Fotoğraf (max:2mb)</label>
            <input type="file" {...register<any>("file", {required: false})} className="form-control"
                   id="id_supportTicketFile"/>
          </div>
          <button className="btn btn-success">Gönder</button>
        </form>
      </div>
    </div>
  </>)
}
