import { env } from "../constants/global.vars";
import { ServerDate } from "../services/misc.functions";

export class RaffleModel {
  detail: any;
  draw_categories_id: any;
  draw_date: any;
  game_type: any;
  detail_photo: any;
  id: any;
  is_new: any;
  is_popular: any;
  is_running_out: any;
  best_selling: any;
  name: any;
  no: any;
  tag: any;
  payout_amount: any;
  photo: any;
  remaining_tickets: any;
  second_hand: any;
  sell_end_date: any;
  sell_start_date: any;
  status: any;
  this_week: any;
  ticket_price: any;
  title: any;
  title_abbreviated: any;
  total_tickets: any;
  transferred_amount: any;
  transferred_count: any;
  is_added: any;
  cartIndex: any;
  draw_price: any;
  sort: any;
  draw_categories_sort: any;

  constructor(obj: any) {
    this.detail = obj.detail;
    this.draw_categories_id = obj.draw_categories_id;
    this.draw_categories_sort = obj.draw_categories_sort;
    this.draw_date = obj.draw_date;
    this.game_type = obj.game_type;
    this.is_added = obj.is_added;
    this.cartIndex = obj.cartIndex;
    this.draw_price = obj.draw_price;
    this.id = obj.id;
    this.tag = obj.tag === 1;
    this.is_new = obj.tag === 1;
    this.is_popular = obj.is_popular;
    this.is_running_out = obj.is_running_out;
    this.best_selling = obj.tag === 3;
    this.name = obj.name;
    this.no = obj.no;
    this.payout_amount = obj.payout_amount;
    this.photo = env.accounting_api + "/media/" + obj.photo;
    this.detail_photo = env.accounting_api + "/media/" + obj.detail_photo;
    this.remaining_tickets = obj.remaining_tickets;
    this.second_hand = obj.tag === 2;
    this.sell_end_date = obj.sell_end_date;
    this.sell_start_date = obj.sell_start_date;
    this.status = obj.status;
    this.this_week = obj.this_week;
    this.ticket_price = Number(obj.ticket_price);
    this.title = obj.title;
    this.title_abbreviated = obj.title_abbreviated;
    this.total_tickets = obj.total_tickets;
    this.transferred_amount = obj.transferred_amount;
    this.transferred_count = obj.transferred_count;
    this.sort = obj.sort;
  }

  leftTicketPercColor() {
    let perc = 100 - (100 * this.remaining_tickets) / this.total_tickets;
    if (perc < 50) {
      return "#4AD3A5";
    } else if (perc < 80) {
      return "#FF9F46";
    } else {
      return "#FF4646";
    }
  }

  leftTicketPerc() {
    let perc = 100 - (100 * this.remaining_tickets) / this.total_tickets;
    if (this.remaining_tickets == 0) {
      perc = 100;
    } else {
      if (perc > 99) {
        perc = 99;
      }
    }

    return perc.toFixed(0);
  }

  leftDays() {
    try {
      let leftDay = Math.floor(
        (new Date(this.draw_date.split("T")[0]).getTime() - ServerDate().setHours(0, 0, 0, 0)) / 1000 / 60 / 60 / 24
      );
      if (leftDay == 0) {
        return "Bugün";
      } else {
        return leftDay;
      }
    } catch (e) {
      return Math.floor((new Date(this.draw_date).getTime() - ServerDate().getTime()) / 1000 / 60 / 60 / 24);
    }
  }
}
