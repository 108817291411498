import {SupportMenu} from "./support";
import React, {useEffect} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {useNavigate, useParams} from "react-router";
import paths from "../../paths";
import iconBackArrow from "../../assets/feather/arrow-left.svg";
import {isMobile} from "../../App";

export function SupportFAQ(){

  const [subjects, setSubjects] = React.useState<any[]>([])
  const [issues, setIssues] = React.useState<any[]>([])
  const [hint, setHint] = React.useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const pathname = useNavigate();

  useEffect(()=>{
    let api = new ApiService();
    api.start('get', env.accounting_api + '/misc/api/list-questions/', null , false)
      .then((res:any)=>{
        if(params.id){
          getIssues(Number(params.id)).finally(()=>{
            setSubjects(res.data);
          })
        }else{
          setSubjects(res.data);
        }

      })
  },[])

  useEffect(()=>{
    if(!params.id){
      setIssues([]);
    }
  },[pathname]);

  const getIssues = async (e:any) => {
    if(Number(e) === -1){
      setIssues([]);
      setHint(null);
      return null;
    }
    let api = new ApiService();
    await api.start('post', env.accounting_api + '/misc/api/list-questions/', {'question_type':e} , false)
      .then((res:any)=>{
        setIssues(res.data);
        navigate( `${paths.support.url}/sikca-sorulan-sorular/${e}/`);
      })
  }

  return (<>
    <div className="page-widget dark p-4 support-wrapper ">
      <div className="row">
        <div className={`col-lg-3 col-sm-12 ${isMobile ? '' : 'pt-3'}`}>
          <SupportMenu></SupportMenu>
        </div>
        <div className="col-lg-9 col-sm-12">

          <h1 className={`${isMobile ? 'mt-2' : ''}`}>Sıkça Sorulan Sorular</h1>

          <div className="page-widget support-faq">

            {issues.length !== 0 &&
                <img alt={"geri"} className={`float-start mt-3 ms-3 me-3`} src={iconBackArrow} onClick={() => {
                  getIssues(-1);
                }}/>}

            <h2 className="pb-1">Size hangi konuda yardımcı olabiliriz?</h2>
            <p className="px-3">
              Size yardımcı olmamızı istediğiniz konuyu seçerek hızlı cevap alabilir ya da size sunulan cevap
              yetersiz kalırsa müşteri hizmetlerine ulaşabilmek için destek talebi oluşturabilirsiniz
            </p>


            <div className="p-3">
              {issues.length === 0 && subjects && <ul className="faq-list">
                {subjects.map((item: any, key: number) => {
                  return (<li key={`subjects-faq-${key}`} id={`subjects-faq-${item.question_type}`}>
                    <a onClick={() => {
                      getIssues(item.question_type)
                    }}>{item.question_type_display}</a>
                  </li>)
                })}
              </ul>}

              <ul className="faq-list">
                {issues && issues.map((item: any, key: number) => {
                  return (<li key={`subjects-faq-${key}`} id={`subjects-faq-${item.question_type}`}>
                    <a className={hint === item.id ? 'active' : ''} onClick={() => {
                      setHint(item.id)
                    }}>{item.title}</a>
                    {hint === item.id && <p className="p-3" dangerouslySetInnerHTML={{__html: item.content}}></p>}
                  </li>)
                })}
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
