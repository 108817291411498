import { Subject } from "rxjs";
import { RaffleModel } from "../models/raffle.model";

export const raffleDraws: RaffleModel[] = [];
export const raffleCategories: any = [];
export const raffleSubject = new Subject();
export const raffleDrawSubject = new Subject();

export const recoveredCartItems: any = [];

export const cartToggleSubject = new Subject();
export const creditCartSubject = new Subject();
export const cartStore: any = {
  items: [],
  couponCode: null,
  pointUsage: false,
};

export const drawFilters: any = {
  categories: [],
  isPopular: false,
  isNew: false,
  runningOut: true,
  ordering: ["sort", "asc"],
};

export const scratchCards: any = [];
