import React from "react";
import "./cookies.css";
import { Storage } from "../../services/localstorege.service";
import paths from "../../paths";
import { useNavigate } from "react-router";
import {wssProvider} from "../../services/websocket.service";
import {auth} from "../../store/auth.store";
import {env} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";

export default function Cookies() {
  const cookiesData = Storage.get("cookies-policy");
  const navigate = useNavigate();
  function admit() {
    let element: any = document.getElementById("cookies");
    element.style.display = "none";
    Storage.set("cookies-policy", true);
    if (auth.member) {
      let api = new ApiService();
      api
          .start(
              "post",
              env.accounting_api + "/member/api/update-account-info/",
              {"functional": true, "targeting_cookies": true, "mandatory": true},
              true
          )
          .then((res: any) => {
            wssProvider.checkauth();
          })
          .finally(() => {
          });
    }

  }
  return cookiesData ? null : (
    <div className="footer-cookies-bar" id="cookies">
      <div className="container footer-cookies-container">
        <p>
          İnternet sayfamızda yasal düzenlemelere uygun olarak sayfamızı
          işlevsel tutmak, kullanıcı memnuniyetini artırmak ve size özel
          pazarlama ve reklamcılık faaliyetleri gerçekleştirebilmek için
          çerezler kullanılmaktadır. Çerez Politikamızı inceleyerek detaylı
          bilgi alabilirsiniz.
        </p>

        <div className={"row mt-2"}>
          <div className={"col-6 text-end"}>
            <button
              onClick={() => {
                navigate(paths.cookieSettings.url);
              }}
              className="btn btn-small btn-outline-primary "
            >
              Çerez Tercihleri
            </button>
          </div>
          <div className={"col-6 "}>
            <button
              onClick={() => admit()}
              className="btn btn-small btn-primary "
            >
              Kabul Ediyorum
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
