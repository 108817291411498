import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForceUpdate } from "../../../services/misc.functions";
import { drawFilters, raffleCategories, raffleDraws } from "../../../store/raffle.store";
import { getRaffleCategories } from "../../../services/raffle.service";
import { isMobile } from "../../../App";
import { passwordModalStyles } from "../../../constants/global.vars";

export function DrawFilter(props: any) {
  const shownRaffleDraws = props.shownRaffleDraws;
  const setFilters = props.setFilters;
  const [modalCategory, setModalCategory] = useState<boolean>(false);
  const [modalSort, setModalSort] = useState<boolean>(false);
  const forceUpdate = useForceUpdate();
  let checkedCategories: [] = raffleCategories.filter((e: any) => e.checked === true).map((e: any) => e.id);

  const changeFilter = (e: any) => {
    for (let [k, v] of Object.entries(e)) {
      drawFilters[k] = v;
    }
    setFilters();
    forceUpdate();
  };

  const checkCategory = (cat: any) => {
    raffleCategories.filter((item: any) => {
      if (item.id === cat.id) {
        item.checked = !item.checked;
      }
    });
    checkedCategories = raffleCategories.filter((e: any) => e.checked === true).map((e: any) => e.id);
    changeFilter({ categories: checkedCategories });
  };

  const clearCheckedCategories = () => {
    raffleCategories.filter((item: any) => {
      item.checked = false;
      return item;
    });
    changeFilter({ categories: [] });
  };

  useEffect(() => {
    getRaffleCategories().then((res: any) => {
      forceUpdate();
    });
  }, []);

  return (
    <div className="col-lg-12 col-md-12">
      <div className="draw-filter-wrapper">
        {!isMobile && (
          <div className="float-start">
            <h4>
              Çekiliş filtreleri
              <span>
                {raffleDraws.length} çekiliş, {shownRaffleDraws.length} adet gösterilen.
              </span>
            </h4>
          </div>
        )}
        <div>
          <button className={`btn btn-sm ${isMobile ? 'text-primary fw-medium p-0' : 'btn-primary'} me-1`}
                  onClick={() => setModalCategory(true)}>
            <i className="fa-solid fa-filter"></i>
            Kategori
            {checkedCategories.length > 0 && (
                <span className="ms-2 badge text-bg-warning">{checkedCategories.length}</span>
            )}
          </button>


          <div className="dropdown float-lg-end me-1">
            <button className={`btn btn-sm ${isMobile ? 'text-primary fw-medium p-0' : 'btn-primary'} dropdown-toggle`}
                    data-bs-toggle="dropdown" aria-expanded="false">
              {drawFilters.runningOut && (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="me-1"
                      viewBox="0 0 16 16"
                  >
                    <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                    <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                    <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                  </svg>
              )}
              {!drawFilters.runningOut && (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                  >
                    <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
              )}
              Tükenenler
            </button>
            <ul className="dropdown-menu bg-white">
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({runningOut: true});
                    }}
                >
                  Gizle
                </a>
              </li>
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({runningOut: false});
                    }}
                >
                  Göster
                </a>
              </li>
            </ul>
          </div>
          <div className="dropdown float-lg-end me-1">
            <button className={`btn btn-sm ${isMobile ? 'text-primary fw-medium p-0' : 'btn-primary'} dropdown-toggle`}
                    data-bs-toggle="dropdown" aria-expanded="false">
              {!isMobile && (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="me-1"
                      viewBox="0 0 16 16"
                  >
                    <path
                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                  </svg>
              )}
              Sırala
            </button>
            <ul className="dropdown-menu bg-white">
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({ordering: ["ticket_price", "asc"]});
                    }}
                >
                  En düşük fiyat
                </a>
              </li>
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({ordering: ["ticket_price", "desc"]});
                    }}
                >
                  En yüksek fiyat
                </a>
              </li>
              {/* <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    changeFilter({ ordering: ["id", "asc"] });
                  }}
                >
                  En yeniler
                </a>
              </li> */}
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({ordering: ["draw_date", "asc"]});
                    }}
                >
                  Yakında çekilecekler
                </a>
              </li>
              <li>
                <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      changeFilter({ordering: ["sort", "asc"]});
                    }}
                >
                  Varsayılan
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
          isOpen={modalCategory}
          className="default"
          onRequestClose={() => setModalCategory(false)}
          contentLabel="Categories"
          style={passwordModalStyles}
      >
        <h2>Kategoriler</h2>
        <div className="modal-body">
          {raffleCategories.length > 0 &&
              raffleCategories.map((category: any, key: number) => (
                  <div className="mb-1 form-check d-flex gap-1" key={`raffle-categories-${key}`}>
                    <input
                        type="checkbox"
                        checked={category.checked}
                        onChange={() => checkCategory(category)}
                        className="form-check-input"
                        id={`raffle-categories-${key}`}
                    />
                    <label className="form-check-label" htmlFor={`raffle-categories-${key}`}>
                      {category.name}
                    </label>
                  </div>
              ))}
        </div>
        <div className="modal-ticket-footer text-end">
          <div className="wave"></div>
          <div className="body-row row">
            <div className="col-6 pe-0">
              <button id="doneButton" onClick={clearCheckedCategories} className="btn w-100 btn-warning">
                Temizle
              </button>
            </div>
            <div className="col-6 ps-0">
              <button
                  type="button"
                  onClick={() => setModalCategory(false)}
                  className="btn w-100 custom-btn-danger me-2"
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={modalSort} className="default" onRequestClose={() => setModalSort(false)} contentLabel="Sort">
        <h2>Sıralama</h2>
        <div className="modal-body">
          <div className="mb-1">
            <button
                className="btn btn-outline-dark w-100"
                onClick={() => {
                  changeFilter({ordering: ["ticket_price", "asc"]});
                  setModalSort(false);
                }}
            >
              En düşük fiyat
            </button>
          </div>
          <div className="mb-1">
            <button
                className="btn btn-outline-dark w-100"
                onClick={() => {
                  changeFilter({ordering: ["ticket_price", "desc"]});
                  setModalSort(false);
                }}
            >
              En yüksek fiyat
            </button>
          </div>
          <div className="mb-1">
            <button
                className="btn btn-outline-dark w-100"
                onClick={() => {
                  changeFilter({ ordering: ["id", "asc"] });
                setModalSort(false);
              }}
            >
              En yeniler
            </button>
          </div>
          <div className="mb-1">
            <button
              className="btn btn-outline-dark w-100"
              onClick={() => {
                changeFilter({ ordering: ["draw_date", "asc"] });
                setModalSort(false);
              }}
            >
              Yakında çekilecekler
            </button>
          </div>
          <div className="mb-1">
            <button
              className="btn btn-outline-dark w-100"
              onClick={() => {
                changeFilter({ ordering: ["sort", "asc"] });
                setModalSort(false);
              }}
            >
              Varsayılan
            </button>
          </div>
        </div>
        <div className="modal-ticket-footer text-end">
          <div className="wave"></div>
          <div className="body-row row">
            <div className="col-6 pe-0">
              <button
                id="doneButton"
                onClick={() => {
                  changeFilter({ ordering: ["sort", "asc"] });
                }}
                className="btn w-100 btn-warning"
              >
                Temizle
              </button>
            </div>
            <div className="col-6 ps-0">
              <button type="button" onClick={() => setModalSort(false)} className="btn w-100 custom-btn-danger me-2">
                Kapat
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
