import { cartStore, cartToggleSubject, raffleDraws } from "../../store/raffle.store";
import { Cart } from "../../models/cart.model";
import { clearCart, orderComplete, removeFromCart, updateCart } from "../../services/raffle.service";
import cartIcon from "../../assets/feather/shopping-cart.svg";
import crossIcon from "../../assets/feather/x-circle.svg";
import plusCircleIcon from "../../assets/feather/plus-circle.svg";
import React, { useEffect, useState } from "react";
import { modalServiceSubject } from "../../services/modal.service";
import { dateFormat, useForceUpdate } from "../../services/misc.functions";
import trashIcon from "../../assets/feather/trash2-red.svg";
import chevronDown from "../../assets/feather/chevron-down.svg";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
import { useNavigate } from "react-router-dom";
import iconBackArrow from "../../assets/feather/arrow-left.svg";

let cartItemCount = 0;
let cartTotalPrice: number = 0;
let cartTotalPriceWithDiscount: number = 0;

export function RaffleCart({ navToTickets }: { navToTickets: any }) {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [cartStep, setCartStep] = useState(0);

  const reCalc = () => {
    cartItemCount =
      cartStore.items.length > 0
        ? cartStore.items
            .map((el: Cart) => el.quantity)
            .reduce(function (a: any, b: any) {
              return Number(a) + Number(b);
            })
        : 0;
    cartTotalPrice =
      cartStore.items.length > 0
        ? cartStore.items
            .map((el: Cart) => Number(el.quantity) * Number(el.item.ticket_price))
            .reduce(function (a: any, b: any) {
              return Number(a) + Number(b);
            })
        : 0;
    cartTotalPriceWithDiscount =
      Number(cartTotalPrice) -
      (cartStore.couponCode && cartStore.couponCode.minCartCond <= cartTotalPrice
        ? Number(cartStore.couponCode.amount)
        : 0);
    cartStore.total = cartTotalPriceWithDiscount;
  };

  useEffect(() => {
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe((res: any) => {
      reCalc();
      forceUpdate();
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  const setPointUsage = () => {
    cartStore.pointUsage = !cartStore.pointUsage;
    cartToggleSubject.next({ action: "update" });
    forceUpdate();
  };

  const addCouponCode = async () => {
    modalServiceSubject.next({
      title: "Kupon Kodunu Giriniz",
      afterLoad: () => {
        const sureButton = document.getElementById("id_btnModelServiceSure") as HTMLButtonElement;
        const inputElements = document.querySelectorAll("input.code-input");

        inputElements.forEach((ele: any, index: number) => {
          ele.addEventListener("keydown", (e: KeyboardEvent) => {
            const target = e.target as HTMLInputElement;
            if (e.key === "Backspace" && target.value === "") {
              (inputElements[Math.max(0, index - 1)] as HTMLInputElement).focus();
            }
          });

          ele.addEventListener("input", (e: Event) => {
            const target = e.target as HTMLInputElement;
            const first = target.value.charAt(0);
            target.value = first;

            const lastInputBox = index === inputElements.length - 1;
            const didInsertContent = first !== "";

            if (didInsertContent && !lastInputBox) {
              (inputElements[index + 1] as HTMLInputElement).focus();
              inputElements[index + 1].dispatchEvent(new Event("input"));
            }

            const validateCodeValue = Array.from(inputElements)
              .map((inp) => (inp as HTMLInputElement).value)
              .join("");
            if (validateCodeValue.length === 6) {
              sureButton.disabled = false;
            } else {
              sureButton.disabled = true;
            }
          });
        });
      },
      content: `
        <div class="mb-3 px-5 d-flex w-100">
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <div class="clearfix"></div>
        </div>
      `,
      confirm: {
        sure: {
          class: "btn btn-primary",
          label: "Onayla",
          action: () => {
            const inputElements = document.querySelectorAll("input.code-input");
            const couponCode = Array.from(inputElements)
              .map((inp) => (inp as HTMLInputElement).value)
              .join("");
            cartStore.couponCode = couponCode;
            cartToggleSubject.next({ action: "update", couponCode: couponCode });
          },
        },
        cancel: {
          class: "btn btn-warning",
          label: "Kapat",
          action: () => {},
        },
      },
    });
  };

  const sendOrder = async () => {
    if (!cartStore.pointUsage && cartTotalPriceWithDiscount > auth.member.balance_debit) {
      if (cartTotalPriceWithDiscount < Number(auth.member.balance_debit) + Number(auth.member.balance_point)) {
        modalServiceSubject.next({
          case: "danger",
          title: "Bakiyeniz Yetersiz",
          width: "400px",
          content: `<p class="text-center">
              NP Kullanabilir veya Bakiye yükleyerek ödemeye devam edebilirsiniz.
             </p>
             `,
          confirm: {
            sure: {
              label: "NP Kullan",
              class: "btn btn-warning",
              action: () => {
                setPointUsage();
                sendOrder();
              },
            },
            cancel: {
              label: "Para Yatırma Yöntemleri",
              class: "btn-primary",
              action: () => {
                navigate(paths.deposit.url);
              },
            },
          },
        });
      } else {
        modalServiceSubject.next({
          case: "danger",
          title: "Bakiyeniz Yetersiz",
          width: "400px",
          content: `<p class="text-center">
              Hesabınıza bakiye yükleyerek işleminize devam edebilirsiniz.
             </p>
             `,
          cancel: {
            label: "Para Yatırma Yöntemleri",
            class: "btn-warning w-100",
            action: () => {
              navigate(paths.deposit.url);
            },
          },
        });
      }
    } else if (
      cartStore.pointUsage &&
      cartTotalPriceWithDiscount > Number(auth.member.balance_debit) + Number(auth.member.balance_point)
    ) {
      modalServiceSubject.next({
        case: "danger",
        title: "Bakiyeniz Yetersiz",
        width: "400px",
        content: `<p class="text-center">
             Hesabınıza bakiye yükleyerek işleminize devam edebilirsiniz.
             </p>
             `,

        confirm: {
          cancel: {
            label: "Para Yatırma Yöntemleri",
            class: "btn-warning  w-100",
            action: () => {
              navigate(paths.deposit.url);
            },
          },
        },
      });
    } else {
      orderComplete()
        .then((res: any) => {
          // console.log(res);
          // setCartStep(0);
          if (res.status) {
            clearCart();
            modalServiceSubject.next({
              case: "success",
              title: "Ödemeniz Başarılı",
              width: "400px",
              content: `<p class="text-center">
      Biletleriniz başarıyla satın alınmıştır. Bilet numaralarınızı ve detaylarını görmek için Biletlerim sayfasına gidebilirsiniz.
     </p>`,
              confirm: {
                sure: {
                  class: "btn btn-primary",
                  label: "Biletlerimi Gör",
                  action: () => {
                    navToTickets();
                    forceUpdate();
                  },
                },
                cancel: {
                  class: "btn btn-warning",
                  label: "Yeni Bilet Al",
                  action: () => {
                    navigate(paths.raffles.url);
                    forceUpdate();
                  },
                },
              },
            });
          } else {
            if (res.error === "re-login") {
              navigate(paths.login.url);
              // modalServiceSubject.next({
              //   title: "Lütfen Giriş Yapınız",
              //   content: "Çekilişe katılmak için giriş yapınız..",
              //   width: 320,
              //   confirm: {
              //     cancel: {
              //       label: "Tamam",
              //     },
              //   },
              // });
            }
          }
          if (res.status === false) {
            if (res.success_count > 0) {
              clearCart();
            }

            modalServiceSubject.next({
              case: "danger",
              title: res.success_count === 0 ? "Biletler Alınamadı" : "Bazı Biletler Alınamadı",
              width: "400px",
              content: `<p class="text-center">
      Bilet alım işlemi başarısız olan bilet tutarı 4nala hesabınıza iade edildi.
     </p>
     
     ${Object.entries(res.data)
       .map(([d, v]: any, k: any) => {
         let dr: any = raffleDraws.find((el: any) => el.no === Number(d));
         return `<div class="row item"> 
              <span class="nowrap fw-bold text-primary">${dr.title_abbreviated} - ${
           v.data.success_count > 0
             ? `<span class="text-primary">${v.data.message}</span>`
             : `<span class="text-primary">${v.data.message}</span>`
         }</span>
              ${
                v.data.success_count > 0
                  ? `<span class="d-block text-success fw-medium">Başarılı alınan bilet: ${v.data.success_count} adet</span>`
                  : ""
              }
              ${
                v.data.error_count > 0
                  ? `<span class="d-block text-danger fw-medium">Hatalı bilet: ${v.data.error_count} adet</span>`
                  : ""
              }
        </div>`;
       })
       .join("")}`,
              confirm: {
                sure: {
                  class: "btn btn-primary",
                  label: "Biletlerimi Gör",
                  action: () => {
                    navToTickets();
                    forceUpdate();
                  },
                },
                cancel: {
                  class: "btn btn-warning",
                  label: "Yeni Bilet Al",
                  action: () => {
                    navigate(paths.raffles.url);
                    forceUpdate();
                  },
                },
              },
            });
          }
        })
        .finally(() => {
          setCartStep(0);
          clearCart();
          forceUpdate();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <div className={"raffle-cart  rounded-bottom-0"}>
        <div className="row">
          <h3 className="text-white fw-bold fs-6">ÇEKİLİŞ SEPETİM</h3>
        </div>

        <div className="cart-items-wrapper">
          {cartStore.items?.length > 0 ? (
            cartStore.items?.map((item: Cart, key: number) => {
              return (
                <div key={`item-my-raffle-order-${key}`} className="cart-item">
                  <div className="col-3">
                    <img className="raffle-image" src={item.item.photo} />
                  </div>
                  <div className="col-9 ms-2 gap-1">
                    <div className={"d-flex flex-column"}>
                      <h4 className="text-nowrap text-truncate" title={item.item.title}>
                        {item.item.title}
                      </h4>
                      <p className={"cart-item-total"}>
                        Bilet Fiyatı: <span className={"fw-bold"}> {Number(item.item.ticket_price).toFixed(2)}₺</span>
                      </p>
                    </div>
                    <div className="add mt-1">
                      <div className="input">
                        <i
                          onClick={() => {
                            if (cartStore.items[key] && cartStore.items[key]?.quantity > 0) {
                              updateCart(cartStore.items[key], key, Number(cartStore.items[key]?.quantity) - 1);
                            }
                          }}
                        >
                          -
                        </i>
                        <input
                          className="no-arrows"
                          type="number"
                          value={cartStore.items[key]?.quantity || ""}
                          onChange={(e: any) => {
                            updateCart(cartStore.items[key], key, e.target.value);
                          }}
                          onBlur={(e: any) => {
                            if (e.target.value === "" || e.target.value === "0") {
                              removeFromCart(cartStore.items[key], key);
                            }
                          }}
                          max={100}
                          step={1}
                          min={1}
                        />
                        <i
                          onClick={() => {
                            if (
                              cartStore.items[key] &&
                              cartStore.items[key]?.quantity < 100
                              // cartStore.items[key]?.quantity < item.remaining_tickets
                            ) {
                              updateCart(cartStore.items[key], key, Number(cartStore.items[key].quantity) + 1);
                            }
                          }}
                        >
                          +
                        </i>
                      </div>
                      <div className="trash">
                        <img src={trashIcon} onClick={() => removeFromCart(cartStore.items[key], key)} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-5 gap-3 text-center">
              <img alt="plus" src={cartIcon} style={{ color: "#8389E4", width: "100%", height: "100px" }} />

              <span className="text-white">Sepetinizde Bilet Bulunmamaktadır.</span>
            </div>
          )}
        </div>

        <div className="row gap-1">
          <div className="row align-items-center p-0 m-0">
            <div className={"col-6 text-white fw-medium fs-7 text-nowrap"}>Toplam Bilet Adedi:</div>
            <div className={"col-6 text-end text-white fw-bold fs-7"}> {cartItemCount} Adet</div>
          </div>
          <div className="row align-items-center p-0 m-0">
            <div className={"col-6 text-white fw-medium fs-7 text-nowrap"}>Toplam Tutar:</div>
            <div className={"col-6 text-end text-white fw-bold fs-7"}>
              {" "}
              {cartStore.items
                ?.reduce((accumulator: any, item: any) => {
                  const price = Number(item.item.ticket_price);
                  const quantity = Number(item.quantity) || 1;
                  return accumulator + price * quantity;
                }, 0)
                .toFixed(2)}
              ₺
            </div>
          </div>

          <div className="row align-items-center p-0 m-0">
            {auth.authentication && auth.member && auth.member.id ? (
              <>
                <div className={"col-6 text-white fw-medium fs-7 text-nowrap"}>Puan Kullan:</div>
                <div className="col-6 text-end text-white fw-bold fs-7 d-flex align-items-center justify-content-end gap-1">
                  <input
                    type="checkbox"
                    value={cartStore.pointUsage}
                    checked={cartStore.pointUsage}
                    disabled={auth.member ? auth.member.balance_point <= 0 : true}
                    onChange={() => {
                      setPointUsage();
                    }}
                  />{" "}
                  <span>{auth.member ? auth.member.balance_point + "P" : "0.00P"}</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="row align-items-center p-0 m-0">
            <div className={"col-6 text-white fw-medium fs-7 text-nowrap"}>İndirim Kuponu:</div>

            <div className={"col-6 text-end text-white fw-bold fs-7 align-items-center"}>
              {cartStore.couponCode ? (
                <div className="d-flex justify-content-end">
                  <img
                    alt="cross"
                    src={crossIcon}
                    style={{
                      filter: "invert(1)",
                      width: "14px",
                      height: "14px",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                    onClick={() => {
                      cartStore.couponCode = null;
                      cartToggleSubject.next({ action: "update", couponCode: null });
                    }}
                  />

                  {cartStore.couponCode}
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <button
                    className="btn border rounded-5 text-white fw-medium d-flex align-items-center text-nowrap gap-1 fs-8 p-0 px-2"
                    onClick={addCouponCode}
                  >
                    <img
                      alt="plus"
                      src={plusCircleIcon}
                      style={{ filter: "invert(1)", width: "12px", height: "12px" }}
                    />
                    Kupon Gir
                  </button>
                </div>
              )}
            </div>

            <div className="text-warning fs-8 mt-2">Satılan biletlerin iadesi gerçekleştirilemez.</div>
          </div>
        </div>
      </div>
      <button
        disabled={cartStore.items?.length <= 0}
        className="btn btn-lg btn-warning fw-medium fs-6 w-100 rounded-top-0 p-4"
        onClick={() => setCartStep(1)}
      >
        ALIŞVERİŞİ TAMAMLA
      </button>

      <div className={`order-steps ${cartStep === 1 ? "open" : ""}`}>
        <div className={`order-steps body`}>
          <img alt="chevron-down" src={chevronDown} onClick={() => setCartStep(0)} />

          <div className={"col-6 text-white fw-bold mt-4"}>
            {cartItemCount} Bilet Tutarı:{" "}
            {cartStore.items
              ?.reduce((accumulator: any, item: any) => {
                const price = Number(item.item.ticket_price);
                const quantity = Number(item.quantity) || 1;
                return accumulator + price * quantity;
              }, 0)
              .toFixed(2)}
            ₺
          </div>

          <div className={"col-6 text-white text-nowrap fs-7"}>
            Harcama sonrasında{" "}
            {cartStore.items
              ?.reduce((accumulator: any, item: any) => {
                const price = Number(item.item.ticket_price);
                const quantity = Number(item.quantity) || 1;
                return accumulator + price * quantity;
              }, 0)
              .toFixed(2)}
            ₺ bakiyenizden düşecektir.
          </div>

          {auth.authentication && auth.member && auth.member.id ? (
            <div className="row mt-2">
              <div className={"col-6 text-white fw-medium fs-7 text-nowrap"}>Puan Kullan:</div>
              <div className="col-6 text-end text-white fw-bold fs-7 d-flex align-items-center justify-content-end gap-1">
                <input
                  type="checkbox"
                  value={cartStore.pointUsage}
                  checked={cartStore.pointUsage}
                  disabled={auth.member ? auth.member.balance_point <= 0 : true}
                  onChange={() => {
                    setPointUsage();
                  }}
                />{" "}
                <span>{auth.member ? auth.member.balance_point + "P" : "0.00P"}</span>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="text-warning fs-8 mt-2">Satılan biletlerin iadesi gerçekleştirilemez.</div>
        </div>
        <button
          disabled={cartStore.items?.length <= 0}
          className={`btn btn-lg btn-warning fw-medium fs-6 w-100 rounded-top-0 p-4 ${cartStep !== 1 ? "d-none" : ""}`}
          onClick={sendOrder}
        >
          ÖDEMEYİ TAMAMLA
        </button>
      </div>
    </>
  );
}

export function MobileRaffleCart({ navToTickets }: { navToTickets: any }) {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  const reCalc = () => {
    cartItemCount =
      cartStore.items.length > 0
        ? cartStore.items
            .map((el: Cart) => el.quantity)
            .reduce(function (a: any, b: any) {
              return Number(a) + Number(b);
            })
        : 0;
    cartTotalPrice =
      cartStore.items.length > 0
        ? cartStore.items
            .map((el: Cart) => Number(el.quantity) * Number(el.item.ticket_price))
            .reduce(function (a: any, b: any) {
              return Number(a) + Number(b);
            })
        : 0;
    cartTotalPriceWithDiscount =
      Number(cartTotalPrice) -
      (cartStore.couponCode && cartStore.couponCode.minCartCond <= cartTotalPrice
        ? Number(cartStore.couponCode.amount)
        : 0);
    cartStore.total = cartTotalPriceWithDiscount;
  };

  useEffect(() => {
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe((res: any) => {
      reCalc();
      forceUpdate();
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    reCalc();
    forceUpdate();
  }, []);

  const setPointUsage = () => {
    cartStore.pointUsage = !cartStore.pointUsage;
    cartToggleSubject.next({ action: "update" });
    forceUpdate();
  };

  const addCouponCode = async () => {
    modalServiceSubject.next({
      title: "Kupon Kodunu Giriniz",
      afterLoad: () => {
        const sureButton = document.getElementById("id_btnModelServiceSure") as HTMLButtonElement;
        const inputElements = document.querySelectorAll("input.code-input");

        inputElements.forEach((ele: any, index: number) => {
          ele.addEventListener("keydown", (e: KeyboardEvent) => {
            const target = e.target as HTMLInputElement;
            if (e.key === "Backspace" && target.value === "") {
              (inputElements[Math.max(0, index - 1)] as HTMLInputElement).focus();
            }
          });

          ele.addEventListener("input", (e: Event) => {
            const target = e.target as HTMLInputElement;
            const first = target.value.charAt(0);
            target.value = first;

            const lastInputBox = index === inputElements.length - 1;
            const didInsertContent = first !== "";

            if (didInsertContent && !lastInputBox) {
              (inputElements[index + 1] as HTMLInputElement).focus();
              inputElements[index + 1].dispatchEvent(new Event("input"));
            }

            const validateCodeValue = Array.from(inputElements)
              .map((inp) => (inp as HTMLInputElement).value)
              .join("");
            if (validateCodeValue.length === 6) {
              sureButton.disabled = false;
            } else {
              sureButton.disabled = true;
            }
          });
        });
      },
      content: `
        <div class="mb-3 px-5 d-flex w-100">
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <div class="clearfix"></div>
        </div>
      `,
      confirm: {
        sure: {
          class: "btn btn-primary",
          label: "Onayla",
          action: () => {
            const inputElements = document.querySelectorAll("input.code-input");
            const couponCode = Array.from(inputElements)
              .map((inp) => (inp as HTMLInputElement).value)
              .join("");
            // checkCouponCode(couponCode)
            //   .then((res) => console.log(res))
            //   .catch((e) => console.log(e));
            cartStore.couponCode = couponCode;
            cartToggleSubject.next({ action: "update", couponCode: couponCode });
          },
        },
        cancel: {
          class: "btn btn-warning",
          label: "Kapat",
          action: () => {},
        },
      },
    });
  };

  const sendOrder = async () => {
    if (!cartStore.pointUsage && cartTotalPriceWithDiscount > auth.member.balance_debit) {
      if (cartTotalPriceWithDiscount < Number(auth.member.balance_debit) + Number(auth.member.balance_point)) {
        modalServiceSubject.next({
          case: "danger",
          title: "Bakiyeniz Yetersiz",
          width: "400px",
          content: `<p class="text-center">
              NP Kullanabilir veya Bakiye yükleyerek ödemeye devam edebilirsiniz.
             </p>
             `,
          confirm: {
            sure: {
              label: "NP Kullan",
              class: "btn btn-warning",
              action: () => {
                setPointUsage();
                sendOrder();
              },
            },
            cancel: {
              label: "Para Yatırma Yöntemleri",
              class: "btn-primary",
              action: () => {
                navigate(paths.deposit.url);
              },
            },
          },
        });
      } else {
        modalServiceSubject.next({
          case: "danger",
          title: "Bakiyeniz Yetersiz",
          width: "400px",
          content: `<p class="text-center">
              Hesabınıza bakiye yükleyerek işleminize devam edebilirsiniz.
             </p>
             `,
          cancel: {
            label: "Para Yatırma Yöntemleri",
            class: "btn-warning w-100",
            action: () => {
              navigate(paths.deposit.url);
            },
          },
        });
      }
    } else if (
      cartStore.pointUsage &&
      cartTotalPriceWithDiscount > Number(auth.member.balance_debit) + Number(auth.member.balance_point)
    ) {
      modalServiceSubject.next({
        case: "danger",
        title: "Bakiyeniz Yetersiz",
        width: "400px",
        content: `<p class="text-center">
             Hesabınıza bakiye yükleyerek işleminize devam edebilirsiniz.
             </p>
             `,

        confirm: {
          cancel: {
            label: "Para Yatırma Yöntemleri",
            class: "btn-warning  w-100",
            action: () => {
              navigate(paths.deposit.url);
            },
          },
        },
      });
    } else {
      orderComplete()
        .then((res: any) => {
          if (res.status) {
            clearCart();
            modalServiceSubject.next({
              case: "success",
              title: "Ödemeniz Başarılı",
              width: "400px",
              content: `<p class="text-center">
      Biletleriniz başarıyla satın alınmıştır. Bilet numaralarınızı ve detaylarını görmek için Biletlerim sayfasına gidebilirsiniz.
     </p>`,
              confirm: {
                sure: {
                  class: "btn btn-primary",
                  label: "Biletlerimi Gör",
                  action: () => {
                    navigate(paths.raffles.url + "/biletlerim");
                    navToTickets();
                    forceUpdate();
                  },
                },
                cancel: {
                  class: "btn btn-warning",
                  label: "Yeni Bilet Al",
                  action: () => {
                    navigate(paths.raffles.url);
                    forceUpdate();
                  },
                },
              },
            });
          } else {
            if (res.error === "re-login") {
              navigate(paths.login.url);
              // modalServiceSubject.next({
              //   title: "Lütfen Giriş Yapınız",
              //   content: "Çekilişe katılmak için giriş yapınız..",
              //   width: 320,
              //   confirm: {
              //     cancel: {
              //       label: "Tamam",
              //     },
              //   },
              // });
            }
          }
          if (res.status === false) {
            if (res.success_count > 0) {
              clearCart();
            }

            modalServiceSubject.next({
              case: "danger",
              title: res.success_count === 0 ? "Biletler Alınamadı" : "Bazı Biletler Alınamadı",
              width: "400px",
              content: `<p class="text-center">
      Bilet alım işlemi başarısız olan bilet tutarı 4nala hesabınıza iade edildi.
     </p>
     
     ${Object.entries(res.data)
       .map(([d, v]: any, k: any) => {
         let dr: any = raffleDraws.find((el: any) => el.no === Number(d));
         return `<div class="row item"> 
              <span class="nowrap fw-bold text-dark">${dr.title_abbreviated} - ${
           v.data.success_count > 0
             ? `<span class="text-success">${v.data.message}</span>`
             : `<span class="text-danger">${v.data.message}</span>`
         }</span>
              ${
                v.data.success_count > 0
                  ? `<span class="d-block text-success fw-medium">Başarılı alınan bilet: ${v.data.success_count} adet</span>`
                  : ""
              }
              ${
                v.data.error_count > 0
                  ? `<span class="d-block text-danger fw-medium">Hatalı bilet: ${v.data.error_count} adet</span>`
                  : ""
              }
        </div>`;
       })
       .join("")}`,
              confirm: {
                sure: {
                  class: "btn btn-primary",
                  label: "Biletlerimi Gör",
                  action: () => {
                    navToTickets();
                    forceUpdate();
                  },
                },
                cancel: {
                  class: "btn btn-warning",
                  label: "Yeni Bilet Al",
                  action: () => {
                    navigate(paths.raffles.url);
                    forceUpdate();
                  },
                },
              },
            });
          }
        })
        .finally(() => {
          clearCart();
          forceUpdate();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <div className={"raffle-cart raffle-mobile-cart rounded-bottom-0"}>
        <div className="d-flex align-items-center align-content-center px-2 py-2">
          <img
            alt={"geri"}
            className={`float-start invert-color ms-0 me-0`}
            style={{ width: "21px" }}
            src={iconBackArrow}
            onClick={() => {
              navigate(paths.raffles.url);
            }}
          />
          <h3 className="text-white fw-bold fs-6 mb-0 w-100 text-center">ÇEKİLİŞ SEPETİM</h3>
        </div>

        <div className="cart-items-wrapper">
          {cartStore.items?.length > 0 ? (
            cartStore.items?.map((item: Cart, key: number) => {
              return (
                <div key={`item-my-raffle-order-${key}`} className="cart-item">
                  <div className="col-6 px-4 py-2">
                    <img className="raffle-image" src={item.item.photo} />
                    <p className={"cart-item-total text-center mt-2"}>
                      Bilet Adeti: <span className={"fw-bold"}> {Number(item.item.ticket_price).toFixed(2)}₺</span>
                    </p>
                  </div>
                  <div className="col-6 ms-1 gap-1">
                    <div className={"d-flex flex-column"}>
                      <h4
                        className="text-nowrap text-truncate"
                        title={item.item.title}
                        onClick={() => {
                          // openRaffleDetail(item.item);
                        }}
                      >
                        {item.item.title}
                      </h4>
                      <p className={"cart-item-total mt-1"}>
                        Çekiliş Tarihi: <span className={"fw-bold"}> {dateFormat(item.item.draw_date)}</span>
                      </p>
                      <p className={"cart-item-total mt-1"}>
                        Çekiliş No: <span className={"fw-bold"}> {item.item.no}</span>
                      </p>
                    </div>
                    <div className="add mt-1" style={{ height: "45px" }}>
                      <div className="input mobile-item-input">
                        <i
                          className={"left"}
                          onClick={() => {
                            if (cartStore.items[key] && cartStore.items[key]?.quantity > 0) {
                              updateCart(cartStore.items[key], key, Number(cartStore.items[key]?.quantity) - 1);
                            }
                          }}
                        >
                          -
                        </i>
                        <input
                          className="no-arrows"
                          type="number"
                          value={cartStore.items[key]?.quantity || ""}
                          onChange={(e: any) => {
                            updateCart(cartStore.items[key], key, e.target.value);
                          }}
                          onBlur={(e: any) => {
                            if (e.target.value === "" || e.target.value === "0") {
                              removeFromCart(cartStore.items[key], key);
                            }
                          }}
                          max={100}
                          step={1}
                          min={1}
                        />
                        <i
                          className={"right"}
                          onClick={() => {
                            if (
                              cartStore.items[key] &&
                              cartStore.items[key]?.quantity < 100
                              // cartStore.items[key]?.quantity < item.remaining_tickets
                            ) {
                              updateCart(cartStore.items[key], key, Number(cartStore.items[key].quantity) + 1);
                            }
                          }}
                        >
                          +
                        </i>
                      </div>
                      <div className="trash mobile-item-total" style={{ height: "45px" }}>
                        {(cartStore.items[key].quantity * Number(item.item.ticket_price)).toFixed(2)} ₺
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-5 gap-3 text-center">
              <img alt="plus" src={cartIcon} style={{ color: "#8389E4", width: "100%", height: "100px" }} />

              <span className="text-white">Sepetinizde Bilet Bulunmamaktadır.</span>
            </div>
          )}
        </div>

        <div className={"col-12"}>
          <div className="mobile-raffle-cart sticky" style={{ width: "100%", height: "28vh" }}>
            <div className="row">
              <div className="col-12">
                <h2>SEPETİM</h2>
              </div>
            </div>
            {/*  <div className={"mobile-raffle-cart-total mb-1"}>
                            <div className="basket-summary-info">
                                <div className="basket-summary-info-left" onClick={()=>{
                                    addCouponCode();
                                }}>
                                    <div className="basket-summary-info-btn"><label>
                                        <div><img src={plusCircle} className={"invert-color"} width="18"/><br/>İndirim
                                            Kodu Giriniz
                                        </div>
                                    </label></div>
                                </div>
                                <div className="alert-link basket-summary-info-right coupon-code d-inline">
                                        <div className="align-items-center d-flex justify-content-center mt-3 w-100">
                                            <button onClick={()=>{
                                                navigate(paths.campaigns.url);
                                            }} className="btn btn-primary">Kampanyaları İncele</button>
                                        </div>

                                </div>
                            </div>
                        </div>*/}
            <div className={"mobile-raffle-cart-total"}>
              <div className="row">
                <div className={"col-4 text-center align-content-center"} style={{ fontSize: "14px" }}>
                  ADET
                  <br /> <b>{cartStore.items?.length || 0}</b>
                </div>
                <div className={"col-4 text-center align-content-center"} style={{ fontSize: "14px" }}>
                  TUTAR
                  <br />
                  <b>
                    {cartStore.items
                      ?.reduce((accumulator: any, item: any) => {
                        const price = Number(item.item.ticket_price);
                        const quantity = Number(item.quantity) || 1;
                        return accumulator + price * quantity;
                      }, 0)
                      .toFixed(2)}
                    ₺
                  </b>
                </div>
                <div className={"col-4 text-center align-content-center pe-3"} style={{ fontSize: "14px" }}>
                  PUAN
                  <br />
                  <div className={"d-flex align-items-center justify-content-center"}>
                    <input
                      className={"me-1"}
                      type="checkbox"
                      value={cartStore.pointUsage}
                      checked={cartStore.pointUsage}
                      disabled={auth.member ? auth.member.balance_point <= 0 : true}
                      onChange={() => {
                        setPointUsage();
                      }}
                    />
                    <span> {auth.member ? auth.member.balance_point + "P" : "0.00P"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-12 text-center"}>
                <button
                  className="btn btn-primary mt-2 fw-bold w-100"
                  onClick={sendOrder}
                  disabled={cartStore.items?.length <= 0}
                >
                  ALIŞVERİŞİ TAMAMLA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
