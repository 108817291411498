import iconClock from "../../assets/feather/clock.svg";
import React, {useEffect, useState} from "react";
import {availableHippodromes, racesActionSubject} from "../../store/bulletin.store";
import {bulletinProvider} from "../../services/bulletin.service";
import {ServerDate, useForceUpdate} from "../../services/misc.functions";
import {homeHippodromeSubject, tipstersCommentData, tipstersData} from "../../store/misc.store";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";

import {isMobile} from "../../App";
import moment from "moment";
import {useParams} from "react-router-dom";
import TipsterTickets from "../home/tipster.tickets";
import {Coupons} from "../tipster/coupons";


const tabs = [
    { value: 0, label: "HAZIR KUPONLAR" },
    { value: 1, label: "YORUMLAR" },

];

export function Comments(props:any) {
    const params = useParams();

    const [tipsters, setTipsters] = useState<any>([]);
    const [selectedTipster, setSelectedTipster] = useState<any>(null);
    const [hippodrome, setHippodrome] = useState<any>(null);

    const [selectedTab, setSelectedTab] = useState<any>(params?.tab ? tabs[Number(params?.tab)]: tabs[1]);
    const forceUpdate = useForceUpdate();

    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });

    bulletinProvider.getAvailableHippodromes();

    if (!hippodrome && availableHippodromes.length > 0) {
        setHippodrome(availableHippodromes[0]);
    }


    // console.log('hippodrome', hippodrome)


    let today = ServerDate();
    let date = moment(today).format('DDMMYYYY');

    useEffect(() => {

        if (tipstersCommentData[date]) {
            setTipsters(tipstersCommentData[date].filter((h: any) => Number(h.card_id) === Number(hippodrome?.card_id)).map((el: any) => el.tipster));
            setSelectedTipster(tipstersCommentData[date].filter((h: any) => Number(h.card_id) === Number(hippodrome?.card_id)).map((el: any) => el.tipster)[0]);
            forceUpdate();
        } else {
            let api = new ApiService()
            api.start('get', `${env.tipsters_api}/misc/api/racing-tip/`, null, false)
                .then((res: any) => {
                    tipstersCommentData[date] = res.data;
                    setTipsters(tipstersCommentData[date].filter((h: any) => Number(h.card_id) === Number(hippodrome?.card_id)).map((el: any) => el.tipster));
                    setSelectedTipster(tipstersCommentData[date].filter((h: any) => Number(h.card_id) === Number(hippodrome?.card_id)).map((el: any) => el.tipster)[0]);
                    forceUpdate();
                })
        }

    }, [date, hippodrome?._id]);

    if (!hippodrome?.id || selectedTipster === null) {
        return <></>;
    }

    let tips = tipstersCommentData[date].filter((el: any) => Number(el.card_id) === Number(hippodrome?.card_id) && el.tipster.id === selectedTipster?.id)

    console.log(tips);

    return (<div className={"page-widget"}>

        { props.hideTabs !== true  && isMobile && <svg style={{display: "none"}}>
            <defs>
                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
            </defs>
        </svg>
        }

        { props.hideTabs !== true  && isMobile && <ul className="nav nav-tabs">
            {tabs.map((tab: any, index: number) => {
                return (
                    <li
                        key={`home-tabs-${index}`}
                        className={`nav-item ${selectedTab.value === tab.value ? "active" : ""}`}
                        onClick={handleSelect}
                    >
                        <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                            <use xlinkHref="#tabshape"></use>
                        </svg>
                        <a className="nav-link" id={`${index}`}>
                            {tab.label.toUpperCase()}
                        </a>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                            <use xlinkHref="#tabshape"></use>
                        </svg>
                    </li>
                );
            })}
        </ul>}

        {selectedTab.value === 1 && <>

            {isMobile && <div className="bettingtype-options p-3">
                <div className="row">

                    <div className={`col-12`}>
                        <label>HİPODROM</label>
                        {hippodrome ? <select value={hippodrome._id} onChange={(e: any) => {
                            setHippodrome(availableHippodromes.find((data: any) => data._id === e.target.value));
                        }}>
                            {availableHippodromes.map((data: any, key: any) => {
                                return (<option value={data._id}
                                                key={`available-hippodrome-${key}`}>{data.location}</option>)
                            })}
                        </select> : null}

                    </div>
                    <div className={`col-12 mt-2`}>
                        <label>UZMANLAR</label>
                        {hippodrome ? <select value={selectedTipster?.id} onChange={(e: any) => {
                            setSelectedTipster(tipsters.find((data: any) => data.id.toString() === e.target.value.toString()))
                        }}>
                            {tipsters.map((tipster: any, key: any) => {
                                return (<option value={tipster.id}
                                                key={`available-hippodrome-${key}`}> {tipster.nick_name ?? `${tipster.first_name} ${tipster.last_name}`}</option>)
                            })}
                        </select> : null}

                    </div>

                </div>
            </div>}
            <div className="row">
                <div className="col">
                    <div className="page-widget">

                        <svg style={{display: 'none'}}>
                            <defs>
                                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                            </defs>
                        </svg>
                        {!isMobile && <div className={"tab-scroll"}>
                            <ul className="nav nav-tabs">
                                {availableHippodromes.map((item: any, keyHippodrome: number) => {
                                    return (<li key={`runItem-${keyHippodrome}`}
                                                className={`nav-item ${hippodrome?._id === item._id && 'active'}`}>
                                        <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                            <use xlinkHref="#tabshape"></use>
                                        </svg>
                                        <a className="nav-link d-flex align-items-center nowrap-ellipsis"
                                           onClick={() => {
                                               setHippodrome(item);
                                           }}>{item.location}</a>
                                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                            <use xlinkHref="#tabshape"></use>
                                        </svg>
                                    </li>)
                                })}
                            </ul>
                        </div>}

                        <div className="p-3">

                            {tips && tips.length === 0 && <p className="p-4 text-center">
                                <img alt={"iconClock"} src={iconClock} width={64}/> <br/><br/>
                                Seçtiğiniz hipdromdaki koşular için henüz yorum yapılmamış, <br/> Uzman tahmincilerimiz
                                sizler
                                için en kısa zamanda tahminlerini paylaşacaktır.</p>}


                            <div className="row">
                                {!isMobile && <div className={"col-12 col-lg-3"}>
                                    <ul className={`tipsters-list ${!isMobile ? 'flex-column overflow-scroll' : ''}`}>
                                        {tipsters.map((tipster: any, index: number) => {
                                            return (<li key={'tipster-' + tipster.id} onClick={() => {
                                                setSelectedTipster(tipster)
                                            }}
                                                        className={`${!isMobile ? 'tipster' : ''} ${index !== 0 && !isMobile ? 'mt-2' : ''} ${selectedTipster?.id === tipster?.id ? "active" : ''}`}>
                                                <img className="avatar"
                                                     src={tipstersData[tipster.id]?.avatar || '/assets/tipster/default_avatar_tipster_at_yarisi.png'}
                                                     alt=""/> {tipster.nick_name ?? `${tipster.first_name} ${tipster.last_name}`}
                                            </li>)
                                        })}
                                    </ul>
                                </div>}
                                <div className={"col-12 col-lg-9"}>
                                    {selectedTipster && <div className={"tipster-info"}>
                                        <img className="avatar"
                                             src={tipstersData[selectedTipster.id]?.avatar || '/assets/tipster/default_avatar_tipster_at_yarisi.png'}
                                             alt=""/>
                                        <div className={"d-flex flex-column"}>

                                            {selectedTipster.nick_name ?? `${selectedTipster.first_name} ${selectedTipster.last_name}`}
                                            <span>
                                            {tipstersData[selectedTipster.id]?.note}
                                        </span>
                                        </div>

                                    </div>}

                                    {tips?.map((tip: any, index: number) => {
                                        return <>
                                            {tip?.run_tips?.map((run: any, index: number) => {
                                                return (<div key={'run-tip-' + index} className="tipster-comment">
                                                    <div>{run.run}.KOŞU</div>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: run.text.replace(/\n\r/g, "").replace(/\r\n/g, "")}}></div>
                                                </div>)
                                            })
                                            }
                                        </>

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}

        {selectedTab.value === 0 && <Coupons hideTabs={true} />}

    </div>)
}
