import React, {useEffect, useState} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {Storage} from "../../services/localstorege.service";
import {ApiService} from "../../services/http.service";
import {changedJokeyUrlPrefix} from "../../constants/global.vars";
import {homeHippodrome, homeHippodromeSubject} from "../../store/misc.store";

let hippodromeList: any = [];

export function ChangedJokeys() {
    const forceUpdate = useForceUpdate();
    // const [data, setData] = useState<any>([]);
    const [jokeyChanges, setJockeyChanges] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            if (hippodromeList.length === 0) {
                getData();
            } else {
                getHippodromejokeys();
            }

        })
        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }
    }, []);


    const getData = () => {

        // let cachedData = Storage.get(`changedJokeys`);
        // if (!cachedData) {
        let api = new ApiService()
        api.start('get', changedJokeyUrlPrefix, null, false)
            .then((res: any) => {
                if (res.status) {
                    hippodromeList = res.data;
                    setTimeout(() => {
                        getHippodromejokeys();
                    }, 1000);

                } else {
                    hippodromeList = [];
                }
            })
        // } else {
        //     setData(cachedData);
        // }
    }

    const getHippodromejokeys = () => {
        var selectedHippodromeJokey;
        for (const hippodromeListElement of hippodromeList) {
            if (hippodromeListElement.keyword === homeHippodrome.keyword) {
                selectedHippodromeJokey = hippodromeListElement;
            }
        }
        if (selectedHippodromeJokey) {
            setJockeyChanges(selectedHippodromeJokey.jockey_changes);
        }
    }

    const getHorseName = (runNo: any, horseNo: any) => {
        try {
            var selectedRun = homeHippodrome.runs.filter((el: any) => Number(el.no) === Number(runNo));
            var selectedHorse = selectedRun[0].horseMap[horseNo];
            return selectedHorse.name;
        } catch (e: any) {
            return horseNo;
        }
    }


    return <>
        {/*<div className={"home-header"}>*/}
        {/*    JOKEY DEĞİŞİKLİKLERİ*/}
        {/*</div>*/}
        {jokeyChanges.length > 0 && <table className={"w-100 table table-primary table-borderless table-striped"}>
            <thead>
            <tr>
                <th className={"text-center"} style={{width: 75}}>Koşu</th>
                <th>At</th>
                <th>Jokey</th>
            </tr>
            </thead>
            <tbody>

            {jokeyChanges.map((jockey: any, index: any) => {
                return <tr key={`changes-jokey-${index}`}>
                    <td className={"text-center"}>{jockey.run_no}</td>
                    <td>{getHorseName(jockey.run_no, jockey.horse_no)}</td>
                    <td>{jockey.jockey_old}</td>
                </tr>
            })}
            </tbody>
        </table>}
        {jokeyChanges.length === 0 &&
            <p className="text-center p-4">Bu hipodromda jokey değişikliği yoktur.</p>
        }
    </>
}
