export class StorageService {
  set(key:string, val:any, timeout:any=null){
    let expire = null
    if(timeout){
      const now = new Date()
      expire = now.getTime() + timeout
    }
    const data:any = {val, expire}
    localStorage.setItem(key, JSON.stringify(data))
  }

  get(key:string){
    const now = new Date()
    const expire = now.getTime()
    const val:any = localStorage.getItem(key);
    if(val === null){
      return null;
    }
    try {
      const data: any = JSON.parse(val)
      if(data.expire !== null && data.expire < expire){
        localStorage.removeItem(key);
        return null;
      }else{
        return data.val;
      }
    }catch (e:any){
      localStorage.clear();
      return null
    }

  }

  ttl(key:string){

    const now = new Date()
    const expire = now.getTime()
    const val:any = localStorage.getItem(key);
    if(val === null){
      return null;
    }
    try {
      const data: any = JSON.parse(val)
      if(data.expire !== null && data.expire < expire){
        localStorage.removeItem(key);
        return null;
      }else{
        return data.expire - expire;
      }
    }catch (e:any){
      localStorage.clear();
      return null
    }
  }

  del(key:string){
    localStorage.removeItem(key);
    return null;
  }
}

export const Storage = new StorageService();
