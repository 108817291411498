import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {JockeyStatus} from "../home/jockey.status";


const tabs = [
    { value: 0, label: "Raporlu Jokeyler" },
    { value: 1, label: "Cezalı Jokeyler" },
];
export function JockeysStats() {
    const params = useParams();

    const [selectedTab, setSelectedTab] = useState<any>(params?.tab ? tabs[Number(params?.tab)]: tabs[0]);

    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    return (<>
        <div className="page-widget">

            <svg style={{display: "none"}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>


            <ul className="nav nav-tabs">
                {tabs.map((tab: any, index: number) => {
                    return (
                        <li
                            key={`home-tabs-${index}`}
                            className={`nav-item ${selectedTab.value === tab.value ? "active" : ""}`}
                            onClick={handleSelect}
                        >
                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                            <a className="nav-link" id={`${index}`}>
                                {tab.label.toUpperCase()}
                            </a>
                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                        </li>
                    );
                })}
            </ul>

            <div className={`p-3`}>

                {selectedTab.value === 0 && <JockeyStatus all={true} only={'reported'}/>}

                {selectedTab.value === 1 && <JockeyStatus all={true} only={'forbidden'}/>}

            </div>

        </div>
    </>)
}
