import {Subject} from "rxjs";

export const agreementSubject:any = new Subject();
export const newsSubject:any = new Subject();
export const flatPageSubject:any = new Subject();
export const homeSlides:any = [];
export const homeSliderSubject:any = new Subject();
export const notifications:any = [];
export const homeHippodromeSubject:any = new Subject();
export const supportTicketWsSubject:any = new Subject();
export const homeHippodrome:any = {};
export const todayResults: any = {};
export const todayResultsSubject: any = new Subject();

export const tipstersData:any = {};

export const tipstersCommentData:any = {};

export const path:any = {
  full:'/',
  root:'/'
}

export const ConnectionStatus:any = {
  isConnected: false,
}
