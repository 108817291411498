import {env, passwordModalStyles} from "../../constants/global.vars";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import paths from "../../paths";
import {SubmitHandler, useForm} from "react-hook-form";
import {LoginRequest} from "../../models/auth.model";
import {ApiService} from "../../services/http.service";
import {exceptionModal} from "../../services/misc.functions";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router";

export function ResetPassword(){

  const {pathname} = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const [showPasswordResetModal, setShowPasswordResetModal] = useState<any>(pathname===paths.resetPassword);


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>()

  useEffect(()=>{
    if(pathname===paths.resetPassword.url){
      setShowPasswordResetModal(true);
    }
  },[pathname]);


  const onSubmit: SubmitHandler<LoginRequest> = (payload:any) => {
    payload['hash'] = searchParams.get('hash');
    console.log(payload)

    if(payload.password !== payload.password2){
      return ;
    }
    let api = new ApiService();
    api.start('post', env.accounting_api +'/member/api/password-renew/', payload, false)
      .then((res:any)=>{
        console.log(res)

        if(res.status){
          setShowPasswordResetModal(false);
          modalServiceSubject.next({
            case:'success',
            title: 'Parola sıfırlandı',
            content:`<p>Yeni parolanızın tanımlanması yapıldı. artık güncel parolanız ile giriş yapabilirsiniz.</p>`,
            confirm:{
              cancel:{
                label: 'Kapat',
              },
              sure:{
                label:'Giriş Yap',
                action:()=>{
                  navigate(paths.login);
                }
              }
            }
          })
        }else{
          setErrorMessage(res.message)
        }

      })
      .catch((e:any)=>{
        exceptionModal(e)
      })

  }

  return (<>

    <Modal
      isOpen={showPasswordResetModal}
      onRequestClose={()=>{}}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          maxWidth: 'calc(100vw - 80px)',
          width: '460px',
          padding: '1.5rem',
          maxHeight: 'calc(100vh - 100px)',
          transform: 'translate(-50%, -50%)',
          background: 'var(--bs-white)',
          borderRadius: '1rem',
        },overlay: {
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          backdropFilter: 'blur(10px)',
        }

      }}
      contentLabel=""
    >
      <div className="forget-password-modal">
        <h2>Parola Sıfırla</h2>

        <p>
          Şifreniz en az 6 karakterden oluşmalı ve bir küçük harf, bir büyük harf, bir sayı ve bir özel karakter içermelidir.
        </p>

        {errorMessage && <p className="alert text-center alert-danger">
          {errorMessage}
        </p>}

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="mt-2">
            <label>Parola</label>
            <input type="password" className="form-control" {...register('password', { required: true })} />
            {errors.password && <div className="invalid-feedback d-block">Parola girmeniz gerekli.</div>}
          </div>

          <div className="mt-2">
            <label>Parola (Tekrar)</label>
            <input type="password" className="form-control" {...register('password2', { required: true })} />
            {errors.password2 && <div className="invalid-feedback d-block">Parolanızı tekrar girmeniz gerekli.</div>}
          </div>

          <div className="mt-3 text-end">
            <button type="button" onClick={()=>{
              setShowPasswordResetModal(false);
              navigate(paths.home);
            }} className="btn btn-secondary">Vazgeç</button>

            <button type="submit" className="btn ms-2 btn-primary">Parolayı Kaydet</button>

          </div>


        </form>

      </div>
    </Modal>

  </>);
}
