import React, {useEffect, useState} from "react";
import TipsterTickets from "./tipster.tickets";
import {DailyRacesWidget} from "./daily.races";
import {JockeyStatus} from "./jockey.status";
import {isMobile} from "../../App";
import {homeHippodrome, todayResults} from "../../store/misc.store";
import {TipstersComments} from "./tipsters.comments";
import {NotRunningHorses} from "./not.running.horses";
import {ChangedJokeys} from "./changed.jokeys";


// let tabs: any = [];

export default function Tabs(props: any) {
    const [tabs, setTabs] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState<any>();

    useEffect(() => {
        if (isMobile) {
            setTabs([
                {value: "hazir-kuponlar", label: "Hazır Kuponlar", content: <TipsterTickets/>},
                {value: "sonuçlar", label: "Sonuçlar", content: <DailyRacesWidget/>},
            ]);
            setSelectedTab({value: "hazir-kuponlar", label: "Hazır Kuponlar", content: <TipsterTickets/>});

        } else {
            setTabs([
                {value: "hazir-kuponlar", label: "Hazır Kuponlar", content: <TipsterTickets/>},
                {value: "sonuçlar", label: "Sonuçlar", content: <DailyRacesWidget showResults={true}/>},
                {
                    value: "uzman-yorumlari",
                    label: "Uzman Yorumları",
                    content: <TipstersComments hippodrome={homeHippodrome}></TipstersComments>
                },
                {value: "gunun-kosulari", label: "Günün Koşuları", content: <DailyRacesWidget/>},
                // {value: "raporlu-cezali-jokeyler", label: "Raporlu-Cezalı Jokeyler", content: <JockeyStatus/>},
                {value: "kosmayan-atlar", label: "Koşmayan Atlar", content: <NotRunningHorses/>},
                {value: "jokey-degisikligi", label: "Jokey DEĞİŞİKLİĞİ", content: <ChangedJokeys/>},

            ]);

            if (todayResults[homeHippodrome.keyword] != null) {
                setSelectedTab({value: "sonuçlar", label: "Sonuçlar", content: <DailyRacesWidget showResults={true}/>});
            } else {
                setSelectedTab({value: "hazir-kuponlar", label: "Hazır Kuponlar", content: <TipsterTickets/>});
            }


        }

        if (props.contentType === "jockey-status") {


            setTabs([
                {value: "raporlu-jokeyler", label: "Raporlu Jokeyler", content: <JockeyStatus only={'reported'}/>},
                {value: "cezali-jokeyler", label: "Cezalı Jokeyler", content: <JockeyStatus only={'forbidden'}/>},
            ]);
            setSelectedTab({
                value: "raporlu-jokeyler",
                label: "Raporlu Jokeyler",
                content: <JockeyStatus only={'reported'}/>
            });
        }

        if (props.contentType === "jockey-changes") {

            setTabs([
                {value: "kosmayan-atlar", label: "Koşmayan Atlar", content: <NotRunningHorses/>},
                {value: "jokey-degisikligi", label: "Jokey Değişikliği", content: <ChangedJokeys/>},
            ]);
            setSelectedTab({value: "kosmayan-atlar", label: "Koşmayan Atlar", content: <NotRunningHorses/>});

        }

    }, []);


    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };


    return (
        <div className="page-widget mb-3 mb-lg-0 mb-md-0">
            <svg style={{display: "none"}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>

            {/* Tab Header */}
            <ul className="nav nav-tabs">
                {tabs.map(({value, label}: { value: string; label: string }, index: number) => {
                    return (
                        <li key={`home-tabs-${index}`}
                            className={`nav-item ${selectedTab && selectedTab.value === value ? "active" : ""}`}
                            onClick={handleSelect}>
                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                            <a className="nav-link" id={`${index}`}>
                                {label.toUpperCase()}
                            </a>
                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                        </li>
                    );
                })}
            </ul>

            {/* Tab Content */}
            {selectedTab && <div className="p-3 p-lg-3">{selectedTab.content}</div>}
        </div>
    );
}
