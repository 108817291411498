import React, {useEffect, useState} from "react";
import {MobileTicket, Ticket} from "../tipster/components/ticket";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {useForceUpdate} from "../../services/misc.functions";
import {homeHippodrome, homeHippodromeSubject} from "../../store/misc.store";
import {Storage} from "../../services/localstorege.service";
import iconClock from "../../assets/feather/clock.svg";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import {Link} from "react-router-dom";
import paths from "../../paths";
import {isMobile} from "../../App";

export default function TipsterTickets() {
    const forceUpdate = useForceUpdate();
    const [coupons, setCoupons] = useState<any>(false)

    useEffect(() => {
        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }
    }, []);





    useEffect(() => {
        // Storage.del(`tipsterCoupons-${homeHippodrome.id}`);
        setTimeout(() => {
            let cachedData = Storage.get(`tipsterCoupons-${homeHippodrome.id}`);
            if (!cachedData) {
                setCoupons(false);
                let api = new ApiService()
                api.start('post', env.tipsters_api + '/games/api/tjk/tipster-coupons', {card_id: homeHippodrome.card_id}, false)
                    .then((res: any) => {

                        if (res.status) {
                            Storage.set(`tipsterCoupons-${homeHippodrome.id}`, res.data, 1 * 60 * 1000)
                            setCoupons(res.data)
                        } else {
                            setCoupons([])
                        }
                    })
            } else {
                setCoupons(cachedData);
            }
        }, 300)
    }, [homeHippodrome?.id, homeHippodrome?.card_id]);

    return (
        <>

            <div className={"tipster-tickets-widget"}>
                {!coupons && homeHippodrome?.id && <>

                    <Swiper
                        spaceBetween={isMobile ? 5 : 10}
                        modules={[Navigation, Pagination]}
                        navigation={isMobile ? false : {
                            prevEl: '.prev-tipster-ticket',
                            nextEl: '.next-tipster-ticket',
                        }}
                        pagination={isMobile ? {
                            el: '.swiper-custom-pagination',
                        } : false}

                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            960: {
                                slidesPerView: 2,
                                spaceBetween: 10
                            },
                            // when window width is >= 640px
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 10
                            }
                        }}
                    >

                        {Array.from(Array(4).keys()).map((ticket, index) => {
                            return (
                                <SwiperSlide key={`ticketIndex-${index}`}>
                                    <div className="col" key={`home-tickets-${index}`}>
                                        <div className="ticket-widget skeleton">
                                            <div className="header">
                                            </div>
                                            <div className="body">
                                            </div>
                                            <div className="footer">
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                </>}

                {coupons && coupons.length === 0 && <p className="p-4 text-center">
                    <img src={iconClock} width={64} alt="C" /> <br/><br/>
                    Seçtiğiniz hipdromdaki koşular için henüz hazır kupon paylaşılmamış, <br/> Uzman tahmincilerimiz
                    sizler için
                    en kısa zamanda tahminlerini paylaşacaktır.</p>}

                <Swiper
                    spaceBetween={isMobile ? 5 : 10}
                    modules={[Navigation, Pagination]}
                    navigation={isMobile ? false : {
                        prevEl: '.prev-tipster-ticket',
                        nextEl: '.next-tipster-ticket',
                    }}
                    pagination={isMobile ? {
                        el: '.swiper-custom-pagination',
                        clickable:true
                    } : false}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        960: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        // when window width is >= 640px
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 10
                        }
                    }}
                >
                    {coupons && coupons.length > 0 && coupons.map((ticket: any, index: number) => {
                        return (<SwiperSlide key={`ticketIndex-${index}`}>
                            {isMobile ?  <MobileTicket ticket={ticket}/> : <Ticket ticket={ticket}/>}
                        </SwiperSlide>)
                    })}
                </Swiper>
                {!isMobile && <>
                    <div className="swiper-button-prev prev-tipster-ticket"></div>
                    <div className="swiper-button-next next-tipster-ticket"></div>
                </>}
                <div className="swiper-custom-pagination"/>


            </div>
            <Link to={paths.tipsterCoupons.url} className="btn btn-lg btn-warning fw-medium w-100 mt-3">Tüm Hazır
                Kuponları Göster</Link>
        </>
    );
}
