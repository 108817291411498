import React, {useEffect, useState} from "react";
import {auth, authSubject} from "../../store/auth.store";
import {wssProvider} from "../../services/websocket.service";
import {useForceUpdate} from "../../services/misc.functions";
import Modal from "react-modal";

import {Storage} from "../../services/localstorege.service";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";

export function CookieSettings() {
    const [showCookieModal, setShowCookieModal] = useState<any>(false);
    const [agreementObject, setAgreementObject] = useState<any>(null);
    const [functionalCookie, setFunctionalCookie] = useState<any>(false);
    const [targetingCookie, setTargetingCookie] = useState<any>(false);
    const [mandatoryCookie, setMandatoryCookie] = useState<any>(true);
    const forceUpdate = useForceUpdate();

    const cookies: any = {
        "zorunlu": {
            title: "Zorunlu",
            content: "Bu çerezler, web sitesinin doğru bir şekilde çalışması için gereklidir ve kapatılamaz. Bu çerezler, kişisel olarak tanımlanabilir herhangi bir bilgi saklamaz."
        },
        "hedefleme": {
            title: "Hedefleme Amaçlı Tanımlama Bilgileri",
            content: "Bu çerezler ilgi alanlarınızın profilini oluşturmak ve size bu doğrultuda reklamlar göstermek için kullanılır."
        },
        "islevsel": {
            title: "İşlevsel",
            content: "Bu tanımlama bilgileri, videolar ile canlı sohbet gibi gelişmiş işlevler ve kişiselleştirme olanağı sunabilmemizi sağlar. Bunlar, bizim tarafımızdan veya sayfalarımızda hizmetlerinden faydalandığımız üçüncü taraf sağlayıcılarca ayarlanabilir."
        },
    }


    let screenWidth = window.innerWidth;
    if (screenWidth > 768) {
        screenWidth = 720;
    }
     let api = new ApiService()

    const updateCookieSettingsFunctional = (obj: any) => {
        api
            .start(
                "post",
                env.accounting_api + "/member/api/update-account-info/",
                {"functional": obj},
                true
            )
            .then((res: any) => {
                wssProvider.checkauth();
            })
            .finally(() => {
            });
    };
    const updateCookieSettingsTargeting = (obj: any) => {
        api
            .start(
                "post",
                env.accounting_api + "/member/api/update-account-info/",
                {"targeting_cookies": obj},
                true
            )
            .then((res: any) => {
                wssProvider.checkauth();
            })
            .finally(() => {
            });
    };
    const updateCookieSettingsMandatory = (obj: any) => {
        api
            .start(
                "post",
                env.accounting_api + "/member/api/update-account-info/",
                {'mandatory': obj},
                true
            )
            .then((res: any) => {
                wssProvider.checkauth();
            })
            .finally(() => {
            });
    };

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe(() => {
            if (auth.member) {
                setFunctionalCookie(auth.member.functional);
                setTargetingCookie(auth.member.targeting_cookies);
                setMandatoryCookie(auth.member.mandatory)
            }
            forceUpdate();
        });
        return () => {
            auth_subscriber.unsubscribe();
        };
    }, []);

    useEffect(() => {
        var isAccepted = Storage.get("cookies-policy");
        if (isAccepted){
            setFunctionalCookie(true);
            setTargetingCookie(true);
            setMandatoryCookie(true);
        }
    }, []);

    const getSelectedCookieAgreement = (title: any) => {

        setAgreementObject(cookies[title]);
        setShowCookieModal(true);


    }

    return (
        <>
            <h1 className={"text-light"}>Çerez Ayarları</h1>
            <div className="page-widget">
                <form className="member-form">
                    <div className="row px-2">
                        <div className="form-check form-switch notification-item">
                            <label onClick={() => {
                                getSelectedCookieAgreement('islevsel');
                            }}
                                   className="form-check-label pointer"
                                   htmlFor="id_switch_mail_notification">
                                <h3 className="cookie-form-label">İşlevsel</h3>

                                <span style={{whiteSpace: "normal"}}>
                                        {cookies['islevsel']['content']}
                                      </span>
                            </label>

                            <input
                                checked={functionalCookie}
                                className="form-check-input float-end mt-3"
                                type="checkbox"
                                role="switch"
                                id="functional_cookies"
                                onChange={(e: any) => {
                                    if (auth.member) {
                                        updateCookieSettingsFunctional(!functionalCookie)
                                    } else {
                                        setFunctionalCookie(!functionalCookie);
                                    }


                                }}
                            />
                        </div>
                    </div>
                    <div className={"row px-2 mt-2"}>
                        <div className="form-check form-switch notification-item">
                            <label onClick={() => {
                                getSelectedCookieAgreement('hedefleme');
                            }}
                                   className="form-check-label  pointer"
                                   htmlFor="id_switch_sms_notification"
                            >
                                <h3 className="cookie-form-label">
                                    Hedefleme Amaçlı Tanımlama Bilgileri
                                </h3>
                                <span style={{whiteSpace: "normal"}}>
                                        {cookies['hedefleme']['content']}
                                      </span>
                            </label>
                            <input
                                checked={targetingCookie}
                                className="form-check-input float-end mt-3"
                                type="checkbox"
                                role="switch"
                                id="target_cookies"
                                onChange={(e: any) => {
                                    if (auth.member) {
                                        updateCookieSettingsTargeting(!targetingCookie);
                                    } else {
                                        setTargetingCookie(!targetingCookie);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"row px-2 mt-2"}>
                        <div className="form-check form-switch notification-item">
                            <label onClick={() => {
                                getSelectedCookieAgreement('zorunlu');

                            }}
                                   className="form-check-label  pointer"
                                   htmlFor="is_switch_call_notification"
                            >
                                <h3 className="cookie-form-label">Zorunlu</h3>
                                <span style={{whiteSpace: "normal"}}>
                                        {cookies['zorunlu']['content']}
                                      </span>
                            </label>
                            <input
                                checked={mandatoryCookie}
                                className="form-check-input float-end mt-3"
                                type="checkbox"
                                role="switch"
                                disabled
                                id="must_cookies"
                                onChange={(e: any) => {
                                    if (auth.member) {
                                        updateCookieSettingsMandatory(!mandatoryCookie);
                                    } else {
                                        setMandatoryCookie(!mandatoryCookie);
                                    }

                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>

            {/*<Modal
                isOpen={showCookieModal}
                className={"default"}
                onRequestClose={() => {
                    setShowCookieModal(false);
                }}
                style={{
                    content: {

                        height: '275px',
                    },
                }}
                contentLabel=""

            ><>

                {agreementObject &&
                    <div className="head">
                        <h2 className={"text-center"}>{agreementObject.title}</h2>
                    </div>
                }
                {agreementObject &&
                    <div className="modal-body">
                        <p dangerouslySetInnerHTML={{__html: agreementObject.content.replaceAll('\n\r\n', '<br/>')}}></p>
                    </div>
                }
                <div className="modal-ticket-footer text-end">
                    <div className="wave"></div>
                    <div className="body-row row">
                        <div className="col-12">
                            <button
                                type="button"
                                onClick={() => setShowCookieModal(false)}
                                className="btn w-100 custom-btn-danger me-2">
                                Kapat
                            </button>
                        </div>
                    </div>
                </div>
            </>

            </Modal>*/}
        </>
    );
}
