import React, { useState } from "react";
import { modalServiceSubject } from "./modal.service";
import { toast } from "react-toastify";
import {
  agreements,
  env,
  errorLogStyle,
  flatPages,
  news,
  resultsUrlPrefix,
  successLogStyle,
} from "../constants/global.vars";
import { ApiService } from "./http.service";
import { Storage } from "./localstorege.service";
import {
  agreementSubject,
  ConnectionStatus,
  flatPageSubject,
  homeHippodrome,
  newsSubject,
  tipstersData,
  todayResults,
  todayResultsSubject,
} from "../store/misc.store";
import iconFather from "../assets/feather/father-at-yarisi.svg";
import { wsProvider } from "../App";
import { auth } from "../store/auth.store";
import { cartStore } from "../store/raffle.store";
import { NavigateFunction } from "react-router-dom";
import { getGameRemains } from "./auth.service";
import paths from "../paths";

export let ServerTimeDifference: number = 0;
let resultsInterval: any = null;
export function getServerTime() {
  let browserDate = new Date();

  // api.start('get', env.accounting_api+'/misc/api/get-current-date/?v=' + Math.random().toString(), null, false)
  //   .then((response: any) => {
  //     if (response.status) {
  //       let serverDate = new Date(response.data.currentDate)
  //       // @ts-ignore
  //       ServerTimeDifference = (serverDate.getTime() + (serverDate.getTimezoneOffset() * 60000)) - browserDate.getTime() + (3600000 * 3);
  //       console.log('%cBrowser timedelta: ' + ServerTimeDifference + ' saniye',warninLogStyle);
  //       // console.log("%cTarayıcı Zamanı: "+browserDate, warninLogStyle);
  //       // console.log("%cSunucu Zamanı: "+new Date(response.data.currentDate), warninLogStyle);
  //       // console.log("New date time",new Date(browserDate.getTime() + ServerTimeDifference));
  //     }
  //   }).catch((e: any) => {})
}

getServerTime();

let offset = new Date().getTimezoneOffset() / 60;

export function ServerDate() {
  return new Date(new Date().getTime() + ServerTimeDifference);
}

export function useForceUpdate() {
  // @ts-ignore
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value: any) => value + 1); // update the state to force render
}

export function dateFormat(eventDate: string) {
  let date = new Date(eventDate);
  date = new Date(date.setTime(date.getTime() - offset));
  return date.toLocaleDateString();
}

export function dateTimeFormat(eventDate: string) {
  let date = new Date(eventDate);
  date = new Date(date.setTime(date.getTime() - offset));
  return date.toLocaleDateString() + " " + date.toLocaleTimeString().slice(0, 5);
}

export function showAgreementModal(key: any) {
  let content = agreements[key];
  modalServiceSubject.next({
    title: content.title,
    content: content.content,
    width: 720,
    confirm: {
      cancel: {
        class: "btn w-100 btn-info me-2",
        label: "Kapat",
      },
    },
  });
}

const monthNames: any = {
  "0": "Ocak",
  "1": "Şubat",
  "2": "Mart",
  "3": "Nisan",
  "4": "Mayıs",
  "5": "Haziran",
  "6": "Temmuz",
  "7": "Ağustos",
  "8": "Eylül",
  "9": "Ekim",
  "10": "Kasım",
  "11": "Aralık",
};

export const longDays: any = {
  0: "Pazar",
  1: "Pazartesi",
  2: "Salı",
  3: "Çarşamba",
  4: "Perşembe",
  5: "Cuma",
  6: "Cumartesi",
};
export const shortDays: any = {
  0: "Pzr",
  1: "Pts",
  2: "Sal",
  3: "Çar",
  4: "Per",
  5: "Cum",
  6: "Cmt",
};

export function getDateHumanize(eventDate: string, t: any = "long") {
  var now = ServerDate();
  var date1 = new Date(now.toISOString());
  var date2 = new Date(eventDate);

  var Difference_In_Days = date2.getDate() - date1.getDate();

  let day = "";
  if (Difference_In_Days === 0) {
    day = "Bugün";
  } else if (Difference_In_Days === 1) {
    day = "Yarın";
  } else if (Difference_In_Days > 6) {
    day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
  } else {
    if (t === "long") {
      day = longDays[date2.getDay()];
    } else {
      day = shortDays[date2.getDay()];
    }
  }
  return day;
}

export function dateTimeStringFormat(eventDate: string, year: any = false) {
  let date = new Date(eventDate);
  return (
    date.getDate() + " " + date.toLocaleString("tr-tr", { month: "long" }) + (year ? " " + date.getFullYear() : "")
  );
}

export function backDrop(status: boolean) {
  if (status) {
    try {
      // @ts-ignore
      document.getElementById("backdrop-blur").style.display = "block";
    } catch (e) {}
  } else {
    try {
      // @ts-ignore
      document.getElementById("backdrop-blur").style.display = "none";
    } catch (e) {}
  }
}

export function exceptionModal(e: any, option: any = null) {
  let defaultOption: any = {
    title: "Beklenmedik Bir Hata!",
    content: `<p>${e}</p>`,
    timeout: 5000,
    confirm: {
      cancel: {
        label: "Tamam",
      },
    },
  };
  if (option) {
    for (const [k, v] of Object.entries(option)) {
      defaultOption[k] = v;
    }
  }
  modalServiceSubject.next(defaultOption);
}

export function CurrencyFormat(price: any, decimal: number = 2) {
  if (decimal === 0) {
    return Number(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&#")
      .slice(0, -3)
      .replaceAll("#", ".");
  }
  return toFixedRound(Number(price), Number(decimal))
    .replace(/\d(?=(\d{3})+\.)/g, "$&#")
    .replaceAll(".", ",")
    .replaceAll("#", ".");
}

export function toFixedRound(value: any, pow: any) {
  let i = String(Number(value)).split(".");
  if (pow === 0) {
    return i[0];
  }
  return i[0] + "." + (i.length === 1 ? "00000000000000000".slice(0, pow) : (i[1] + "00000000000000000").slice(0, pow));
}

export function copyMemberNumber(text: string, message: any = null) {
  navigator.clipboard.writeText(text).then(() => {
    toast.success(message ? message : "Kopyalandı!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  });
}

const { innerWidth: width } = window;
export let mobileView = width < 986 ? true : false;

export function getDateTimeHumanize(eventDate: string, t: any = "long") {
  var now = ServerDate();
  var date1 = new Date(now.toISOString());
  var date2 = new Date(eventDate);

  // @ts-ignore
  const diffTime = Math.abs(date1 - date2);
  const Difference_In_Days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  let day = "";
  if (Difference_In_Days === 0) {
    day = "Bugün";
  } else if (Difference_In_Days === -1) {
    day = "Dün";
  } else if (Difference_In_Days === 1) {
    day = "Yarın";
  } else if (Difference_In_Days > 6) {
    day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
  } else {
    if (t === "long") {
      day = longDays[date2.getDay()];
    } else {
      day = shortDays[date2.getDay()];
    }
  }
  return day + " " + date2.toLocaleTimeString().slice(0, 5);
}

export function loadingAnimation(state: any) {
  let loading: any = document.getElementById("id_loaderAnimation");
  if (loading) {
    if (state) {
      loading.style.display = "block";
    } else {
      loading.style.display = "none";
    }
  }
}

export function getTipsters() {
  //fixme prod a geçerken bu satır silinmeli
  Storage.del(`tipsters`);

  let cachedData = Storage.get(`tipsters`);
  if (!cachedData) {
    let api = new ApiService();
    api.start("get", env.tipsters_api + "/member/api/tipsters/", null, false).then((res: any) => {
      Storage.set(`tipsters`, res.data, 1 * 60 * 1000);
      if (res.status) {
        res.data.forEach((tipster: any) => {
          tipster.avatar = tipster.avatar ? env.accounting_api + tipster.avatar : null;
          tipstersData[tipster.id] = tipster;
        });
      }
    });
  } else {
    cachedData.forEach((tipster: any) => {
      tipstersData[tipster.id] = tipster;
    });
  }
}

export async function getAgreements() {
  // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli
  // fixme prod ortama geçince bu satır iptal edilmeli
  Storage.del("agreements");
  let existingAgreements = Storage.get("agreements");
  if (existingAgreements) {
    for (let [k, v] of Object.entries(existingAgreements)) {
      agreements[k] = v;
    }
    agreementSubject.next({ action: "loaded" });
    return agreements;
  }
  let api = new ApiService();
  api
    .start("get", env.accounting_api + "/misc/api/list-agreement/", null, false)
    .then((response: any) => {
      if (response.status) {
        for (let item of response.data) {
          agreements[item.slug] = item;
        }
        Storage.set("agreements", agreements, 60 * 60 * 1000);
        agreementSubject.next({ action: "loaded" });
      }
    })
    .catch((e: any) => {
      return null;
    })
    .finally(() => {
      return agreements;
    });
}

export async function getFlatPages() {
  // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli
  // fixme prod ortama geçince bu satır iptal edilmeli
  Storage.del("flatpages");
  let existingFlatPages = Storage.get("flatpages");
  if (existingFlatPages) {
    for (let [k, v] of Object.entries(existingFlatPages)) {
      flatPages[k] = v;
    }
    flatPageSubject.next({ action: "loaded" });
    return flatPages;
  }

  let api = new ApiService();
  api
    .start("get", env.accounting_api + "/misc/api/list-flatpages/", null, false)
    .then((response: any) => {
      if (response.status) {
        for (let item of response.data) {
          flatPages[item.slug] = item;
        }
        Storage.set("flatpages", flatPages, 60 * 1000);
        flatPageSubject.next({ action: "loaded" });
      }
    })
    .catch((e: any) => {
      return null;
    })
    .finally(() => {
      return flatPages;
    });
}

export async function getNews() {
  // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli

  let existingNews = Storage.get("news");
  if (existingNews) {
    Object.assign(news, existingNews);
    newsSubject.next({ action: "loaded" });
  }

  let api = new ApiService();
  api
    .start("get", env.accounting_api + "/misc/api/list-news/", null, false)
    .then((response: any) => {
      if (response.status) {
        // Object.assign(news, []);
        response.data.forEach((item: any) => {
          let hasAdded = news.find((e: any) => e.id === item.id);
          if (!hasAdded) {
            news.push(item);
          }else{
            hasAdded.photo_web = item.photo_web;
            hasAdded.photo_mobile = item.photo_mobile;
          }
        });
        Storage.set("news", news, 24 * 60 * 60 * 1000);
        newsSubject.next({ action: "loaded" });
      }
    })
    .catch((e: any) => {})
    .finally(() => {});
}

export function isOnline() {
  ConnectionStatus.isConnected = true;
  console.log("%c Internet connected up again :)", successLogStyle);
}

export function isOffline() {
  ConnectionStatus.isConnected = false;
  console.log("%c Ops, Connection lost ! :(", errorLogStyle);
  if (wsProvider && wsProvider.wsHandler && wsProvider.wsHandler.readyState === 1) {
    wsProvider.wsHandler.close();
  }
}

export function getTodayResults() {
  let startDate = new Date();
  let api = new ApiService();
  api
    .start(
      "get",
      `${resultsUrlPrefix}/${String(startDate.getDate()).padStart(2, "0")}${String(startDate.getMonth() + 1).padStart(
        2,
        "0"
      )}${startDate.getFullYear()}/`,
      null,
      true
    )
    .then((response: any) => {
      if (response.status) {
        response.data.forEach((result: any) => {
          result.runsMap = [];
          result.runs.forEach((run: any) => {
            result.runsMap[run.no] = run;
            if (run.horses != null) {
              run.winnerHorse = run.horses.filter((el: any) => el.result != null && el.result.toString() === "1")[0];
              if (run.winnerHorse?.stablemate > 0) {
                run.winnerStablemate = run.horses.filter(
                  (e: any) => Number(e.stablemate) === Number(run.winnerHorse?.stablemate)
                );
              }
            }
          });
          todayResults[result.keyword] = result;
          todayResultsSubject.next(true);
        });
      }
    });
  if (resultsInterval === null) {
    resultsInterval = setInterval(getTodayResults, 300 * 1000);
  }
}

export const preventBetSlipValues = (e: any) => ["e", "E", "+", "-", ",", "."].includes(e.key) && e.preventDefault();
export const preventNegativeValues = (e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const gtmViewItemList = (itemArray: any, categoryName: any) => {
  /**
   Kullanıcı herhangi bir ödül, oyun veya çekiliş listesini görüntülediğinde view_item_list eventi pushlanmalıdır.
   Event ile birlikte datalayer içinde event parametreleri ve ödül, oyun veya çekiliş bilgilerini içeren item array’i bulunmalıdır. Normalde kullanıcı o an listede kaç ödül/oyun/çekiliş görüyor ise item array’i o kadar ödül/oyun/çekiliş içermelidir.
   Item_list_name’ler görüntülenen itemin kategorisine göre değişkenlik göstermektedir.
   Bir ödül/oyun/çekilişin ilk görüntülendiği liste adı işlem yapılana kadar yani purchase’a kadar hep aynı olmalıdır.
   Bu şekilde listelerin performanslarını da değerlendirebileceğiz.

   **/
  try {
    var items = [];
    if (itemArray.length > 0) {
      for (const item of itemArray) {
        if (categoryName === "Çekiliş") {
          items.push({
            item_id: item.no, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: item.ticket_price,
            quantity: 1,
          });
        } else if (categoryName === "Oyun") {
          items.push({
            item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: 1,
            quantity: 1,
          });
        } else {
          items.push({
            item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: item.price,
            quantity: 1,
          });
        }
      }
      // @ts-ignore
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      // @ts-ignore
      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_id: "", //Kullanıcının görüntülediği item'in kategorisinin id'si
          item_list_name: categoryName, //Kullanıcının görüntülediği item'in kategorisinin ismi bu senaryo için Çekiliş, Oyunlar veya Ödüller basılmalı
          items: items,
        },
      });
    }
  } catch (e: any) {
    console.log(e);
  }
};

export const gtmSelectItem = (itemArray: any, categoryName: any) => {
  /**
   Herhangi bir listeden oyun/çekiliş/ödülün detayına gitmek için üzerine tıklandığında select_item eventi pushlanmalıdır.
   **/
  try {
    var items = [];
    if (itemArray.length > 0) {
      for (const item of itemArray) {
        if (categoryName === "Çekiliş") {
          items.push({
            item_id: item.no, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: item.ticket_price,
            quantity: 1,
          });
        } else if (categoryName === "Oyunlar") {
          items.push({
            item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: 1,
            quantity: 1,
          });
        } else {
          items.push({
            item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
            item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
            item_category: categoryName,
            price: item.price,
            quantity: 1,
          });
        }
      }

      // @ts-ignore
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      // @ts-ignore
      dataLayer.push({
        event: "select_item",
        ecommerce: {
          item_list_id: "", //Kullanıcının görüntülediği item'in kategorisinin id'si
          item_list_name: categoryName, //Kullanıcının görüntülediği item'in kategorisinin ismi bu senaryo için Çekiliş, Oyunlar veya Ödüller basılmalı
          items: items,
        },
      });
    }
  } catch (e: any) {}
};

export const gtmViewItem = (itemArray: any, categoryName: any) => {
  /**
   Kullanıcı bir oyun/çekiliş/ödülün detay sayfasını görüntülediğinde view_item eventi pushlanmalıdır.
   Item_list_id ve item_list_name, oyun/çekiliş/ödüllerin bulunduğu liste adları olarak basılmalıdır.
   **/
  try {
    var items = [];

    for (const item of itemArray) {
      if (categoryName === "Çekiliş") {
        items.push({
          item_id: item.no, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
          item_name: item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
          item_category: categoryName,
          price: item.ticket_price,
          quantity: 1,
        });
      } else if (categoryName === "Oyunlar") {
        items.push({
          item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
          item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
          item_category: categoryName,
          price: 1,
          quantity: 1,
        });
      } else {
        items.push({
          item_id: item.code, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
          item_name: item.name, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
          item_category: categoryName,
          price: item.price,
          quantity: 1,
        });
      }
    }

    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "TRY",
        value: itemArray[0].item.draw_price,
        items: items,
      },
    });
  } catch (e: any) {}
};

export const gtmAddToCart = (itemArray: any, categoryName: any) => {
  /**
   Kullanıcı herhangi bir sepete ürün ekleme alanında sepete ürün attığında pushlanması gereken event.
   **/
  try {
    var items = [];
    var totalPrice = 0;
    for (const item of itemArray) {
      items.push({
        item_id: item.item.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
        item_name: item.item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
        item_category: categoryName,
        price: item.item.draw_price,
        quantity: item.quantity,
      });
      totalPrice += Number(item.quantity) * Number(item.item.draw_price);
    }
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "TRY",
        value: totalPrice,
        items: items,
      },
    });
  } catch (e: any) {}
};

export const gtmViewCart = (itemArray: any, categoryName: any) => {
  /**
   Kullanıcı sepetini görüntülediğinde pushlanması gereken event.
   Fakat sitede ki yapıdan kaynaklı kullanıcı çekiliş sayfasına land ettiğinde sepet otomatik olarak açıldığı için sadece anasayfa’da “Alışverişi Tamamla” butonuna tıklandığında gönderilmesi gereken event.

   **/
  try {
    var items = [];
    var totalPrice = 0;
    for (const item of itemArray) {
      items.push({
        item_id: item.item.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
        item_name: item.item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
        item_category: categoryName,
        price: item.item.draw_price,
        quantity: item.quantity,
      });
      totalPrice += Number(item.quantity) * Number(item.item.draw_price);
    }
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "TRY",
        value: totalPrice,
        items: items,
      },
    });
  } catch (e: any) {}
};

export const gtmRemoveFromCart = (itemArray: any, categoryName: any) => {
  /**
   Kullanıcı sepetinden bir ürün çıkardığın pushlanması gereken event.
   **/
  try {
    var items = [];
    var totalPrice = 0;
    for (const item of itemArray) {
      items.push({
        item_id: item.item.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
        item_name: item.item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
        item_category: categoryName,
        price: item.item.draw_price,
        quantity: item.quantity,
      });
      totalPrice += Number(item.quantity) * Number(item.item.draw_price);
    }
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "TRY",
        value: totalPrice,
        items: items,
      },
    });
  } catch (e: any) {}
};

export const gtmBeginCheckout = () => {
  /**
   Kullanıcı “Alışverişi Tamamla” butonuna tıkladığında pushlanması gereken event
   **/
  try {
    var items = [];
    var totalPrice = 0;
    for (const item of cartStore.items) {
      items.push({
        item_id: item.item.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
        item_name: item.item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
        item_category: "Çekiliş",
        price: item.item.draw_price,
        quantity: item.quantity,
      });
      totalPrice += Number(item.quantity) * Number(item.item.draw_price);
    }
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "TRY",
        value: totalPrice,
        items: items,
      },
    });
  } catch (e: any) {}
};

export const gtmViewPromotion = (slideName: any, image: any, campaignId: any, campaignName: any) => {
  /***
   Kullanıcı anasayfada bulunan bannerları görüntülediğinde tetiklenmesi gereken event.
   Carousel bannerlar için, eğer kullanıcı banner ile etkileşime geçmezse, bannerlar 1 tam tur döndüğünde bu eventin tetiklenmesi durmalıdır.
   Kullanıcı slider banner ile tekrar etkileşime geçtiğinde event tekrar tetiklenmelidir.
   Slider olmayan statik bannerlar için (anasayfanın sağ köşesinde listelenen), kullanıcı bannerı görüntülediğinde, görüntülenen banner sayısı kadar bu event tetiklenmelidir.
   ***/
  try {
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "view_promotion",
      ecommerce: {
        creative_slot: slideName, //Görüntülen promotion'ın sayfadaki konumu ve türü basılmalıdır.
        creative_name: image, // Görüntülenen promotion bannerın görselinin  URL'si
        promotion_id: campaignId,
        promotion_name: campaignName,
      },
    });
  } catch (e: any) {}
};

export const gtmSelectPromotion = (slideName: any, image: any, campaignId: any, campaignName: any) => {
  /**
   Kullanıcı banner’a tıkladığında pushlanması gereken event.
   **/
  try {
    // @ts-ignore
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // @ts-ignore
    dataLayer.push({
      event: "select_promotion",
      ecommerce: {
        creative_slot: slideName, //Görüntülen promotion'ın sayfadaki konumu ve türü basılmalıdır.
        creative_name: image, // Görüntülenen promotion bannerın görselinin  URL'si
        promotion_id: campaignId,
        promotion_name: campaignName,
      },
    });
  } catch (e: any) {}
};

export const gtmCashOut = (bankName: any, amount: any) => {
  try {
    // @ts-ignore
    dataLayer.push({
      event: "cashOut",
      bankaAdı: bankName, // Ex: Ziraat
      tutar: amount,
      balance: auth.member.balance_debit,
    });
  } catch (e: any) {}
};

export const gtmUserEvent = (eventName: any) => {
  try {
    // @ts-ignore
    dataLayer.push({
      event: eventName,
      user_id: auth.member.id,
    });
  } catch (e: any) {}
};

export const getGameItem = (gameCode: any) => {
  try {
    let games: any = Storage.get("games");
    let game = games.find((r: any) => r.code === gameCode);
    return game;
  } catch (e: any) {
    return null;
  }
};

export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
  return str;
}

export const gameNavigationMiddleware = (game: any, navigate: NavigateFunction) => {
  const isLogin = auth.authentication && auth.member && auth.member.id;
  if (!isLogin) {
    navigate(paths.login.url);
    return;
  }
  if (!game.is_active && auth.remaining_games.includes(game.id)) {
    return;
  }

  if (game.is_active) {
    navigate(
      "/sans-oyunlari/" +
        slugify(
          game.name
            .toLowerCase()
            .replaceAll("ü", "u")
            .replaceAll("ş", "s")
            .replaceAll("ç", "c")
            .replaceAll("ğ", "g")
            .replaceAll("ı", "i")
            .replaceAll("ö", "o")
        )
    );

    return;
  }

  if (isLogin && !game.is_active) {
    let api = new ApiService();
    api
      .start("post", env.accounting_api + "/misc/api/create-game-reminder/", { game_id: game.id }, true)
      .then((res: any) => {
        if (res && res.status) {
          game.reminder = true;
          getGameRemains();
          modalServiceSubject.next({
            case: "info",
            title: "Hatırlatma Oluşturuldu",
            content: `<p class="text-center">${game.name} çok yakında 4nala.com'da. Sizi haberdar edeceğiz.</p>`,
            confirm: {
              cancel: {
                label: "Tamam",
                class: "btn btn-primary w-100",
              },
            },
          });
        }
      });

    return;
  }

  // modalServiceSubject.next({
  //     class: 'success',
  //     title: 'Üye Girişi',
  //     content: `<p>Bilet sorgulama için önce oturum açmanız gerekli. Üye değilseniz hemen üye olabilir yada giriş yaparak alışverişinizi tamamlaya bilirsiniz.</p>`,
  //     confirm: {
  //         sure: {
  //             label: 'Giriş Yap',
  //             class: 'btn btn-success',
  //             action: () => {
  //                 navigate(paths.login)
  //             }
  //         },
  //         cancel: {
  //             label: 'Üye Ol',
  //             class: 'btn btn-info',
  //             action: () => {
  //                 navigate(paths.register)
  //             }
  //         }
  //     }
  // });
};
