import React, { useEffect, useRef } from "react";

import { useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { env } from "../../../constants/global.vars";
import { ApiService } from "../../../services/http.service";
import { isMobile } from "../../../App";
import eSansWhite from "../../../assets/icons/e-sans-white.svg";
import GameSelectWidget from "../widgets/game_select_widget";
import backButton from "../../../assets/feather/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import paths from "../../../paths";

function SuperWhellGame() {
  const iframeContainerRef = useRef<HTMLIFrameElement>(null);
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (auth.authentication && auth.member && auth.member.id) {
      // gtmViewItem([{code: "carkifelek", name: "Süper Çarkıfelek"}], 'Oyunlar');
      let api = new ApiService();
      api
        .start("get", env.accounting_api + "/games/ert/vendor/auth?game_code=carkifelek", null, true)
        .then((res: any) => {
          if (res && res.status && iframeContainerRef.current) {
            iframeContainerRef.current.src = res.data.url;
          }
        });
    }
  }, [auth?.authentication]);

  useEffect(() => {
    if (isMobile) {
      const bottomBar = document.getElementById("mobile-footer-menu");
      const headers = document.getElementsByTagName("header");

      // Hide elements
      if (bottomBar) {
        bottomBar.style.display = "none";
      }

      if (headers.length > 0) {
        headers[0].style.display = "none";
      }

      // Cleanup function to restore elements
      return () => {
        if (bottomBar) {
          bottomBar.style.display = "flex";
        }

        if (headers.length > 0) {
          headers[0].style.display = "flex";
        }
      };
    }
  }, []);

  return (
    <div>
      {isMobile && (
        <div className="row">
          <div className="col-3 d-flex align-items-center justify-content-center">
            <img
              src={backButton}
              height={25}
              style={{ marginRight: "0.5rem", position: "absolute", left: "10px", filter: "invert(100%)" }}
              onClick={() => navigate(paths.games.url)}
            />
          </div>

          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <div className={"d-flex justify-content-center align-items-center mt-2 me-4"}>
              <img src={eSansWhite} height={20} style={{ marginRight: "0.5rem" }} />
              <h2 className={"text-white mb-0"} style={{ fontSize: "16px" }}>
                Şans Oyunları
              </h2>
            </div>
            <GameSelectWidget game_name="Süper Çarkıfelek" />
          </div>

          <div className="col-3 game-user-balance">
            <div className="lines">
              <span>{auth.member.balance_debit}₺</span>
              <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
            </div>
          </div>
        </div>
      )}
      <iframe ref={iframeContainerRef} className={"iframeContainer"} />
    </div>
  );
}

export default SuperWhellGame;
