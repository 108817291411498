import React, {useEffect, useState} from "react";
import {ApiService} from "../../../services/http.service";
import {possiblesUrlPrefix} from "../../../constants/global.vars";
import {Storage} from "../../../services/localstorege.service";
import iconFather from "../../../assets/feather/father-at-yarisi.svg";
import _ from "lodash";
import iconClock from "../../../assets/feather/clock.svg";
import {isMobile} from "../../../App";
import jokeyIcon from "../../../assets/img/jokey.png";

export function Possibles(prop:any){

  const [possiblesData, setPossiblesData] = useState<any>(Storage.get("possiblesData"))
  const [hippodrome, setHippodrome] = useState<any>(prop.hippodrome);
  const [shownRun, setShownRun] = useState<any>(prop.hippodrome?.runs[0]);

  // console.log("hippodrome", hippodrome)

  useEffect(() => {

    if(!possiblesData){
      let api = new ApiService()
      api.start("get", possiblesUrlPrefix, null, false).then((res:any)=>{
        if(res.status){
          setPossiblesData(res.data);
          // fixme bu süre daha sonra değiştirilebilir, muhtemeller datasını çok sık çekmemek için cache atıyorum
          Storage.set("possiblesData", res.data,1*60*1000);
        }
      })
    }
  }, []);

  const colorHorses = (h1:any,h2:any)=>{
    let allHorses:any = document.getElementsByClassName(`line`)
    if(allHorses) {
      for(let e of allHorses){
        e.classList.remove('high')
        e.classList.remove('low')
      }
    }
    if(h2===null) {
      let firstHorses: any = document.getElementsByClassName(`first-horse-${h1}`)
      if (firstHorses) {
        for (let e of firstHorses) {
          // console.log(e)
          e.classList.add('high')
          // e.style.backgroundColor = '#CC000088';
        }
      }

      let secondHorses: any = document.getElementsByClassName(`second-horse-${h1}`)
      if (secondHorses) {
        for (let e of secondHorses) {
          // console.log(e)
          e.classList.add('low')

          // e.style.backgroundColor = '#CC000044';
        }
      }

    }else{

      let firstHorses: any = document.getElementsByClassName(`first-horse-${h1} second-horse-null`)
      if (firstHorses) {
        for (let e of firstHorses) {
          // console.log(e)
          // e.style.backgroundColor = '#CC000088';
          e.classList.add('high')
        }
      }

      let secondHorses: any = document.getElementsByClassName(`first-horse-${h2} second-horse-null`)
      if (secondHorses) {
        for (let e of secondHorses) {
          // console.log(e)
          // e.style.backgroundColor = '#CC000044';
          e.classList.add('low')
        }
      }

      let overHorses:any = document.getElementsByClassName(`first-horse-${h1} second-horse-${h2}`)
      if (overHorses) {
        for (let e of overHorses) {
          // e.style.backgroundColor = '#CC000088';
          e.classList.add('high')
        }
      }
    }
  }

  let eqStyles:any= {
    "1": {transform: 'scaleX(-1)', marginLeft:5, filter:'brightness(0)'},
    "2": {transform: 'scaleX(-1)', marginLeft:5, filter:'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)'},
    "3": {transform: 'scaleX(-1)', marginLeft:5 },
  }

  //
  // console.log("possiblesData",possiblesData)
  // console.log("shownRun",shownRun)

  if(!hippodrome || !possiblesData || !shownRun){
    return <></>
  }

  if(possiblesData.length===0){
    return <><div className="page-widget">
      <p className="p-4 text-center"><img src={iconClock} width={64}  alt="" /> <br /><br />
      Henüz muhtemel verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
    </div></>
  }

  // console.log("shownRun.no", shownRun.no)
  // console.log("Data", hippodrome.keyword, possiblesData.find((el:any)=>el.keyword===hippodrome.keyword))

  let possibles:any = possiblesData.find((el:any)=>el.keyword===hippodrome.keyword)?.runs?.find((p:any)=>p.no===shownRun.no)
  // console.log("possibles", possibles)

  return (<>
    <div className="page-widget">
      <svg style={{display:'none'}}>
        <defs>
          <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
        </defs>
      </svg>

      <div className="betting-run-tabs" id={"scroll-areas"}>
        <ul className="nav nav-tabs">
          {hippodrome.runs.map((run:any, keyRun:number)=>{
            return (<li key={`runItem-${keyRun}`} className={`nav-item ${shownRun === run ? 'active' : ''}`}>
              <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none"><use xlinkHref="#tabshape"></use></svg>
              <a className="nav-link" onClick={()=>{
                setShownRun(run);
                if(isMobile) {
                  // @ts-ignore
                  document.getElementById('scroll-areas').scroll({
                    top: 1,
                    left: keyRun * 33.3,
                    behavior: "smooth",
                  })
                }

              }}>{run.no} {isMobile ? shownRun === run ? '. Koşu' : ''  : '. Koşu'}</a>
              <svg viewBox="0 0 80 60" preserveAspectRatio="none"><use xlinkHref="#tabshape"></use></svg>
            </li>)
          })}
        </ul>
      </div>



      {possibles && possibles.wagers && possibles.wagers.length > 0 &&<div className={"w-100 d-inline-flex flex-nowrap possibles-table"}>
        {possibles.wagers?.map((r:any, key:number)=>{
          return (
            <div className="col p-1 runs" key={`agf-runs-r-${key}`}>
                <div className="caption"  >
                  <div className={`text-center`}>{r.wager}</div>
                </div>
                {r.possibilities.map((p:any, pkey:number)=>{
                  let minGanyan = _.orderBy(r.possibilities, [(a:any)=>Number(a.ganyan)], ['asc'])[0]
                  return (<div className={`
                    ${key === 0 ? 'line' : 'line'} 
                    ${key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'fw-bold text-success' : ''} 
                    first-horse-${p.result1} 
                    second-horse-${p.result2} 
                    p-1`} onMouseOver={() => {
                    colorHorses(p.result1, p.result2)
                  }} key={`possibilities-${pkey}`}>
                    <div className={"d-flex justify-content-start align-items-center"}>
                      {p.result1}{key !== 0 && <> - {p.result2}</>}

                      {!isMobile && key === 0 && <> - <img
                          src={`https://medya-cdn.tjk.org/formaftp/${shownRun.horseMap[pkey + 1].owner.code}.jpg`}
                          onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = jokeyIcon;
                          }} width={19} alt={shownRun.horseMap[pkey + 1].jokey.short_name}
                          className="jockey-uniform float-start "/> {p.horse_name_lower} </>}
                    </div>
                    {key === 0 && ["1", "2", "3"].includes(p.stablemate) ?
                        <img alt={"iconFather"} src={iconFather} style={eqStyles[p.stablemate]}/> : null}
                    <span className="float-end">{p.ganyan === '-' ? (isMobile ? 'K' : 'KOŞMAZ') : p.ganyan}</span>
                  </div>)
                })}
            </div>
          )
        })}
      </div>}

    </div>
  </>)
}
