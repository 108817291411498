import {useParams} from "react-router";
import React from "react";
import {SupportTicket} from "./support.ticket";
import {Link, useLocation} from "react-router-dom";
import paths from "../../paths";
import "./support.css";
import {SupportTicketList} from "./support.ticket.list";
import {SupportFAQ} from "./support.faq";
import {SupportContact} from "./support.contact";
import {isMobile} from "../../App";
import iconTickets from "../../assets/feather/clipboard.svg";
import iconHelp from "../../assets/feather/help-circle.svg";
import iconMail from "../../assets/feather/mail.svg";
import headphones from "../../assets/feather/headphones.svg"
import downIcon from "../../assets/feather/chevron-down.svg"
import upIcon from "../../assets/feather/chevron-up.svg"

const subPages: any = {
    'cozum-merkezi': <SupportTicket></SupportTicket>,
    'destek-taleplerim': <SupportTicketList></SupportTicketList>,
    'sikca-sorulan-sorular': <SupportFAQ></SupportFAQ>,
    'iletisim-bilgileri': <SupportContact></SupportContact>,
}

export function Support() {
    const param = useParams();

    if (param.page && subPages[param.page]) {
        return subPages[param.page];
    }
    return (<></>)
}


export function SupportMenu() {

    const {pathname} = useLocation();
    const [height, setHeight] = React.useState<any>(0)

    const openMenu = () => {
        var accordionHeader: any = document.getElementById('accordion-list')
        if (height === 0) {
            accordionHeader.style.maxHeight = '230px';
            setHeight(230);
        } else {
            accordionHeader.style.maxHeight = '0';
            setHeight(0);
        }
    }

    return (<div className={`page-widget side-menu liner-tabs ${isMobile ? '' : 'mt-4'}`}>
        {isMobile ?
            <h3 onClick={() => {
                openMenu()
            }} className="py-3 px-4 mb-0 d-flex justify-content-between">YARDIM {height === 0 ?
                <img className="svgcolor" src={downIcon} alt="-" /> : <img className="svgcolor" src={upIcon} alt="-"/>}</h3>
            : <h3 className="py-3 ps-4">YARDIM</h3>}

        <ul className={`${isMobile ? 'accordion-list' : ''} ${isMobile && height > 0 ? 'my-2' : ''}`} id={"accordion-list"}>
            <li className={String(pathname).includes('cozum-merkezi') ? 'active' : ''}>
                <Link to={`${paths.support.url}/cozum-merkezi/`}>
                    <img className="svgcolor" src={headphones} alt="-"/>
                    ÇÖZÜM MERKEZİ
                </Link>
            </li>
            <li className={String(pathname).includes('destek-taleplerim') ? 'active' : ''}>
                <Link to={`${paths.support.url}/destek-taleplerim/`}>
                    <img className="svgcolor" src={iconTickets} alt="-"/>
                    DESTEK TALEPLERİM
                </Link>
            </li>
            <li className={String(pathname).includes('sikca-sorulan-sorular') ? 'active' : ''}>
                <Link to={`${paths.support.url}/sikca-sorulan-sorular/`}>
                    <img className="svgcolor" src={iconHelp} alt="-"/>
                    SIKÇA SORULAN SORULAR
                </Link>
            </li>
            <li className={String(pathname).includes('iletisim-bilgileri') ? 'active' : ''}>
                <Link to={`${paths.support.url}/iletisim-bilgileri/`}>
                    <img className="svgcolor" src={iconMail} alt="-"/>
                    İLETİŞİM BİLGİLERİ
                </Link>
            </li>
        </ul>
    </div>)
}
