import React, { useEffect, useState } from "react";
import "./gift.ticket.css";

import { isMobile } from "../../App";

import { toast } from "react-toastify";
import { dateFormat, dateTimeFormat, loadingAnimation, useForceUpdate } from "../../services/misc.functions";

import { useNavigate } from "react-router";
import { modalServiceSubject } from "../../services/modal.service";
import paths from "../../paths";
import { env } from "../../constants/global.vars";
import { ApiService } from "../../services/http.service";
import { raffleDraws, raffleDrawSubject } from "../../store/raffle.store";
import { RaffleModel } from "../../models/raffle.model";
import { getRaffleDraws, openRaffleDetail } from "../../services/raffle.service";
import { DrawDetail } from "../raffles/widgets/draw.detail";

let myRaffleListPage: number = 0;
let xhrMetaData: any = {};
let OrderStatusMap: any = {
  0: {
    class: "warning",
    label: "Oynanıyor",
  },
  1: {
    class: "warning",
    label: "Beklemede",
  },
  2: {
    class: "success",
    label: "Kazanan",
  },
  3: {
    class: "danger",
    label: "Kaybetti",
  },
};

export function GiftTicket() {
  const navigate = useNavigate();
  const [referenceCode, setReferenceCode] = useState<string>("");
  const [loading, setLoading] = useState<any>(true);
  const [dataList, setDataList] = useState<any[]>([]);
  const forceUpdate = useForceUpdate();

  const showRaffleTickets = (el: any) => {
    const api = new ApiService();
    let payload: any = {
      draw_id: String(el.draw_id),
      type: "get_draw",
    };

    api.start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true).then((res: any) => {
      if (res && res.status) {
        const groupByCategory = res.data.reduce((group: any, product: any) => {
          const { create_date } = product;
          group[create_date.substring(0, 16)] = group[create_date.substring(0, 16)] ?? [];
          group[create_date.substring(0, 16)].push(product);
          return group;
        }, {});

        modalServiceSubject.next({
          title: `Biletlerim - ${el.draw.title_abbreviated}`,
          content: `<p>
              <div class="row mx-0">
                  ${Object.keys(groupByCategory)
                    .map((itm: any) => {
                      return `<div class="container-fluid">
                <div class="row group-header">
                Alım Zamanı: ${dateTimeFormat(itm)}
                </div>
                <div class="row">
                ${groupByCategory[itm]
                  .map((c: any) => {
                    return `<div class= "col-3 p-1 text-center"> <div class="border  rounded-2 ${
                      c.status === 2
                        ? "bg-success-subtle  border-success"
                        : c.status === 3
                        ? "bg-danger-subtle border-danger"
                        : "bg-secondary-subtle border-secondary"
                    }" >${c.approve_code} </div></div > `;
                  })
                  .join("")}</div>
                
                </div>`;
                    })
                    .join("")}
              </div>
            </p>`,
          confirm: {
            cancel: {
              label: "Kapat",
              class: "btn btn-secondary w-100",
            },
          },
        });
      }
    });
  };

  const handlerCheckReferanceCode = () => {
    const api = new ApiService();
    if (!referenceCode) {
      return;
    }
    api
      .start("post", env.accounting_api + "/somos/api/check-reference-coupon/", { reference_code: referenceCode }, true)
      .then((res: any) => {
        if (res.status) {
          loadData(0);
          setReferenceCode("");
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.error("API isteği başarısız oldu:", error);
      });
  };

  useEffect(() => {
    loadData(0);
  }, []);

  const loadData = async (forcePage: any = null) => {
    const api = new ApiService();
    loadingAnimation(true);
    setLoading(true);

    let pageN = forcePage !== null ? forcePage : myRaffleListPage;
    if (pageN === 0 && dataList && dataList.length > 0) {
      setDataList([]);
    }

    let payload: any = {
      gift: true,
    };

    return await api
      .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true)
      .then((res: any) => {
        loadingAnimation(false);
        setLoading(false);
        let tmp = [];
        if (res && res.status) {
          if (pageN === 0 && dataList && dataList.length > 0) {
            dataList.splice(0, dataList.length);
            tmp.push(...res.data);
          } else {
            if (dataList === null) {
              tmp.push(...res.data);
            } else {
              tmp = [...dataList];
              tmp.push(...res.data);
            }
          }
        }
        if (res && res.metadata) {
          xhrMetaData = res.metadata;
          let id_datalist_loadmore: any = document.getElementById("id_datalist_loadmore");
          if (res.metadata.count === 0 || res.metadata.count <= tmp.length) {
            id_datalist_loadmore.style.display = "none";
          } else {
            id_datalist_loadmore.style.display = "unset";
          }
        }
        setDataList(tmp);
        forceUpdate();
      });
  };

  const loadMore = (e: any) => {
    e.target.disabled = true;
    loadData().finally(() => {
      e.target.disabled = false;
    });
  };

  return (
    <>
      <div className="gift-ticket-content">
        <div className="gift-ticket-info">
          <p className="gift-ticket-title">Hediye Biletler</p>
          <p className={"text-center"}>
            Maksimum 8 haneli referans kodunuzu girdikten sonra ödülünüz hesabınıza tanımlanacaktır.
          </p>
          <div className="gift-ticket-button justify-content-center">
            <input
              maxLength={8}
              value={referenceCode}
              onChange={(e) => setReferenceCode(e.target.value)}
              className={"form-control py-2 gift-ticket-input text-center w-75"}
              placeholder="Referans kodunuzu giriniz"
            />
          </div>

          <div className="gift-ticket-button justify-content-center">
            <button className="btn btn-warning w-50" onClick={handlerCheckReferanceCode}>
              Uygula
            </button>
          </div>

          {/*<h6 className="gift-ticket-title">{responseMessage}</h6>*/}
        </div>
      </div>

      <div className={`my-raffle-order-item mx-2`}>
        {dataList.map((el: any, key: number) => {
          if (Number(el.lost_count) + Number(el.won_count) === 0) {
            el.status = 1;
          } else if (Number(el.won_count) > 0) {
            el.status = 2;
          } else if (Number(el.pending_count) > 0) {
            el.status = 0;
          } else if (Number(el.lost_count) > 0) {
            el.status = 3;
          }
          return !isMobile ? (
            <div key={`item-my-raffle-order-${key}`} className="row item py-2">
              {el.draw && (
                <>
                  <div className="col-2">
                    <img className="img-thumbnail" src={`${env.cdn_host}/media/${el.draw?.photo}`} />
                  </div>
                  <div className="col-4">
                    <h3>{el.draw?.title_abbreviated}</h3>
                    <span className="d-block">Çekiliş Tarihi: {dateFormat(el.draw?.draw_date)}</span>
                    <span className="d-block">Çekiliş No: {el.draw.no}</span>
                    <span
                      className="d-block link-primary pointer"
                      onClick={() => {
                        let draw: any = raffleDraws.find((x: RaffleModel) => x.no === el.draw.no);
                        if (draw) {
                          openRaffleDetail(draw);
                        } else {
                          el.draw.detail_photo = el.draw.detail_photo?.replace("/media/", "");
                          el.status = el.status === true ? 1 : 0;

                          raffleDrawSubject.next({
                            action: "history",
                            draw: new RaffleModel(el.draw),
                          });
                        }
                      }}
                    >
                      Çekiliş Detayları
                    </span>
                  </div>
                  <div className="col-4 pt-2">
                    <div>
                      {/*<h3>{el.name}</h3>*/}
                      <span className="d-block">Bilet ücreti: {Number(el.draw.ticket_price).toFixed(2)} ₺</span>
                      <span className="d-block">
                        Alınan bilet:
                        {el.status === 0 ? (
                          <> Satın alma devam ediyor</>
                        ) : (
                          <>
                            {" "}
                            {el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count} adet
                          </>
                        )}
                      </span>
                      <span className="d-block fw-bold">
                        Toplam Tutar:
                        {el.status === 0 ? (
                          <> Satın alma devam ediyor</>
                        ) : (
                          <>
                            {" "}
                            {(
                              Number(el.draw.ticket_price) *
                              Number(el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count)
                            ).toFixed(2)}
                            ₺
                          </>
                        )}
                      </span>
                      <span
                        className="d-block link-primary pointer"
                        onClick={() => {
                          showRaffleTickets(el);
                        }}
                      >
                        Biletlerimi Gör
                      </span>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-between align-items-center">
                    <div className={"d-flex flex-column"}>
                      {el.badge && (
                        <div className={`status mb-2 success bg-white px-1 py-2`}>
                          <img src={env.cdn_host + el.badge} className={"badge-image"} />
                          {/*<img src={logoESans} className={"badge-image"}/>*/}
                        </div>
                      )}
                      <div className={`status ${OrderStatusMap[el.status].class}`}>
                        {OrderStatusMap[el.status].label}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div key={`item-my-raffle-order-${key}`} className="row item py-2">
              {el.draw && (
                <>
                  <div className="col-4 column-center" style={{ width: "40%" }}>
                    <img className="img-thumbnail" src={`${env.cdn_host}/media/${el.draw?.photo}`} />
                    <br />
                    <h3 className="text-center">{el.draw?.title_abbreviated}</h3>
                    <span
                      className="d-block link-primary pointer text-center"
                      onClick={() => {
                        let draw: any = raffleDraws.find((x: RaffleModel) => x.no === el.draw.no);
                        if (draw) {
                          openRaffleDetail(draw);
                        } else {
                          el.draw.detail_photo = el.draw.detail_photo.replace("/media/", "");
                          el.status = el.status === true ? 1 : 0;
                          raffleDrawSubject.next({
                            action: "history",
                            draw: new RaffleModel(el.draw),
                          });
                        }
                      }}
                    >
                      Çekiliş Detayları
                    </span>
                  </div>
                  <div className="col-8" style={{ width: "60%" }}>
                    <div className="d-flex justify-content-start">
                      <div className={`w-100 status ${OrderStatusMap[el.status].class}`}>
                        <p className="m-1">{OrderStatusMap[el.status].label}</p>
                      </div>
                    </div>
                    <span className="d-block" style={{ fontSize: "14px" }}>
                      Çekiliş Tarihi:
                      <span style={{ color: "blue" }}> {dateFormat(el.draw?.draw_date)}</span>
                    </span>
                    <span className="d-block" style={{ fontSize: "14px" }}>
                      Çekiliş No:
                      <span style={{ color: "blue" }}> {el.draw.no}</span>
                    </span>
                    <span className="d-block" style={{ fontSize: "14px" }}>
                      Alınan bilet:
                      {el.status === 0 ? (
                        <span style={{ color: "blue" }}>Satın alma devam ediyor</span>
                      ) : (
                        <span style={{ color: "blue" }}> {el.played_count} adet</span>
                      )}
                    </span>
                    {el.badge && (
                      <div className={`w-100 status-badge status mb-2 success bg-white px-1 py-2`}>
                        <img src={env.cdn_host + el.badge} className={"badge-image"} />
                        {/*<img src={logoESans} className={"badge-image"}/>*/}
                      </div>
                    )}
                    <span
                      className="d-block link-primary pointer text-end"
                      onClick={() => {
                        showRaffleTickets(el);
                      }}
                    >
                      Biletlerimi Gör
                    </span>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {dataList && dataList.length === 0 && !loading && (
        <div className="page-widget mt-2 p-3">
          <div className="text-center py-5">
            Size tanımlı hediye biletiniz bulunmamaktadır. Referans kodunuzu girerek hediye biletiniz alabilir ya da
            çekilişlermizi inceleyebilirsiniz.
          </div>

          <div className={"d-flex justify-content-center"}>
            <button
              className="mx-auto btn btn-primary w-50 mt-2"
              onClick={() => {
                navigate(paths.raffles.url);
              }}
              id="btn_submit"
            >
              İncele
            </button>
          </div>
        </div>
      )}

      <div className="text-center">
        <div id="id_loaderAnimation" className="loader"></div>
        <button
          id="id_datalist_loadmore"
          onClick={loadMore}
          type="button"
          className="btn rounded-pill btn-outline-primary"
        >
          Devamını Yükle
        </button>
      </div>

      <DrawDetail></DrawDetail>
    </>
  );
}
