import "./campaign.css";
import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import _, {constant} from "lodash";
import {Link} from "react-router-dom";
import paths from "../../paths";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router";

const tabs = [
    {value: "guncel-kampanyalar", label: "Güncel Kampanyalar"},
    {value: "gecmis-kampanyalar", label: "Geçmİş Kampanyalar"},
];

export default function Campaign() {
    const api = new ApiService();
    // const [dataList, setDataList] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
    const [validCampaigns, setValidCampaigns] = useState<any>([]);
    const [notValidCampaigns, setNotValidCampaigns] = useState<any>([]);
    const navigate = useNavigate();


    const getCampaignList = () => {
        var valids: any = [];
        var notValids: any = [];
        api.start("get", env.accounting_api + "/misc/api/campaign/list/", null, true).then((res: any) => {
            if (res && res.status) {
                // setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]));
                for (const data of res.data) {
                    if (data.is_valid) {
                        valids.push(data);
                    } else {
                        notValids.push(data);
                    }
                }
            }
        }).then(() => {
            setValidCampaigns(valids);
            setNotValidCampaigns(notValids)
            console.log(valids);
            console.log(notValids);
        });
    }


    useEffect(() => {
       getCampaignList();

    }, []);

    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    const subscribeCampaign = (campaign: any) => {
        api.start("post", env.accounting_api + "/misc/api/member-campaign/subscribe/", {"campaign_id": campaign.id}, true).then((res: any) => {
            if (res && res.status) {
                getCampaignList();
                modalServiceSubject.next({
                    case: "success",
                    title: "Başarılı",
                    width: 380,
                    content: `<p>Kampanya'ya katılımınız gerçekleşmiştir.</p>`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-success",
                        },

                    },
                });

            } else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata",
                    width: 380,
                    content: `<p>${res.message}</p>`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-danger",
                        },

                    },
                });
            }
        }).then(() => {
        });
    }

    return (

        <div className="page-widget mt-4 mt-lg-0">


            <svg style={{display: "none"}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>
            <div className={"tab-scroll"}>
                {/* Tab Header */}
                <ul className="nav nav-tabs">
                    {tabs.map(({value, label}: { value: string; label: string }, index) => {
                        return (
                            <li
                                key={`home-tabs-${index}`}
                                className={`nav-item ${selectedTab.value === value ? "active" : ""}`}
                                onClick={handleSelect}
                            >
                                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use xlinkHref="#tabshape"></use>
                                </svg>
                                <a className="nav-link" id={`${index}`}>
                                    {label.toUpperCase()}
                                </a>
                                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use xlinkHref="#tabshape"></use>
                                </svg>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {/* Tab Content */}
            <div className="p-3">
                <div className="row">
                    {selectedTab.value === "guncel-kampanyalar" && validCampaigns.map((el: any, key: number) => {

                        return (
                            <div

                                className="col-12 col-lg-6 campaign-item text-decoration-none"
                                key={`campaign-${el.id}`}
                            >
                                <div className="campaign-widget">

                                    {!el.is_valid &&
                                        <div className="ribbon ribbon-old">
                                            <span>Geçmiş</span>
                                        </div>
                                    }

                                    <img src={env.cdn_host + "/media/" + el.photo} alt=""/>

                                    <div className="body">
                                        <span className="title">{el.name}</span>
                                        <span className="sub-title">{el.title_abbreviated}</span>
                                    </div>

                                    <div className={"row"}>
                                        <div
                                            className={`col-12 ${el.participation_required ? 'col-lg-6' : 'col-lg-12'} `}>
                                            <button onClick={() => {
                                                navigate(`${paths.campaigns.url}/${el.id}`)
                                            }} className="btn btn-info w-100">Kampanya Detayları
                                            </button>
                                        </div>
                                        {el.participation_required &&
                                            <div className={"col-12 col-lg-6 mt-2 mt-lg-0"}>
                                                <button className="btn btn-warning  w-100" onClick={() => {
                                                    if (!el.member_subscribed != null) {
                                                        subscribeCampaign(el)
                                                    }
                                                }}>
                                                    {el.member_subscribed != null ? "Kampanya'ya Katıldınız" : "Kampanya'ya Katıl"}
                                                </button>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        );

                    })}
                    {selectedTab.value === "gecmis-kampanyalar" && notValidCampaigns.map((el: any, key: number) => {

                        return (
                            <Link
                                to={`${paths.campaigns.url}/${el.id}`}
                                className="col-6 col-mobile-12 campaign-item text-decoration-none"
                                key={`campaign-${el.id}`}
                            >
                                <div className="campaign-widget">
                                    {!el.is_valid &&
                                        <div className="ribbon ribbon-old">
                                            <span>Geçmiş</span>
                                        </div>
                                    }
                                    <img src={env.cdn_host + "/media/" + el.photo} alt=""/>
                                    <div className="body">
                                        <span className="title">{el.name}</span>
                                        <span className="sub-title">{el.title_abbreviated}</span>
                                    </div>
                                </div>
                            </Link>
                        );

                    })}

                    {selectedTab.value === "guncel-kampanyalar" && validCampaigns.length == 0 && <>
                        <p className="text-center p-4">
                            Güncel Kampanyamız bulunmamaktadır

                        </p>

                    </>}
                    {selectedTab.value === "gecmis-kampanyalar" && notValidCampaigns.length == 0 && <>
                        <p className="text-center p-4">
                            Geçmiş Kampanyamız bulunmamaktadır

                        </p>

                    </>}
                </div>
            </div>


        </div>

    );
}
