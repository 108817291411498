import {useEffect} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {modalServiceSubject} from "../../services/modal.service";
import paths from "../../paths";
import {exceptionModal} from "../../services/misc.functions";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {wssProvider} from "../../services/websocket.service";

export function VerifyEmail(){

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hash = params.get('hash');
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(()=>{
    if(hash && pathname.includes(paths.verifyEmail.url)) {
      let api = new ApiService();
      api.start('get', env.accounting_api + '/member/email-verify/?hash=' + hash, null, true)
        .then((res: any) => {
          if (res) {
            if (res.status){
              wssProvider.checkauth();
            }
            modalServiceSubject.next({
              case: res.status ? 'success' : 'danger',
              title: res.status ? 'Teşekkürler' : 'Üzgünüz!',
              content: `<p class="text-center">${res.message}</p>`,
              confirm: {
                cancel: {
                  label: 'Kapat',
                  action: () => {
                    navigate(paths.home);
                  }
                }
              }
            })
          }
        })
        .catch((e: any) => {
          exceptionModal(e)
        })
    }
  },[hash]);

  return (<></>)
}
