import "./secret-elements.css";
import React, { useEffect, useRef, useState } from "react";
import { auth, authSubject } from "../../../store/auth.store";
import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { ApiService } from "../../../services/http.service";
import { env } from "../../../constants/global.vars";
import { isMobile } from "../../../App";
import paths from "../../../paths";
import GameSelectWidget from "../widgets/game_select_widget";
import eSansWhite from "../../../assets/icons/e-sans-white.svg";
import backButton from "../../../assets/feather/chevron-left.svg";
import { useNavigate } from "react-router-dom";

function SecretElements() {
    const navigate = useNavigate();
    const secretElementsIframeRef = useRef(null);
    const [config, setConfig] = useState(null);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe(() => {
            forceUpdate();
        });
        return () => {
            auth_subscriber.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            const bottomBar = document.getElementById("mobile-footer-menu");
            const headers = document.getElementsByTagName("header");
            const footers = document.getElementsByTagName("footer");

            // Hide elements
            if (bottomBar) {
                bottomBar.style.display = "none";
            }

            if (headers.length > 0) {
                headers[0].style.display = "none";
            }

            if (footers.length > 0) {
                footers[0].style.display = "none";
            }

            // Cleanup function to restore elements
            return () => {
                if (bottomBar) {
                    bottomBar.style.display = "flex";
                }

                if (headers.length > 0) {
                    headers[0].style.display = "flex";
                }

                if (footers.length > 0) {
                    footers[0].style.display = "flex";
                }
            };
        }
    }, []);

    useEffect(() => {
        if (auth.authentication && auth.member && auth.member.id) {
            gtmViewItem([{code: "12716", name: "Gizli Elementler"}], "Oyunlar");
            const script = document.createElement("script");
            script.src = "https://static.aleaplay.com/js/launch/bundle.js";
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                const api = new ApiService();
                api.start("get", env.accounting_api + "/games/aleaplay/api/get_member_token/", null, true).then((res: any) => {
                    if (res && res.status) {
                        let SecretElementsConfig = {
                            environmentCredential: res.data.environmentCredential,
                            signature: res.data.signature,
                            container: "#secretElementsIframeContainer",
                            casinoId: "1627",
                            casinoPlayerId: auth.member.id,
                            casinoSessionId: res.data.casinoSessionId,
                            gameId: "12716",
                            country: "TR",
                            currency: "TRY",
                            locale: "tr-TR",
                            lobbyUrl: "https://4nala.com.tr",
                            // isTest: true,
                        };
                        // @ts-ignore
                        setConfig(SecretElementsConfig);
                    }
                });
            };
        }
    }, [auth?.authentication]);

    useEffect(() => {
        if (config) {
            // @ts-ignore
            const gameLauncher = new APGameLauncher(config);
            gameLauncher.play();
        }
    }, [config]);

    return (
        <div>
            {isMobile && (
                <div className="row p-0 m-0">
                    <div className="col-3 d-flex align-items-center justify-content-center">
                        <img
                            src={backButton}
                            height={25}
                            style={{
                                marginRight: "0.5rem",
                                position: "absolute",
                                left: "10px",
                                filter: "invert(100%)",
                            }}
                            onClick={() => navigate(paths.games.url)}
                        />
                    </div>

                    <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                        <div className={"d-flex justify-content-center align-items-center mt-2 me-4"}>
                            <img src={eSansWhite} height={20} style={{ marginRight: "0.5rem" }} />
                            <h2 className={"text-white mb-0"} style={{ fontSize: "16px" }}>
                                Şans Oyunları
                            </h2>
                        </div>
                        <GameSelectWidget game_name="Gizli Elementler" />
                    </div>

                    <div className="col-3 game-user-balance">
                        <div className="lines">
                            <span>{auth.member.balance_debit}₺</span>
                            <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
                        </div>
                    </div>
                </div>
            )}
            <div id="secretElementsIframeContainer" ref={secretElementsIframeRef}></div>
        </div>
    );
}

export default SecretElements;
