import React, { useEffect, useState } from "react";
import { Subject, Subscription } from "rxjs";
import iconCheck from "../assets/icons/check-star-circle.png";
import iconAlert from "../assets/icons/alert-star-circle.png";
import iconInfo from "../assets/feather/info.svg";

export const modalServiceSubject = new Subject();

let afterLoad: boolean = false;
const ModalService = (props: any) => {
  const [showModal, setShowModal] = useState<any>(false);

  useEffect(() => {
    const modalServiceSubject_subscription: Subscription = modalServiceSubject.subscribe((obj: any) => {
      if (obj) {
        setShowModal(obj);

        if (obj.timeout && obj.timeout > 0) {
          setTimeout(() => {
            setShowModal(null);
          }, obj.timeout);

        }

      } else {
        unSetModal();
      }
    });
    return () => {
      modalServiceSubject_subscription.unsubscribe();
      afterLoad = false;
    };
  }, []);

  const unSetModal = () => {
    if (showModal && showModal.onClose) {
      showModal.onClose();
    }
    afterLoad = false;
    if(showModal.backdropCloseDisabled!==true) {
      setShowModal(false);
    }
  };

  if (showModal) {

    if (showModal.afterLoad && afterLoad === false) {
      afterLoad = true;
      setTimeout(() => {
        showModal.afterLoad();
      }, 5);
    }

    return (
      <>
        {props.children}
        <div
          className={`modal show ${showModal.case || ""} ${showModal.class || ""}`}
          style={{
            width: showModal.width ? showModal.width : "auto",
            maxWidth: showModal.width ? "calc( 100vw - 40px)" : "",
          }}
        >
          {showModal.title ? <div className="header">
          {showModal.case === 'success' ? <><img src={iconCheck} alt="" /> <div className="rev-wave"></div></> : <></>}
          {showModal.case === 'danger' ? <><img src={iconAlert} alt="" /> <div className="rev-wave"></div></> : <></>}
          {showModal.case === 'info' ? <><img src={iconInfo} alt="" /> <div className="rev-wave"></div></> : <></>}
          {showModal.case === 'warning' ? <><img src={iconAlert} alt="" /> <div className="rev-wave"></div></> : <></>}
          {!showModal.case && showModal.title}
        </div> : <></>}

          {typeof(showModal.content) !== 'object' && <div
            className="body"
            style={{ maxHeight: "calc(100vh - 150px)", height: showModal.height ? showModal.height : "auto" }}
            dangerouslySetInnerHTML={{
              __html: (showModal.case ? `<h2 class="title">${showModal.title}</h2>` : "") + showModal.content,
            }}
          ></div>}

          {typeof(showModal.content) === 'object' && <div
            className="body"
            style={{ maxHeight: "calc(100vh - 150px)", height: showModal.height ? showModal.height : "auto" }}
          >{showModal.content}</div>}


          <div className="footer p-2 text-end">
            {showModal.confirm ? (
              <>
                {showModal.confirm.sure ? (
                  <>
                    <button
                      id="id_btnModelServiceSure"
                      className={"btn " + (showModal.confirm.sure.class || "btn-primary")}
                      onClick={() => {
                        if (showModal.confirm.sure.async) {
                          showModal.confirm.sure.action().then((res: any) => {
                            if (res) {
                              unSetModal();
                            }
                          });
                        } else {
                          showModal.confirm.sure.action();
                        }
                        if (showModal.confirm.sure.autoClose !== false) {
                          unSetModal();
                        }
                      }}
                    >
                      {showModal.confirm.sure.label}
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {showModal.confirm.cancel ? (
                  <>
                    <button
                      id="id_btnModelServiceCancel"
                      className={"btn " + (showModal.confirm.cancel.class || "btn-secondary")}
                      onClick={() => {
                        if (showModal.confirm.cancel && showModal.confirm.cancel.action) {
                          showModal.confirm.cancel.action();
                          if (showModal.confirm.cancel.autoClose !== false) {
                            unSetModal();
                          }
                        } else {
                          unSetModal();
                        }
                      }}
                    >
                      {showModal.confirm.cancel && showModal.confirm.cancel.label
                        ? showModal.confirm.cancel.label
                        : "Vazgeç"}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          onClick={() => {
            unSetModal();
          }}
          className="modal-backdrop show"
        ></div>
      </>
    );
  }
  return <>{props.children}</>;
};
export default ModalService;
