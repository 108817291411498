import { env } from "../constants/global.vars";
import {authSubject, auth, loginModalSubject} from "../store/auth.store";
import { Storage } from "./localstorege.service";
import { Member } from "../models/auth.model";
import { wssProvider } from "./websocket.service";
import { modalServiceSubject } from "./modal.service";
import { exceptionModal } from "./misc.functions";
import paths from "../paths";
import { ApiService } from "./http.service";

export function restoreLogin() {
  let savedData: any = Storage.get("member");
  if (savedData) {
    auth.member = new Member(savedData);
    auth.token = Storage.get("token");
    auth.authentication = true;
  }
}

export async function getGameRemains() {
  if (auth.member && auth.token) {
    let api = new ApiService();
    await api.start('', env.accounting_api + '/misc/api/list-game-reminder/', {}, true).then((res: any) => {

      if (res && res.status) {
        auth.remaining_games = res.data.map((el: any, key: number) => {
          return el.game_id;
        });
        // console.log(auth.remaining_games)
        authSubject.next({action: 'checkAuth'})
      }

    }).catch((e: any) => {

    })
  }
}

export async function userLogin(payload: any) {
  let api = new ApiService();
  return await api
    .start("post", env.accounting_api + "/member/api/login/", payload, false)
    .then((res: any) => {
      if (res && res.status) {
        auth.member = new Member(res.data.member);
        auth.token = res.data.token;
        auth.authentication = true;
        Storage.set("token", res.data.token);
        Storage.set("member", res.data.member);
        authSubject.next({ action: "login" });
        wssProvider.user(auth.token);
      }
      return res;
    })
    .catch((e: any) => {
      return { status: false, error: e, message: e, data: null };
    });
}

export function userLogout() {

  console.log("LOGOUT")

  auth.member = null;
  auth.token = null;
  auth.authentication = false;

  Storage.del("token");
  Storage.del("member");

  authSubject.next({ action: "logout" });
  loginModalSubject.next(false);
}

export function sendValidationCode(payload: any) {
  let api = new ApiService();
  api
    .start("post", env.accounting_api + "/member/api/validation-member-sms/", payload, false)
    .then((res: any) => {
      console.log(res);
    })
    .catch((e: any) => {
      exceptionModal(e);
    });
}

export async function userValidation(payload: any, resend: any = false) {
  if (resend) {
    sendValidationCode(payload);
  }

  let mobile = payload.mobile;
  let s: any = null;
  let sureButton: any = null;
  let resendButton: any = null;
  let validationErrorMessage: any = null;
  const senderTimer = (s: any = null) => {
    let resendTimer: any = document.getElementById("id_resendTimer");
    if (resendTimer) {
      let secondLeft: number = Number(
        Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1
      );
      if (secondLeft < 0) {
        clearInterval(s);
        if (resendButton) {
          resendButton.style.visibility = "visible";
        }
      } else {
        resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
      }
    }
  };

  modalServiceSubject.next({
    title: "Cep Numaranızı Doğrulayın",
    confirm: {
      sure: {
        label: "Doğrula",
        autoClose: false,
        async: true,
        action: async () => {
          validationErrorMessage.style.display = "none";
          const inputElements: any = document.querySelectorAll("input.code-input");
          let validateCodeValue = [...inputElements].map((inp: any) => inp.value).join("");
          let api = new ApiService();
          return await api
            .start(
              "post",
              env.accounting_api + "/member/api/validation-sms/",
              {
                mobile: mobile,
                type: "register_validate",
                code: validateCodeValue,
              },
              false
            )
            .then((res: any) => {
              if (res && res.status) {
                auth.userValidation = true;
                return true;
              } else {
                if (validationErrorMessage) {
                  validationErrorMessage.style.display = "block";
                  validationErrorMessage.innerHTML = res.message;
                }
              }
            })
            .catch((e: any) => {
              if (validationErrorMessage) {
                validationErrorMessage.style.display = "block";
                validationErrorMessage.innerHTML = e;
              }
            });
        },
      },
    },
    onClose: () => {
      clearInterval(s);
    },
    afterLoad: () => {
      s = setInterval(senderTimer, 1000);
      validationErrorMessage = document.getElementById("id_validationErrorMessage");
      if (validationErrorMessage) {
        validationErrorMessage.style.display = "none";
      }
      sureButton = document.getElementById("id_btnModelServiceSure");
      resendButton = document.getElementById("id_resendCode");
      if (sureButton) {
        sureButton.disabled = true;
      }
      if (resendButton) {
        resendButton.style.visibility = "hidden";
        resendButton.addEventListener("click", (e: any) => {
          resendButton.style.visibility = "hidden";
          let resendTimer: any = document.getElementById("id_resendTimer");
          if (resendTimer) {
            if (validationErrorMessage) {
              validationErrorMessage.style.display = "none";
            }

            sendValidationCode(payload);
            resendTimer.innerHTML = "3:00";
            clearInterval(s);
            s = setInterval(senderTimer, 1000);
          }
        });
      }

      const inputElements: any = document.querySelectorAll("input.code-input");
      inputElements.forEach((ele: any, index: any) => {
        ele.addEventListener("keydown", (e: any) => {
          // if the keycode is backspace & the current field is empty
          // focus the input before the current. Then the event happens
          // which will clear the "before" input box.
          if (e.keyCode === 8 && e.target.value === "") inputElements[Math.max(0, index - 1)].focus();
        });
        ele.addEventListener("input", (e: any) => {
          // take the first character of the input
          // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
          // but I'm willing to overlook insane security code practices.
          const [first, ...rest] = e.target.value;
          e.target.value = first ?? ""; // first will be undefined when backspace was entered, so set the input to ""
          const lastInputBox = index === inputElements.length - 1;
          const didInsertContent = first !== undefined;
          if (didInsertContent && !lastInputBox) {
            // continue to input the rest of the string
            inputElements[index + 1].focus();
            // inputElements[index+1].value = rest.join('')
            inputElements[index + 1].dispatchEvent(new Event("input"));
          }

          let validateCodeValue = [...inputElements].map((inp: any) => inp.value).join("");
          if (validateCodeValue.length === 6) {
            sureButton.disabled = false;
          } else {
            sureButton.disabled = true;
          }
        });
      });
    },
    content: `
      <p>
        ${mobile} GSM numaralı telefonunuza gönderdiğimiz doğrulama kodunu giriniz.
      </p>
      <div>
      
        <div id="id_validationErrorMessage" class="text-center alert alert-danger"></div>
          
        <div class="mb-3 px-5 d-flex w-100">
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
          <div class="clearfix"></div>
        </div>
        <div id="emailHelp" class="form-text"><span id="id_resendTimer">3:00</span> sonra tekrar gönder</div>
        <button style="bottom: 8px;" class="btn position-absolute btn-warning" id="id_resendCode">Tekrar Gönder</button>
      </div>`,
  });
}

export async function sendEmailValidationLink() {
  let api = new ApiService();
  api
    .start(
      "post",
      env.accounting_api + "/member/api/validation-email/",
      {
        type: "register_send",
        email: auth.member.email,
        return_address: `${window.location.protocol}//${window.location.host}${paths.verifyEmail.url}`,
      },
      true
    )
    .then((res: any) => {
      console.log(res);
      if (res && res.status) {
        modalServiceSubject.next({
          title: res.message,
          content: `<p>Lütfen eposta adresinize gönderilen doğrulama linkini tıklayarak doğrulama işleminizi tamamlayın. 
                    Eğer bir eposta almazsanız lütfen müşteri hizmetlerinden destek talep ediniz. </p>`,
          confirm: {
            cancel: {
              label: "Tamam",
            },
          },
        });
      }else{
        modalServiceSubject.next({
          case:  'danger',
          title: 'Doğrulama Maili Gönderilemedi',
          content: `<p>${res.message}<p/>`,
          confirm: {
            cancel: {
              label: "Tamam",
            },
          },
        });
      }
    });
}
