import {SupportMenu} from "./support";
import React, {useEffect, useState} from "react";
import {env, passwordModalStyles, supportTicketStatus} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {dateTimeFormat, exceptionModal, loadingAnimation, useForceUpdate} from "../../services/misc.functions";
import {useNavigate, useParams} from "react-router";
import paths from "../../paths";
import iconFile from "../../assets/feather/file.svg";
import {modalServiceSubject} from "../../services/modal.service";
import {SupportTicketForm} from "./support.ticket";
import iconBackArrow from "../../assets/feather/arrow-left.svg";
import {isMobile} from "../../App";
import InputMask from "react-input-mask";
import iconClose from "../../assets/feather/x-circle.svg";

import Modal from "react-modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {supportTicketWsSubject} from "../../store/misc.store";


let selectedSupportTicket:any = null;
let supportTicketPage:number = 0;

export function SupportTicketList() {

  const forceUpdate = useForceUpdate();
  const [metaData, setMetaData] = useState<any>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [ticketForm, showTicketForm] = React.useState<boolean>(false);
  const [newTicketModal, setNewTicketModal] = React.useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    let supportTicketWsSubject_sub = supportTicketWsSubject.subscribe((data: any) => {
      if(dataList.length > 0) {
        let exTicket = dataList.find((el:any)=>Number(el.id)===Number(data.id))
        if(exTicket){
          for (const [key, Value] of Object.entries(exTicket)) {
            exTicket[key] = data[key];
          }
        }
      }

      setDataList(dataList);
      forceUpdate();

    });

    return () => {
      supportTicketWsSubject_sub.unsubscribe();
    }
  }, []);


  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    let acceptedFiles: any[] = [];
    for (let f of payload.file) {
      if ((f.type.includes('image') || f.type.includes('pdf')) && f.size < 1024 * 1024 * 5) {
        acceptedFiles.push(f);
      } else {
        toast.error('Lütfen 5MB dan küçük ve resim/pdf formatında dosya yükleyiniz.');
        return null;
      }
    }
    let formData: any = new FormData()
    Object.entries(payload).forEach(([key, value]) => {
          if (key !== 'file') {
            formData.append(key, value)
          }
        }
    )
    formData.append('file', payload.file[0])
    formData.append('subject', 0)
    let post = new ApiService();
    post.setHeaders({'Content-Type': 'multipart/form-data'})
    post.start('post', env.accounting_api + '/support/api/create-ticket/', formData, true).then((res: any) => {
      reset({message: '', file: null});
      setNewTicketModal(false);
      modalServiceSubject.next({
        case: 'success', title: 'Talebiniz Alındı',
        content: '<p>Destek talebiniz başarıyla oluşturuldu. En kısa sürede size dönüş yapılacaktır.</p>',
        confirm: {
          cancel: {
            class: 'btn btn-success',
            label: 'Tamam',
            action: () => {
              navigate(paths.support.url + '/destek-taleplerim/');
            }
          }
        }
      })
    }).catch((err: any) => {
      exceptionModal(err);
    })
  }



  useEffect(() => {
    loadData(supportTicketPage);
  }, [])

  const loadData = async (page: number) => {
    loadingAnimation(true);
    if (page === 0) {
      setDataList([]);
    }
    let api = new ApiService();
    return await api.start('post', env.accounting_api + '/support/api/list-ticket/', {
      metadata: {
        page:  page,
      },
    }, true)
      .then((res: any) => {
        loadingAnimation(false);

        if(res && res.metadata){
          setMetaData(res.metadata);
        }

        if (res && res.status) {
          dataList.push(...res.data);
          setDataList(dataList);
          return res.data;
        }

      });
  }


  const loadMore = (e:any) => {
    e.target.disabled = true;
    loadData(supportTicketPage + 1).finally(()=>{
      e.target.disabled = false;
    });
  }

  if(params.id){
    selectedSupportTicket = dataList.find((el:any)=>Number(el.id) === Number(params.id));
  }else{
    selectedSupportTicket = null;
  }

  if (metaData) {
    let id_datalist_loadMore: any = document.getElementById('id_datalist_loadMore');
    if (id_datalist_loadMore) {
      if (metaData.page + 1 === metaData.total_page || metaData.page == metaData.total_page) {
        id_datalist_loadMore.style.display = 'none';
      } else {
        id_datalist_loadMore.style.display = 'block';
      }
    }
  }


  return (<>
    <div className="page-widget dark p-4 support-wrapper">
      <div className="row">
        <div className={`col-lg-3 col-sm-12 ${isMobile ? '' : 'pt-3'}`}>
          <SupportMenu></SupportMenu>
        </div>
        <div className="col-lg-9 col-sm-12">


          <div className={`d-flex justify-content-between align-items-center mb-2 ${isMobile ? 'my-2' : ''}`}>
            <h1 className={`${isMobile ? 'mt-2' : ''}`}>Destek Taleplerim</h1>
            <button onClick={() => {
              navigate(paths.support.url + '/cozum-merkezi/');
              // setNewTicketModal(true);
            }} className="btn btn-sm rounded-pill float-end btn-success fw-bolder">+ Talep Oluştur
            </button>
          </div>

          <div className="page-widget">


            <div className="p-3 support-ticket-list">


              {selectedSupportTicket && <div>

                <img alt={"geri"} className={`float-start mt-2 ms-1 me-3`} src={iconBackArrow} onClick={() => {
                  window.history.back();
                }}/>

                <div>
                  <span className="fw-bold">Konu: </span> {selectedSupportTicket.subject?.title}
                  <span className="float-end">{dateTimeFormat(selectedSupportTicket.create_date)}</span>
                </div>
                <div><span className="fw-bold">Destek talep numarası: </span> {selectedSupportTicket.id}</div>

                <p className="py-4">
                  {selectedSupportTicket.message}
                </p>

                {selectedSupportTicket.Responses && selectedSupportTicket.Responses.length > 0 &&
                    <div className="ticket-responses">
                      <h3>Müşteri Destek Ekibi Cevabı</h3>

                      {selectedSupportTicket.Responses.map((response: any, key: number) => {
                        return (<div key={`ticket-response-${key}`} className="ticket-response mb-4">
                          <span className="fw-bold">Tarih: </span> {dateTimeFormat(response.create_date)}
                          <p>{response.message}</p>
                          {response.file &&
                              <a className="btn btn-sm btn-warning attach" href={env.cdn_host + response.file}
                                 target="_blank">
                                <img src={iconFile} alt={"Aç"} width={18}/> Dosya Eki
                              </a>}
                        </div>)
                      })}


                      <div className="text-center">
                        <p className="my-4">Bu içerik size yardımcı oldu mu?</p>
                        <button className="btn rounded-pill btn-success me-1" onClick={() => {
                          modalServiceSubject.next({
                            case: 'success',
                            title: 'Teşekkürler',
                            content: '<p>Geri bildirim için teşekkür ederiz. Sizlere daha iyi hizmet verebilmek için biz buradayız.</p>',
                            confirm: {
                              cancel: {
                                class: "btn btn-success",
                                label: 'Kapat',
                              }
                            }
                          })
                        }}>Evet
                        </button>
                        <button className="btn rounded-pill btn-secondary ms-1" onClick={() => {
                          showTicketForm(true);
                        }}>Hayır
                        </button>
                      </div>

                      {ticketForm && <>
                        <p className={"my-3 text-center"}>
                          Müşteri destek ekibimizi arayabilir ve aklınıza takılan soruları sorabilirsiniz.
                          <br/> <br/> <a href="tel:08502601160" className="btn rounded-pill btn-outline-success ms-2">0850
                          260 11 60</a>
                        </p>
                        <SupportTicketForm hintId={selectedSupportTicket.subject?.id}></SupportTicketForm>
                      </>}

                    </div>}

              </div>}
              {selectedSupportTicket === null && dataList.length > 0  && <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                  {dataList && dataList.map((item: any, key: number) => {
                    return (<tr className="pointer" key={`support-ticket-list-${key}`} onClick={() => {
                      navigate(paths.support.url + '/destek-taleplerim/' + item.id + '/')
                    }}>
                      <td>
                        <span className="d-block fw-bold">
                          #{item.id} - {item.subject?.title}
                        </span>
                        <p className="message">{item.message}</p>
                      </td>
                      <td className="text-end">
                        <span className="d-block date">{dateTimeFormat(item.create_date)}</span>
                        {supportTicketStatus[item.status]}
                      </td>
                    </tr>)
                  })}
                  </tbody>
                </table>

                <div className="text-center">
                  <div id="id_loaderAnimation" className="loader"></div>
                  <button id="id_datalist_loadMore" onClick={loadMore} type="button"
                          className="btn btn-sm rounded-pill btn-outline-primary">Devamını Yükle
                  </button>
                </div>
              </div>}
              {
                  selectedSupportTicket === null && dataList.length === 0  &&
                  <>
                    <p className="text-center p-4">
                      Destek Talebiniz Bulunmamaktadır.
                    </p>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
        isOpen={newTicketModal}
        onRequestClose={() => {
          setNewTicketModal(false);
          loadData(0);
        }}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            maxWidth: 'calc(100vw - 80px)',
            width: '460px',
            maxHeight: 'calc(100vh - 100px)',
            transform: 'translate(-50%, -50%)',
            borderRadius: '1rem',
          }, overlay: {
            zIndex: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(10px)',
          }
        }}
        contentLabel="">
      <div className={"modal-close"}>
        <img
            alt={"kapat"}
            src={iconClose}
            width={24}
            className={"float-right cursor-p "}
            onClick={() => setNewTicketModal(false)}
        />
      </div>
      <div className="modal-header">
        <h2 className="fw-bold">
          Sorununuzu bize bildirin
        </h2>
      </div>

      <div className="mb-4 support-ticket-form">

        <div className="py-2">
          <form onSubmit={handleSubmit(onSubmit)}>
          <textarea id={"id_support_textArea"} {...register<any>("message", {
            required: true,
            minLength: 10,
            maxLength: 600
          })} placeholder={"Mesajınızı giriniz"}></textarea>
            {errors.message &&
                <span className="text-danger">Bu alan zorunludur. En az 40, en çok 600 karakter girmelisiniz.</span>}
            <div className="my-3">
              <label htmlFor="id_supportTicketFile" className="form-label">Dosya veya Fotoğraf (max:5mb)</label>
              <input type="file" {...register<any>("file", {required: false})} className="form-control"
                     id="id_supportTicketFile"/>
            </div>
            <button className="btn w-100 btn-success">Gönder</button>
          </form>
        </div>
      </div>


    </Modal>
  </>)
}
