import React, {useEffect} from 'react';
import './App.css';
import AppRouters from './routers';
import {bulletinProvider} from "./services/bulletin.service";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalService from "./services/modal.service";
import {restoreLogin} from "./services/auth.service";
import {wssProvider} from "./services/websocket.service";
import {env} from "./constants/global.vars";
import {
    getAgreements,
    getFlatPages,
    getNews,
    getTipsters, getTodayResults,
    isOffline,
    isOnline,
    useForceUpdate
} from "./services/misc.functions";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Storage} from "./services/localstorege.service";
import {selectedColumns} from "./store/bulletin.store";


const {innerWidth: width} = window;
export let isMobile = width < 986 ? true : false;


export let wsProvider: any;

bulletinProvider.init();

function App() {

    const forceUpdate = useForceUpdate();

    if (!wsProvider) {
        wsProvider = wssProvider.connect(env.accounting_wss);
    }

    if (Storage.get(`selectedColumns`)) {

        Object.assign(selectedColumns, Storage.get(`selectedColumns`));
    }
    restoreLogin();
    getTipsters();
    getTodayResults();
    getAgreements();
    getFlatPages();
    getNews();


    useEffect(() => {
        window.addEventListener("online", isOnline, false);
        window.addEventListener("offline", isOffline, false);
        return () => {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);


    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);

    const resizeWindow = () => {
        let resolution = window.innerWidth < 986 ? true : false;
        if (resolution !== isMobile) {
            isMobile = resolution;
            forceUpdate();
        }
    }

    return (<div className="App">
            <AppRouters/>
            <ModalService/>
            <ToastContainer
                closeOnClick={true}
                pauseOnHover={false} draggable={true} theme={'light'} autoClose={5000}/>
        </div>
    );
}

export default App;
