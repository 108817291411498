export class Cart {
    item: object | any;
    itemType: string | any;
    quantity: number | any;

    constructor(obj: any, quantity: number, itemType: string) {
        this.item = obj;
        this.itemType = itemType;
        this.quantity = quantity;
    }
}
