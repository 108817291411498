import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../services/http.service";
import { env, redirectionErrorCodes } from "../../constants/global.vars";
import okIcon from "../../assets/icons/check-star-circle.png";
import _ from "lodash";
import paths from "../../paths";
import { modalServiceSubject } from "../../services/modal.service";
import { dateTimeStringFormat } from "../../services/misc.functions";

const { innerWidth: width } = window;
export let isMobile = width < 986 ? true : false;

export default function CampaignDetail() {
  const navigate = useNavigate();
  const api = new ApiService();
  const { id } = useParams();
  const [dataList, setDataList] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);

  const getCampaignList = () => {
    api
      .start("get", env.accounting_api + "/misc/api/campaign/list/", null, true)
      .then((res: any) => {
        if (res && res.status) {
          setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]));
        }
      })
      .then(() => {});
  };

  useEffect(() => {
    if (dataList.length === 0) {
      getCampaignList();
    }
    if (id) {
      api.start("get", env.accounting_api + `/misc/api/campaign/detail/${id}/`, null, true).then((res: any) => {
        if (res && res.status) {
          setSelectedCampaign(res.data);
        }
      });
    }
  }, [id]);

  const subscribeCampaign = (campaign: any) => {
    api
      .start("post", env.accounting_api + "/misc/api/member-campaign/subscribe/", { campaign_id: campaign.id }, true)
      .then((res: any) => {
        if (res && res.status) {
          getCampaignList();
          modalServiceSubject.next({
            case: "success",
            title: "Başarılı",
            width: 380,
            content: `<p>Kampanya'ya katılımınız gerçekleşmiştir.</p>`,
            confirm: {
              cancel: {
                label: "Tamam",
                class: "btn-success",
              },
            },
          });
        } else {
          modalServiceSubject.next({
            case: "danger",
            title: "Hata",
            width: 380,
            content: `<p>${res.message}</p>`,
            confirm: {
              cancel: {
                label: "Tamam",
                class: "btn-danger",
              },
            },
          });
        }
      })
      .then(() => {});
  };

  return (
    <div className="page-widget">
      {isMobile ? (
        <button
          className="btn btn-warning w-100 rounded-bottom-0 mb-3"
          onClick={() => {
            navigate("/kampanyalar");
          }}
        >
          Tüm Kampanyaları Göster
        </button>
      ) : (
        <></>
      )}
      <div className="row p-1 mx-0">
        {!isMobile && (
          <div className={`col-lg-3`}>
            <div className={"row"}>
              {dataList?.map((campaign) => {
                return (
                  <Link
                    to={`${paths.campaigns.url}/${campaign.id}`}
                    className={`col-12 col-mobile-12 campaign-item text-decoration-none ${
                      campaign.id.toString() === selectedCampaign?.id.toString() ? "active" : ""
                    }`}
                    key={`campaign-${campaign.id}`}
                  >
                    <div className="campaign-widget">
                      {!campaign?.is_valid && (
                        <div className="ribbon ribbon-old">
                          <span>Geçmiş</span>
                        </div>
                      )}
                      <img
                        className="picture mb-3 w-100"
                        src={env.cdn_host + "/media/" + campaign?.photo}
                        alt={campaign?.name}
                      />
                      <div className="card-title">
                        <h1>{campaign?.name}</h1>
                      </div>
                      <div className="card-body">
                        <div>
                          <p
                            className="info mx-3"
                            dangerouslySetInnerHTML={{
                              __html: campaign?.title_abbreviated,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        {selectedCampaign && (
          <div className="col-lg-9 position-relative p-3">
            {!selectedCampaign?.is_valid && (
              <div className="ribbon ribbon-old">
                <span>Geçmiş</span>
              </div>
            )}
            <img
              className="picture mb-3 w-100"
              src={env.cdn_host + selectedCampaign?.photo}
              alt={selectedCampaign?.name}
            />

            <div className="card-title">
              <h1>{selectedCampaign?.name}</h1>
            </div>

            <div className="card-body">
              <div className={"row"}>
                <div className={"col-12 col-lg-8"}>
                  <h2>Kampanya Detayları</h2>
                  <div>
                    <p className="info mx-3" dangerouslySetInnerHTML={{ __html: selectedCampaign?.description }}></p>

                    <h2>Katılım Şartları</h2>
                    <p
                      className="info mx-3"
                      dangerouslySetInnerHTML={{
                        __html: selectedCampaign?.participation_requirement,
                      }}
                    ></p>
                  </div>
                </div>
                {selectedCampaign.participation_required && (
                  <div className={"col-12 col-lg-4"}>
                    {!selectedCampaign.member_subscribed && (
                      <button
                        className="btn btn-lg btn-warning fw-medium w-100 mt-3"
                        onClick={() => {
                          subscribeCampaign(selectedCampaign);
                        }}
                      >
                        Kampanya'ya Katıl
                      </button>
                    )}
                    {selectedCampaign.member_subscribed && (
                      <div className={"campaign-saved"}>
                        <img src={okIcon} />
                        <h2 className={"text-center p-1"}>Kampanyaya Katılımınız Gerçekleşmiştir.</h2>
                        <p className={"text-center"}>
                          Kampanyaya {dateTimeStringFormat(selectedCampaign.member_subscribed.create_date)} tarihinde
                          katıldınız.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
