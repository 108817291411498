import "./enhancement.css";
import { useEffect, useState } from "react";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { useNavigate, useParams } from "react-router-dom";

import { isMobile } from "react-device-detect";

const tabs = [{ value: "yenilikler", label: "4NALA YENİLİKLER" }];

export default function Enhancements() {
  const api = new ApiService();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const [enchancements, setEnchancements] = useState<any>([]);
  const [selectedEnchancement, setSelectedEnchancement] = useState<any>(null);
  const navigate = useNavigate();

  const getEnchancementList = () => {
    api.start("get", env.accounting_api + "/misc/api/list-enhancement/", null, true).then((res: any) => {
      setEnchancements(res.data);
      if (id) {
        setSelectedEnchancement(res.data.find((item: any) => item.id === Number(id)));
      } else if (!isMobile) {
        setSelectedEnchancement(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getEnchancementList();
  }, []);

  const handleSelect = (e: any) => {
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
    }
  };

  return (
    <div className="page-widget mt-4 mt-lg-0">
      <svg style={{ display: "none" }}>
        <defs>
          <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
        </defs>
      </svg>
      <div className={"tab-scroll"}>
        {/* Tab Header */}
        <ul className="nav nav-tabs">
          {tabs.map(({ value, label }: { value: string; label: string }, index) => {
            return (
              <li
                key={`home-tabs-${index}`}
                className={`nav-item ${selectedTab.value === value ? "active" : ""}`}
                onClick={handleSelect}
              >
                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                  <use xlinkHref="#tabshape"></use>
                </svg>
                <a className="nav-link" id={`${index}`}>
                  {label.toUpperCase()}
                </a>
                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                  <use xlinkHref="#tabshape"></use>
                </svg>
              </li>
            );
          })}
        </ul>
      </div>
      {/* Tab Content */}
      <div className="p-3">
        <div className="row p-1 mx-0">
          {!isMobile && (
            <div className={`col-lg-3`}>
              <div className={"row"}>
                {enchancements?.map((enchancement: any) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedEnchancement(enchancement);
                        navigate(`/yenilikler/${enchancement.id}`);
                      }}
                      className={`col-12 col-mobile-12 campaign-item text-decoration-none ${
                        enchancement.id.toString() === selectedEnchancement?.id.toString() ? "active" : ""
                      }`}
                      key={`campaign-${enchancement.id}`}
                    >
                      <div className="campaign-widget">
                        <img
                          className="picture mb-3 w-100"
                          src={env.cdn_host + "/media/" + enchancement?.photo_web}
                          alt={enchancement?.title}
                        />
                        <div className="card-title">
                          <h1>{enchancement?.title}</h1>
                        </div>
                        <div className="card-body">
                          <div>
                            <p
                              className="info mx-3"
                              dangerouslySetInnerHTML={{
                                __html: enchancement?.title_abbreviated,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {isMobile && selectedEnchancement === null && (
            <div className={`col-lg-3`}>
              <div className={"row"}>
                {enchancements?.map((enchancement: any) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedEnchancement(enchancement);
                        navigate(`/yenilikler/${enchancement.id}`);
                      }}
                      className={`col-12 col-mobile-12 campaign-item text-decoration-none ${
                        enchancement.id.toString() === selectedEnchancement?.id.toString() ? "active" : ""
                      }`}
                      key={`campaign-${enchancement.id}`}
                    >
                      <div className="campaign-widget">
                        <img
                          className="picture mb-3 w-100"
                          src={env.cdn_host + "/media/" + enchancement?.photo_web}
                          alt={enchancement?.title}
                        />
                        <div className="card-title">
                          <h1>{enchancement?.title}</h1>
                        </div>
                        <div className="card-body">
                          <div>
                            <p
                              className="info mx-3"
                              dangerouslySetInnerHTML={{
                                __html: enchancement?.title_abbreviated,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {isMobile && selectedEnchancement !== null ? (
            <button
              className="btn btn-warning w-100 rounded-bottom-0 mb-3"
              onClick={() => {
                setSelectedEnchancement(null);
                navigate("/yenilikler");
              }}
            >
              Tüm Yenilikleri Göster
            </button>
          ) : (
            <></>
          )}

          {selectedEnchancement && (
            <div className="col-lg-9 position-relative">
              {/* <img
                className="picture mb-3 w-100"
                src={env.cdn_host + "/media/" + selectedEnchancement?.photo_web}
                alt={selectedEnchancement?.name}
              /> */}

              {/* <div className="card-title">
                <h1>{selectedEnchancement?.title}</h1>
              </div> */}

              <div className="card-body">
                <div>
                  <p
                    className="info mx-0"
                    dangerouslySetInnerHTML={{
                      __html: selectedEnchancement?.content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
