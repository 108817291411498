import {news} from "../../constants/global.vars";
import React from "react";

export function Announcements() {


    return <div className={"page-widget mt-4 mt-lg-0 p-3"}>
        <h1>Haberler</h1>
        <div className="p-3">
            <div className="row">
                {news.map((el: any, key: number) => {
                    return (
                        <div className="campaign-widget col-12 col-lg-6">
                            <div className="body p-1">
                                <span className="title mb-2">{el.title}</span>
                                <span className="sub-title text-black">{el.content}</span>
                            </div>
                        </div>
                    );

                })}


                {news.length === 0 && <>
                    <p className="text-center p-4">
                        Güncel haber bulunmamaktadır
                    </p>

                </>}

            </div>
        </div>
    </div>
}
