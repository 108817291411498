import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import { drawFilters, raffleDraws } from "../../store/raffle.store";
import { getRaffleDraws } from "../../services/raffle.service";
import Ticket from "./widgets/ticket";
import { RaffleModel } from "../../models/raffle.model";
import _ from "lodash";
import { DrawFilter } from "./widgets/draw_filter";
import {isMobile} from "../../App";

export default function Draw({}) {
  const { pathname } = useLocation();
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    if (raffleDraws?.length === 0) {
      loadingAnimation(true);
      setLoading(true);
      getRaffleDraws().then(() => {
        loadingAnimation(false);
        setLoading(false);
        forceUpdate();
      });
    }

    return () => {};
  }, [pathname]);

  const loadMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    getRaffleDraws().finally(() => {
      e.currentTarget.disabled = false;
      forceUpdate();
    });
  };

  const setFilters = () => {
    forceUpdate();
  };

  let shownRaffleDraws: RaffleModel[] = raffleDraws.filter((item: RaffleModel) => {
    let validDraw: any = item;
    if (drawFilters.categories.length > 0 && !drawFilters.categories.includes(item.draw_categories_id)) {
      validDraw = null;
    }
    if (validDraw && drawFilters.isPopular && item.is_popular === false) {
      validDraw = null;
    }

    if (validDraw && drawFilters.isNew && !item.is_new) {
      validDraw = null;
    }

    if (validDraw && drawFilters.runningOut === true && item.remaining_tickets === 0) {
      validDraw = null;
    }

    if (validDraw) {
      return validDraw;
    }
  });

  if (drawFilters.ordering[0] == "sort") {
    var runningOuts: RaffleModel[] = [];
    var cars = shownRaffleDraws.filter((el: RaffleModel) => el.draw_categories_sort === 1 && el.remaining_tickets > 0);
    var motorbike = shownRaffleDraws.filter(
      (el: RaffleModel) => el.draw_categories_sort === 2 && el.remaining_tickets > 0
    );
    var others = shownRaffleDraws.filter(
      (el: RaffleModel) => el.draw_categories_sort != 1 && el.draw_categories_sort != 2 && el.remaining_tickets > 0
    );
    // console.log(drawFilters.runningOut);
    if (!drawFilters.runningOut) {
      runningOuts = shownRaffleDraws.filter((el: RaffleModel) => el.remaining_tickets === 0);
    }

    others = _.orderBy(others, "ticket_price", "desc");
    shownRaffleDraws = [...cars, ...motorbike, ...others];
    shownRaffleDraws = _.orderBy(shownRaffleDraws, "sort", "desc");
    shownRaffleDraws = [...shownRaffleDraws, ...runningOuts];
  } else {
    var runningOuts: RaffleModel[] = [];
    if (!drawFilters.runningOut) {
      runningOuts = shownRaffleDraws.filter((el: RaffleModel) => el.remaining_tickets === 0);
      runningOuts = _.orderBy(runningOuts, drawFilters.ordering[0], drawFilters.ordering[1]);
    }
    var items = shownRaffleDraws.filter((el: RaffleModel) => parseInt(el.remaining_tickets) > 0);
    items = _.orderBy(items, drawFilters.ordering[0], drawFilters.ordering[1]);
    shownRaffleDraws = [...items, ...runningOuts];
  }

  return (
    <>
      <div className={`pt-0 ${isMobile ? 'p-0' :'p-3' }`}>
        <DrawFilter setFilters={setFilters} shownRaffleDraws={shownRaffleDraws}></DrawFilter>
        <div className={"row mt-3"}>
          {shownRaffleDraws && shownRaffleDraws.length === 0 && !loading && (
            <>
              <div className="text-center py-5">Girdiğiniz kriterlerde kayıt bulunamadı.</div>
            </>
          )}
          {shownRaffleDraws.map((item: RaffleModel) => {
            return (
              <div className="col-6 col-lg-4 col-md-4 col-xl-3 px-1" key={`raffle-list-item-${item.id}`}>
                <Ticket item={item}></Ticket>
              </div>
            );
          })}

          <div className="text-center">
            <div id="id_loaderAnimation" className="loader"></div>
            <button
              id="id_datalist_loadmore"
              onClick={loadMore}
              type="button"
              className="btn rounded-pill btn-outline-primary"
            >
              Devamını Yükle
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
