import "./member.css";
import {auth, authSubject} from "../../store/auth.store";

import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {wssProvider} from "../../services/websocket.service";

import {useEffect} from "react";

import {useForceUpdate} from "../../services/misc.functions";

export function MemberNotificationInfo() {
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            auth_subscriber.unsubscribe();
        }
    }, [])


    const updateNotificationSettings = (payload: any, obj: any) => {
        // for (const [k, v] of Object.entries(payload)) {
        //   auth.member[k] = v;
        // }
        // forceUpdate();
        obj.target.disabled = true;
        const api = new ApiService();
        api
            .start(
                "post",
                env.accounting_api + "/member/api/update-account-info/",
                payload,
                true
            )
            .then((res: any) => {
                wssProvider.checkauth();
                forceUpdate();
            })
            .finally(() => {
                obj.target.disabled = false;
            });
    };


    return (<>
        <h1>Bildirim Tercihlerim</h1>
        <div className="page-widget">
        <form className="member-form">
            <div className="row px-2">
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="id_switch_mail_notification"
                    >
                        <h4>E-Posta</h4>
                        <p className="white-space-normal">
                            destek@4nala.com adresinden gelmeyen e-postalara itibar
                            etmeyiniz.
                        </p>

                    </label>
                    <input
                        checked={auth.member.mail_notification}
                        className="form-check-input float-end"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {mail_notification: !auth.member.mail_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="id_switch_mail_notification"
                    />
                </div>
            </div>
            <div className={"row px-2 mt-2"}>
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="id_switch_sms_notification"
                    >
                        <h3>SMS</h3>
                        <p className="white-space-normal">
                            Joker Şans ile gelmeyen SMS'lere itibar etmeyiniz!
                        </p>

                    </label>
                    <input
                        checked={auth.member.sms_notification}
                        className="form-check-input float-end"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {sms_notification: !auth.member.sms_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="id_switch_sms_notification"
                    />
                </div>
            </div>
            <div className={"row px-2 mt-2"}>
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="is_switch_call_notification"
                    >
                        <h3>Müşteri Hizmetleri</h3>
                        <p className="white-space-normal">
                            0850 441 64 64 telefon numarasından gelmeyen aramalara itibar
                            etmeyiniz.
                        </p>

                    </label>
                    <input
                        checked={auth.member.call_notification}
                        className="form-check-input float-end mt-3"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {call_notification: !auth.member.call_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="is_switch_call_notification"
                    />
                </div>
            </div>
        </form>
        </div>
      </>
    );
}
