import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {auth, authSubject} from "../../store/auth.store";
import {

    dateTimeFormat, getDateTimeHumanize, loadingAnimation,
    useForceUpdate
} from "../../services/misc.functions";
import {useNavigate, useParams} from "react-router";
import {notifications} from "../../store/misc.store";
import iconTrash from "../../assets/feather/trash-2.svg";
import "./notifications.css";
import {modalServiceSubject} from "../../services/modal.service";
import {isMobile} from "../../App";

import {wssProvider} from "../../services/websocket.service";

let loading: any = false;

export function Notifications() {

    let params = useParams();
    const forceUpdate = useForceUpdate();
    const [page, setPage] = useState<number>(0);
    const [showNotification, setShowNotification] = useState<any>(null);

    //fixme pagination eklenmeli
    useEffect(() => {
        setPage(0);
        loadData(0);
    }, [params?.id])

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        })
        notifications.splice(0, notifications.length);
        return () => {
            auth_subscriber.unsubscribe();
        }
    }, [])


    const loadData = async (forcePage: any = null) => {
        loadingAnimation(true);
        loading = true;
        console.log(forcePage != null ? forcePage : page);
        const api = new ApiService();
        api.start('post', env.accounting_api + '/misc/api/list-member-notification/', {
            metadata: {
                offset: forcePage != null ? forcePage : page,
                limit: 20
            }, type: '1'
        }, true)
          .then((res: any) => {
              loadingAnimation(false);
              // notifications.splice(0, notifications.length);
              if (res.data){
                  notifications.push(...res.data);
                  forceUpdate();
                  if (res && res.metadata) {
                      let id_datalist_loadMore: any = document.getElementById('id_datalist_loadMore');
                      if (res.metadata.count === 0 || (res.metadata.count <= notifications.length)) {
                          id_datalist_loadMore.style.display = 'none';
                      } else {
                          id_datalist_loadMore.style.display = 'unset';
                      }
                  }
              }

          })

    }

    const loadMore = (e: any) => {
        e.target.disabled = true;
        let nPage = page + 1;

        setPage(nPage);
        loadData(nPage).finally(() => {
            e.target.disabled = false;
        });
    }

    const deleteNotification = (id: number) => {

        let index = notifications.findIndex((el: any) => el.id === id);
        notifications.splice(index, 1);


        if (Number(params.notification_id) === id) {
            setShowNotification(null);
            wssProvider.checkauth();
            forceUpdate();
        }

        const api = new ApiService();
        api.start('post', env.accounting_api + '/misc/api/delete-notification/', {notification_id: id}, true)
          .then((res: any) => {
              forceUpdate();
          })
    }

    const readAll = () => {
        const api = new ApiService();
        api.start('post', env.accounting_api + '/misc/api/update-notification/', {}, true)
          .then((res: any) => {
              for (const notification of notifications) {
                  notification.is_seen = true;
              }
              wssProvider.checkauth();
              forceUpdate();
          })
    }


    if (showNotification) {
        if (showNotification && showNotification.is_seen === false) {
            showNotification.is_seen = true;
            const api = new ApiService();
            api.start('post', env.accounting_api + '/misc/api/update-notification/', {notification_id: showNotification.id}, true)
              .then((res: any) => {
                showNotification.is_seen = true;
                // auth.member.unread_notification_count--;
                  wssProvider.checkauth();
                // authSubject.next(true);
              })
        }
    }

    return (<>
          <div className={`${isMobile ? '' : 'page-widget dark p-4'}`}>
              <div className="row">

                  {showNotification && <div className="col-lg-3 pt-3">
                      <div className="page-widget side-menu mt-4">
                          <div className="bg-warning bg-dashed mb-0">
                              <h2 className="p-2 py-3 mb-0 fs-5">
                                  Bildirimlerim
                                  <p className="mb-0 py-3  fw-normal fs-7"><span
                                    className="fw-bold">{auth?.member?.unread_notification_count ?? 0}</span> okunmamış
                                      mesajınız bulunmakta.
                                      Toplam <span className="fw-bold">{notifications.length}</span> adet bildiriminiz
                                      var. </p>
                              </h2>
                          </div>
                          <h3 className="py-2 ps-2 fw-normal fs-7">
                              <button className="btn btn-primary w-100 rounded-3 mt-2 btn-sm" onClick={() => {
                                  readAll()
                              }}>Tümünü Okundu Olarak İşaretle
                              </button>

                          </h3>
                          <ul className="">
                              {notifications.map((el: any, key: number) => {
                                  return (<li className={ showNotification===el ? "active":""}><a onClick={() => {
                                      setShowNotification(el);
                                  }}>{el.title} <i
                                    style={{
                                        display: 'contents',
                                        fontSize: 12,
                                        color: 'var(--bs-gray-500)'
                                    }}>{dateTimeFormat(el.create_date)}</i></a></li>)
                              })}
                          </ul>
                      </div>
                  </div>}

                  <div
                    className={showNotification ? "col-lg-9 p-2 p-lg-4 px-lg-3 py-lg-0" : "col-lg-12 p-2 p-lg-4 px-lg-3 py-lg-0"}>
                      <h1 className={`${isMobile ? 'text-light' :''}`}>Bildirimlerim</h1>
                      <div className="page-widget p-2 p-md-4 p-lg-4">

                          {!showNotification && <div className={"custom-row"}>
                              <p>{auth?.member?.unread_notification_count ?? 0} okunmamış mesajınız bulunmakta.
                                  Toplam {notifications.length} adet bildiriminiz var. </p>
                              <button className="btn btn-primary float-end rounded-3 mt-n2 btn-sm" onClick={() => {
                                  readAll()
                              }}>Tümünü Oku
                              </button>

                          </div>}

                          <div className={"row"}>
                              {!showNotification && notifications && notifications.length > 0 &&
                                <div>
                                    <table
                                      className={"table notifications table-borderless table-primary table-striped"}>
                                        <thead>
                                        <tr>
                                            <th style={{width: 120}}>Tarih</th>
                                            <th>Mesaj</th>
                                            <th style={{width: 40}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {notifications.map((el: any, key: number) => {
                                            return <tr key={key}
                                                       className={`${el.is_seen === false ? 'unreaded' : ''} pointer`}>
                                                <td onClick={() => {
                                                    if (isMobile) {
                                                        modalServiceSubject.next({
                                                            case: 'info',
                                                            width: '400px',
                                                            title: el.title,
                                                            content: `<p class="text-center">${el.content}</p>`,
                                                            confirm: {
                                                                sure: {
                                                                    label: 'Sil',
                                                                    class: 'btn-secondary w-100',
                                                                    action: () => {
                                                                        deleteNotification(el.id);
                                                                    }
                                                                },
                                                                cancel: {
                                                                    label: 'Kapat',
                                                                    class: 'btn-secondary w-100',
                                                                }
                                                            }
                                                        })
                                                    } else {
                                                        setShowNotification(el);
                                                    }

                                                }}>{getDateTimeHumanize(el.create_date)}</td>
                                                <td onClick={() => {
                                                    if (isMobile) {
                                                        modalServiceSubject.next({
                                                            case: 'info',
                                                            width: '400px',
                                                            title: el.title,
                                                            content: `<p class="text-center">${el.content}</p>`,
                                                            confirm: {
                                                                sure: {
                                                                    label: 'Sil',
                                                                    class: 'btn-secondary w-100',
                                                                    action: () => {
                                                                        deleteNotification(el.id);
                                                                    }
                                                                },
                                                                cancel: {
                                                                    label: 'Kapat',
                                                                    class: 'btn-secondary w-100',
                                                                }
                                                            }
                                                        })
                                                    } else {
                                                        setShowNotification(el);
                                                    }
                                                }}>
                                                    {el.title}
                                                    <p className="m-0">
                                                        {el.content}
                                                    </p>
                                                </td>
                                                <td className="text-end">
                                                    <img alt={"sil"} src={iconTrash} className="pointer" width={18}
                                                         onClick={() => {
                                                             deleteNotification(el.id);
                                                         }}/>
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>}

                              {showNotification && <div className="col-lg-12">
                                  <div className={"d-flex align-items-center"}>
                                      {/*<button className="btn btn-primary btn-icon float-start py-1 px-1 me-2"
                                              onClick={() => {
                                              setShowNotification(null);
                                              }}><img src={iconLeft} className="invert-color me-1"/></button>*/}
                                      <h2 className="p-0 mb-3">
                                          <i
                                              style={{
                                                  fontSize: 12,
                                                  color: 'var(--bs-gray-500)'
                                              }}>{dateTimeFormat(showNotification.create_date)}</i><br/>
                                          {showNotification.title}
                                      </h2>
                                  </div>

                                  <p dangerouslySetInnerHTML={{__html: showNotification.content.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></p>

                                  <button onClick={() => {
                                      deleteNotification(showNotification.id);
                                  }} className="btn  btn-danger mt-3">Sil
                                  </button>
                              </div>}
                          </div>
                      </div>
                      <div className="text-center">
                          <div id="id_loaderAnimation" className="loader"></div>
                          <button id="id_datalist_loadMore" onClick={loadMore} type="button"
                                  className="btn btn-sm rounded-pill btn-outline-primary">Devamını Yükle
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
}
