import React, { useEffect, useState } from "react";
import { homeHippodrome, homeHippodromeSubject } from "../../store/misc.store";
import { useForceUpdate } from "../../services/misc.functions";

export function NotRunningHorses() {
  const [horses, setHorses] = useState<any>([]);
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
      forceUpdate();
      getNotRunningHorses();
    });
    return () => {
      homeHippodromeSubject_subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    getNotRunningHorses();
  }, []);

  const getNotRunningHorses = () => {
    var notRunningHorses = [];
    if (homeHippodrome.runs) {
      for (const run of homeHippodrome.runs) {
        for (const horse of run.horses) {
          if (!horse.run_status) {
            horse.run_no = run.no;
            notRunningHorses.push(horse);
          }
        }
      }
    }

    setHorses(notRunningHorses);
  };

  return (
    <>
      {/*<div className={"home-header"}>*/}
      {/*    KOŞMAYAN ATLAR*/}
      {/*</div>*/}
      {horses.length > 0 && (
        <table className={"w-100 table table-primary table-borderless table-striped"}>
          <thead>
            <tr>
              <th className={"text-center"} style={{ width: 75 }}>
                Koşu
              </th>

              <th>At</th>
            </tr>
          </thead>
          <tbody>
            {horses.map((horse: any, index: any) => {
              console.log(horse);
              return (
                <tr key={"not-running-horse" + index}>
                  <td className={"text-center"}>{horse.run_no}</td>
                  <td>
                    ({horse.no}) {horse.name}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {horses.length === 0 && <p className="text-center p-4">Bu hipodromda koşmayan at yoktur.</p>}
    </>
  );
}
