import "./raffle.slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import { useForceUpdate } from "../../../services/misc.functions";
import { isMobile } from "react-device-detect";
import cekilis from "../../../assets/icons/cekilis.svg";
import oyunlar from "../../../assets/icons/oyunlar.svg";
import gift from "../../../assets/img/home_icon_products.png";
import paths from "../../../paths";

export function RaffleSlider() {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // fixme ana sayfa hariç görünmemeli
  useEffect(() => {
    forceUpdate();
  }, [pathname]);

  return (
    <div className="games-widgets d-flex flex-row m-0 p-2 gap-1 rounded-3 justify-content-between">
      <div
        className={`w-100 item d-flex flex-row align-items-center gap-2 justify-content-center cursor-pointer ${
          pathname === paths.games.url ? "active" : ""
        }`}
        onClick={() => {
          navigate(paths.games.url);
        }}
      >
        <img alt={"program"} src={oyunlar} width={22} />
        <span className={"nowrap-ellipsis me-1"}>DİJİTAL OYUNLAR </span>
      </div>

      <div
        className={`w-100 item d-flex flex-row align-items-center gap-2 justify-content-center cursor-pointer ${
          pathname === paths.raffles.url ? "active" : ""
        }`}
        onClick={() => {
          navigate(paths.raffles.url);
        }}
      >
        <img alt={"program"} src={cekilis} width={22} />
        <span className={"nowrap-ellipsis"}>EŞYA PİYANGOSU</span>
      </div>

      <div
        className={`w-25 item d-flex flex-row align-items-center gap-2 justify-content-center cursor-pointer`}
        style={{
          backgroundColor: pathname === paths.products.url ? "#d0d3f5" : "",
          border: pathname === paths.products.url ? "" : "1px solid #1e3d98",
        }}
        onClick={() => {
          navigate(paths.products.url);
        }}
      >
        <img alt={"program"} src={gift} width={22} />
        <span className={"nowrap-ellipsis"}>ÖDÜLLER</span>
      </div>
    </div>
  );
}
