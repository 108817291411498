import DatePicker, {registerLocale} from "react-datepicker";
import React, {useEffect, useState} from "react";
import {tr} from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import {env, resultsUrlPrefix} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {courseColors, DailyRaces, racesActionSubject} from "../../store/bulletin.store";
import iconInfo from "../../assets/feather/info.svg";
import iconClose from "../../assets/feather/x-circle.svg";
import iconClock from "../../assets/feather/clock.svg";

import {getPrettyFormatJewelry, getPrettyFormatLastSix} from "../../services/bulletin.service";
import Modal from "react-modal";
import {CurrencyFormat, ServerDate, useForceUpdate} from "../../services/misc.functions";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {GetStablemateIcon} from "../betting/components/betting-datatable";
import {isMobile} from "../../App";

registerLocale("tr", tr);

export function Results() {
    const params = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [resultsData, setResultsData] = useState<any>([]);

    const [hippodrome, setHippodrome] = useState<any>(null);
    const [shownRun, setShownRun] = useState<any>(null);
    const [showRunInfo, setShowRunInfo] = useState<any>(false);
    const [isFirstCall, setIsFirstCall] = useState<any>(true);
    const [showTrainingVideo, setShowTrainingVideo] = useState<any>(false);
    const [mobileRunModal, setMobileRunModal] = useState<any>(false);
    const [type, setType] = useState<any>("");

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === "update") {
                forceUpdate();
            }
        });
        return () => {
            racesActionSubject_subscription.unsubscribe();
        };
    });


    const getResultData = () => {
        let api = new ApiService();
        var date = `${String(startDate.getDate()).padStart(2, "0")}${String(startDate.getMonth() + 1).padStart(2, "0")}${startDate.getFullYear()}`;
        if (params.date && isFirstCall) {
            date = params.date;
            var formatDate = `${params.date.slice(4)}-${params.date.slice(2, 4)}-${params.date.slice(0, 2)}T00:00:00`
            setStartDate(new Date(formatDate));
            setIsFirstCall(false);
        }
        api
            .start("get",
                `${resultsUrlPrefix}/${date}/`,
                null,
                true
            )
            .then((result: any) => {
                if (result.status) {
                    var orderedResponseData = _.orderBy(result.data, ['opening'], ['asc']);
                    let hip = null;
                    if (params.hippodrome) {

                        hip = orderedResponseData.find((h: any) => h.keyword.toLowerCase() === params.hippodrome);
                        if (hip) {
                            setHippodrome(hip);
                        } else {
                            hip = orderedResponseData[0];
                            setHippodrome(orderedResponseData[0]);
                        }
                    } else {
                        hip = orderedResponseData[0];
                        setHippodrome(orderedResponseData[0]);
                    }

                    setResultsData(orderedResponseData);

                    if (orderedResponseData && orderedResponseData.length > 0 && orderedResponseData[0].runs) {
                        if (params.run && hip) {
                            let rn = hip.runs.find((r: any) => Number(r.no) === Number(params.run));
                            if (rn) {
                                setShownRun(rn);
                            } else {
                                setShownRun(hip.runs[0]);
                            }
                        } else {
                            setShownRun(orderedResponseData[0].runs[0]);
                        }
                    } else {
                        setShownRun(null);
                    }
                }
            });
    }

    useEffect(() => {
        getResultData();
    }, [startDate]);

    const changeHippodrome = (hippodrome: any) => {
        setHippodrome(hippodrome);
        if (hippodrome.runs) {
            setShownRun(hippodrome.runs[0]);
        } else {
            setShownRun(null);
        }
    };

    if (hippodrome && hippodrome.runs.length === 0 && DailyRaces.length > 0) {
        let dailyRace = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword);
        if (dailyRace) {
            hippodrome.runs = dailyRace.runs;
            hippodrome.continuous = true;
            setShownRun(hippodrome.runs[0]);
        }
    }

    let today = ServerDate();
    if (
        hippodrome &&
        hippodrome._date === `${today.getDate()}${String(today.getMonth() + 1).padStart(2, "0")}${today.getFullYear()}` &&
        DailyRaces.length > 0
    ) {
        let dailyRace: any = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword);
        if (dailyRace && hippodrome.runs.length !== dailyRace.runs.length) {
            let finishedRuns = hippodrome.runs.map((r: any) => Number(r.no));
            let continuousRuns = dailyRace.runs.filter((r: any) => {
                if (!finishedRuns.includes(Number(r.no))) {
                    r.continuous = true;
                    return r;
                }
            });
            hippodrome.runs = _.orderBy(hippodrome.runs.concat(continuousRuns), [(e: any) => Number(e.no)]);
        }
    }

    return (
        <>
            <div className="bettingtype-options p-2 p-sm-3 mt-2 mt-md-0">
                <div className="row">
                    <div className="col-6 col-md-4 pe-1 pe-md-2">
                        <label>TARİH</label>
                        <DatePicker
                            className={"date-input"}
                            dateFormat={"dd/MM/yyyy"}
                            selected={startDate}
                            locale={"tr"}
                            minDate={new Date("29-10-2018")}
                            maxDate={new Date()}
                            onChange={(date: any) => setStartDate(date)}
                        />
                    </div>
                    <div className={`col-6 col-md-4 ps-1 ps-md-2 mt-0`}>
                        <label>HİPODROM</label>
                        {hippodrome ? (
                            <select
                                value={hippodrome.keyword}
                                onChange={(e: any) => {
                                    changeHippodrome(resultsData.find((data: any) => data.keyword === e.target.value));
                                }}
                            >
                                {resultsData.map((data: any, key: any) => {
                                    return (
                                        <option value={data.keyword} key={`available-hippodrome-${key}`}>
                                            {data.location}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : null}
                    </div>
                    <div className={`col-lg-4 mt-2 mt-sm-0 hidden-sm`}>
                        <label>HAVA DURUMU</label>
                        {hippodrome && hippodrome.weather && (
                            <p className="input mb-0 d-flex align-items-center" style={{lineHeight: "14px"}}>
                                {hippodrome.weather.forecast}
                                {hippodrome.weather.forecast && <br/>}
                                Nem: {hippodrome.weather.humidity}% - Sıcaklık: {hippodrome.weather.temperature}°C
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="page-widget">
                        <svg style={{display: "none"}}>
                            <defs>
                                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                            </defs>
                        </svg>
                        <div className={"betting-run-tabs"} id={"result-tabs"}>
                            <ul className="nav nav-tabs">
                                {hippodrome &&
                                    hippodrome.runs &&
                                    hippodrome.runs.map((run: any, keyRun: number) => {
                                        let columnNo = keyRun + 1;
                                        return (
                                            <li key={`runItem-${keyRun}`}
                                                className={`nav-item ${shownRun === run ? "active" : ""}`}>
                                                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                                    <use xlinkHref="#tabshape"></use>
                                                </svg>
                                                <a
                                                    className="nav-link d-flex align-items-center nowrap-ellipsis"
                                                    onClick={() => {
                                                        setShownRun(run);
                                                        forceUpdate();
                                                        if (isMobile) {
                                                            // @ts-ignore
                                                            document.getElementById("result-tabs").scroll({
                                                                top: 1,
                                                                left: keyRun * 33.3,
                                                                behavior: "smooth",
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {run.no} {shownRun === run ? ".Koşu" : isMobile ? "" : ".Koşu"}
                                                    {run.continuous === true && (
                                                        <img
                                                            alt={"clock"}
                                                            src={iconClock}
                                                            width={14}
                                                            height={14}
                                                            className={`ms-1 ${shownRun === run ? "" : "invert-color"}`}
                                                        />
                                                    )}
                                                </a>
                                                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                                    <use xlinkHref="#tabshape"></use>
                                                </svg>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>

                        <div className="p-2 p-md-3">
                            {!isMobile && shownRun && (
                                <div className="betting-run-info text-light"
                                     style={{backgroundColor: courseColors[shownRun.course]}}>
                                    {hippodrome && hippodrome.continuous !== true && (
                                        <span className="d-inline-flex float-end">
                      {/*<a href={shownRun.photo_finish} target="_blank"*/}
                                            {/*   className="btn btn-sm rounded-4 btn-photo mt-1 me-2">FOTOFİNİŞ</a>*/}
                                            {/*<button className="btn btn-sm rounded-4 btn-photo mt-1 me-2" disabled={!shownRun.video}*/}
                                            {/*        onClick={() => {*/}
                                            {/*          setShowTrainingVideo(shownRun)*/}
                                            {/*        }}>VİDEO</button>*/}

                                            <img
                                                src={iconInfo}
                                                alt="i"
                                                onClick={() => setShowRunInfo(true)}
                                                className={"invert-color ms-2 mt-2"}
                                                width={24}
                                                height={24}
                                            />
                    </span>
                                    )}

                                    <span>{shownRun.no}.Koşu</span>
                                    <span>{shownRun.time}</span>
                                    <span>{shownRun.course}</span>
                                    <span>{shownRun.distance}m</span>
                                    <span>{shownRun.group}</span>
                                    {shownRun.type_detail ? (
                                        <>
                                            <span>{shownRun.type_detail}</span>
                                        </>
                                    ) : null}

                                    {shownRun.gender ? (
                                        <>
                                            <span>{shownRun.gender}</span>
                                        </>
                                    ) : null}
                                    {shownRun.gender_detail ? (
                                        <>
                                            <span>{shownRun.gender_detail}</span>
                                        </>
                                    ) : null}

                                    {hippodrome && hippodrome.continuous !== true ? (
                                        <>
                                            <br/>
                                            {shownRun.wagers}
                                        </>
                                    ) : (
                                        <>
                                            <br/>
                                            {[...Array.from(new Set(shownRun.bets.map((e: any) => e.name)))].join(", ")}
                                        </>
                                    )}
                                </div>
                            )}

                            {isMobile && shownRun && (
                                <div style={{lineHeight: "14px", paddingLeft: 5, paddingTop: 5, paddingBottom: 5}}>
                                    {hippodrome && hippodrome.continuous !== true && (
                                        <span className="d-inline-flex float-end">
                      {/*<a href={shownRun.photo_finish} target="_blank"*/}
                                            {/*   className="btn btn-sm rounded-4 btn-photo mt-1 me-2">FOTOFİNİŞ</a>*/}
                                            {/*<button className="btn btn-sm rounded-4 btn-photo mt-1 me-2" disabled={!shownRun.video}*/}
                                            {/*        onClick={() => {*/}
                                            {/*          setShowTrainingVideo(shownRun)*/}
                                            {/*        }}>VİDEO</button>*/}

                                            <img
                                                src={iconInfo}
                                                alt="i"
                                                onClick={() => setShowRunInfo(true)}
                                                className={" ms-2 mt-2"}
                                                width={24}
                                                height={24}
                                            />
                    </span>
                                    )}
                                    <div className={"d-flex "}>
                                        <div className={"d-flex justify-content-center align-items-center"}>
                                            <span className="fw-semibold fs-5">{shownRun.time}</span>
                                        </div>
                                        <div className={"ms-3"}>
                                            <p className="fs-7 mb-0">
                                                {shownRun.no}.KOŞU{" "}
                                                <span
                                                    className="betting-run-info-mobile px-2"
                                                    style={{backgroundColor: courseColors[shownRun.course]}}
                                                >
                          {shownRun.distance}m {shownRun.course}
                        </span>
                                            </p>
                                            <p className="me-4 fs-7 mb-0 mt-1">
                                                {shownRun.group} {shownRun.type_detail || ""} {shownRun.gender || ""}{" "}
                                                {shownRun.gender_detail || ""}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isMobile && (
                                <>
                                    {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true ? (
                                        <>
                                            <div className={"table-responsive"}>
                                                <table className="table betting-table table-borderless">
                                                    <thead>
                                                    <tr>
                                                        <th className="text-center">Bit.</th>
                                                        <th>Atın İsmi / Yaş</th>
                                                        <th className="text-center">Kilo</th>
                                                        <th>Orijin</th>
                                                        <th className="text-start">Jokey</th>
                                                        <th className="text-start">Sah./Antr.</th>
                                                        <th className="text-center">St</th>
                                                        <th className="text-center">Son 6</th>
                                                        <th className="text-center">Derece</th>
                                                        <th className="text-center">Ganyan</th>
                                                        <th className="text-center">AGF(%)</th>
                                                        <th className="text-center">Fark</th>
                                                        <th className="text-center">Geç Çık</th>
                                                        <th className="text-center">Hp</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {shownRun &&
                                                        shownRun.horses &&
                                                        shownRun.horses.map((horse: any, keyHorse: number) => {
                                                            return (
                                                                <React.Fragment key={`DataTable-horses-${keyHorse}`}>
                                                                    <tr>
                                                                        <th className="seperator"></th>
                                                                    </tr>
                                                                    <tr key={`Tjk-races-${keyHorse}`}>
                                                                        <td>
                                                                            <div
                                                                                className="add-to-betslip"
                                                                                style={
                                                                                    Number(horse.result) === 1
                                                                                        ? {
                                                                                            color: "#FFFFFF",
                                                                                            backgroundColor: "var(--bs-danger)",
                                                                                        }
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                {horse.result}
                                                                                {horse.result === null && horse.degree === null ? (
                                                                                    <span
                                                                                        className="text-danger">{isMobile ? "K" : "K"}</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                        <span
                                            className="d-block fw-bold nowrap-ellipsis tooltip"
                                            style={{width: 100}}
                                        >
                                          ({horse.no}) {horse.name}
                                            <div className="tooltip-text">
                                            {horse.name} ({horse.no})
                                          </div>
                                        </span>
                                                                            <GetStablemateIcon
                                                                                horse={horse}/> {horse.age ? horse.age : "-"}{" "}
                                                                            {horse.jewelry && horse.jewelry !== "" && (
                                                                                <span
                                                                                    className="horse-jewelry text-danger">
                                            / {horse.jewelry}
                                                                                    <div
                                                                                        className="text-dark"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: getPrettyFormatJewelry(horse.jewelry),
                                                                                        }}
                                                                                    ></div>
                                          </span>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-nowrap text-center">
                                                                            {horse.weight || "-"}{" "}
                                                                            {Number(horse.extra_weight) > 0 && (
                                                                                <sup className={"text-danger tooltip"}>
                                                                                    +{horse.extra_weight}
                                                                                    <span className="tooltip-text">Fazla Kilo</span>
                                                                                </sup>
                                                                            )}
                                                                        </td>
                                                                        <td className="fw-medium">
                                        <span className="d-block origin-father nowrap-ellipsis" style={{width: 150}}>
                                          {horse.father}
                                        </span>
                                                                            <span
                                                                                className="origin-mother nowrap-ellipsis float-start"
                                                                                style={{width: 75}}
                                                                            >
                                          {horse.mother}
                                        </span>
                                                                            {horse.mother_father && (
                                                                                <span
                                                                                    className="origin-mother nowrap-ellipsis float-start"
                                                                                    style={{width: 75}}
                                                                                >
                                            {" "}
                                                                                    / {horse.mother_father}
                                          </span>
                                                                            )}
                                                                        </td>
                                                                        <td className="fw-medium">
                                                                            {horse.jockey ? (
                                                                                <div style={{width: 100}}>
                                                                                    {/*<img src={`https://medya-cdn.tjk.org/formaftp/${horse.jockey.code}.jpg`} onError={({ currentTarget }) => {*/}
                                                                                    {/*  currentTarget.onerror = null;*/}
                                                                                    {/*  currentTarget.src = jokeyIcon;}} width={24} alt={horse.jockey.short_name} className="jockey-uniform float-start " />*/}
                                                                                    <div
                                                                                        className="float-start nowrap-ellipsis"
                                                                                        style={{
                                                                                            width: 100,
                                                                                            paddingTop: 7,
                                                                                        }}
                                                                                    >
                                                                                        {horse.jockey.short_name}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{width: 100}}>
                                                                                    {/*<img src={`https://medya-cdn.tjk.org/formaftp/${horse.jockey.code}.jpg`} onError={({ currentTarget }) => {*/}
                                                                                    {/*  currentTarget.onerror = null;*/}
                                                                                    {/*  currentTarget.src = jokeyIcon;}} width={24} alt={horse.jockey.short_name} className="jockey-uniform float-start " />*/}
                                                                                    <div
                                                                                        className="float-start nowrap-ellipsis"
                                                                                        style={{
                                                                                            width: 100,
                                                                                            paddingTop: 7,
                                                                                        }}
                                                                                    >
                                                                                        {horse.jokey}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </td>

                                                                        <td className="">
                                        <span className="owner nowrap-ellipsis d-block" style={{width: 80}}>
                                          {horse.owner ? horse.owner : "-"}
                                        </span>
                                                                            <span
                                                                                className="coach nowrap-ellipsis d-block "
                                                                                style={{width: 80}}>
                                          {horse.trainer ? horse.trainer : "-"}
                                        </span>
                                                                        </td>

                                                                        <td className="text-center">{horse.start ? horse.start : "-"}</td>

                                                                        <td
                                                                            className="text-center"
                                                                            dangerouslySetInnerHTML={{__html: getPrettyFormatLastSix(horse.last6)}}
                                                                        ></td>

                                                                        <td className="text-center">{horse.degree}</td>
                                                                        <td className="text-center">{horse.ganyan}</td>
                                                                        <td className={`text-center`}>
                                                                            {horse.AGF1 ? (
                                                                                <span
                                                                                    className={`${
                                                                                        horse.AGF_rank1 === 1 ? "text-success fw-bold d-block" : "d-block"
                                                                                    }`}
                                                                                >
                                            {horse.AGF1} ({horse.AGF_rank1})
                                          </span>
                                                                            ) : null}
                                                                            {horse.AGF2 ? (
                                                                                <span
                                                                                    className={`${
                                                                                        horse.AGF_rank2 === 1 ? "text-success fw-bold d-block" : "d-block"
                                                                                    }`}
                                                                                >
                                            {horse.AGF2} ({horse.AGF_rank2})
                                          </span>
                                                                            ) : null}
                                                                        </td>

                                                                        <td className="text-center">{horse.difference || ""}</td>
                                                                        <td className="text-center">{horse.late_start || "-"}</td>
                                                                        <td className="text-center">{horse.handicap}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-center p-4">
                                                Bu yarış henüz koşulmadı ya da koşuların resmi sonuçlar açıklanmadı.
                                                <br/>
                                                Resmi sonuç bilgileri TJK’dan temin edilmiştir, bu bilgilerin
                                                doğruluğundan 4nala.com sorumlu
                                                değildir.
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                            {isMobile && (
                                <div className="d-flex mb-2" style={{justifyContent: "space-between"}}>
                                    {shownRun?.photo_finish && (
                                        <button
                                            className="btn btn-muhtemeller fw-semibold w-50 fs-7 ms-1"
                                            onClick={() => {
                                                setType("fotofiniş");
                                                setMobileRunModal(shownRun);
                                            }}
                                        >
                                            FOTOFİNİŞ
                                        </button>
                                    )}
                                    {shownRun?.video && (
                                        <button
                                            className="btn btn-muhtemeller fw-semibold w-50 fs-7 ms-1"
                                            onClick={() => {
                                                setShowTrainingVideo(shownRun);
                                            }}
                                        >
                                            VİDEO
                                        </button>
                                    )}
                                    {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true && (
                                        <button
                                            className="btn btn-muhtemeller fw-semibold w-50 fs-7 ms-1"
                                            onClick={() => {
                                                setType("ikramiyeler");
                                                setMobileRunModal(shownRun);
                                            }}
                                        >
                                            İKRAMİYELER
                                        </button>
                                    )}
                                </div>
                            )}
                            {isMobile && (
                                <>
                                    {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true ? (
                                        <>
                                            {shownRun.horses.map((horse: any, keyHorse: number) => {
                                                return (
                                                    <div key={`keyHorse-${keyHorse}`}
                                                         className={`mobile-betting-table `}>
                                                        <div
                                                            className="horse-no"
                                                            style={
                                                                Number(horse.result) === 1
                                                                    ? {
                                                                        color: "#FFFFFF",
                                                                        backgroundColor: "var(--bs-danger)",
                                                                    }
                                                                    : {}
                                                            }
                                                        >
                                                            {horse.result}
                                                            {horse.result === null && horse.degree === null ? (
                                                                <span
                                                                    className="text-danger">{isMobile ? "K" : "K"}</span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>

                                                        <div className="horse-result nowrap-ellipsis">
                                                            <div className={"d-flex justify-content-between"}>
                                                                <div>
                                                                    {horse.no} <span
                                                                    className={"fw-bold"}>{horse.name}</span>
                                                                </div>
                                                                <div>{horse.jockey ? horse.jockey.short_name : "-"}</div>
                                                            </div>
                                                            <div
                                                                className={"d-flex justify-content-between bg-light p-2"}
                                                                style={{borderRadius: "5px"}}
                                                            >
                                                                <div className={"d-flex"}>
                                                                    AGF: &nbsp;{" "}
                                                                    {horse.AGF1 ? (
                                                                        <span
                                                                            className={`${
                                                                                horse.AGF_rank1 === 1 ? "text-success fw-bold d-block" : "d-block fw-bold"
                                                                            }`}
                                                                        >
                                      {horse.AGF1} ({horse.AGF_rank1})
                                    </span>
                                                                    ) : null}
                                                                    {horse.AGF2 ? (
                                                                        <span
                                                                            className={`${
                                                                                horse.AGF_rank2 === 1 ? "text-success fw-bold d-block" : "d-block fw-bold"
                                                                            }`}
                                                                        >
                                      {horse.AGF2} ({horse.AGF_rank2})
                                    </span>
                                                                    ) : null}
                                                                </div>

                                                                <div className={"d-flex"}>
                                                                    Ganyan: &nbsp; <span
                                                                    className={`d-block fw-bold`}>{horse.ganyan}</span>
                                                                </div>
                                                                <div className={"d-flex"}>
                                                                    HP: &nbsp; <span
                                                                    className={`d-block fw-bold`}>{horse.handicap}</span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={"d-flex justify-content-between bg-light mt-2 p-2"}
                                                                style={{borderRadius: "5px"}}
                                                            >
                                                                <div className={"d-flex"}>
                                                                    Derece: &nbsp; <span
                                                                    className={`d-block fw-bold`}>{horse.degree}</span>
                                                                </div>

                                                                <div className={"d-flex"}>
                                                                    Fark: &nbsp; <span
                                                                    className={`d-block fw-bold`}>{horse.difference}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-center p-4">
                                                Bu yarış henüz koşulmadı ya da koşuların resmi sonuçlar açıklanmadı.
                                                <br/>
                                                Resmi sonuç bilgileri TJK’dan temin edilmiştir, bu bilgilerin
                                                doğruluğundan 4nala.com sorumlu
                                                değildir.
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {!isMobile && (
                <div className={"row"}>
                    {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true && (
                        <div className={"col-12 col-lg-12"}>
                            <div className="page-widget mt-3">
                                <h2>İKRAMİYELER</h2>

                                <div className="row m-0">
                                    <div className="col-6">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                            {shownRun.bets
                                                .filter((x: any) => x.bet.includes("GANYAN"))
                                                .map((bet: any, betIndex: number) => {
                                                    return (
                                                        <tr key={`betsIndex-${betIndex}`}>
                                                            <td>
                                                                <b>{bet.bet}</b> {bet.result}
                                                            </td>
                                                            <td className="text-end fw-semibold">{bet.amount}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-6">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                            {shownRun.bets
                                                .filter((x: any) => !x.bet.includes("GANYAN"))
                                                .map((bet: any, betIndex2: number) => {
                                                    return (
                                                        <tr key={`betsIndex-${betIndex2}`}>
                                                            <td>
                                                                <b>{bet.bet}</b> {bet.result}
                                                            </td>
                                                            <td className="text-end fw-semibold">{bet.amount}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {shownRun?.photo_finish ? (
                        <div className={"col-12 col-lg-6"}>
                            <div className={"page-widget mt-3"}>
                                <h2>Fotofiniş</h2>
                                <a
                                    href={shownRun.photo_finish.toString().replace("http://", "https://")}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        alt={"fotofiniş"}
                                        src={shownRun.photo_finish.toString().replace("http://", "https://")}
                                        style={{width: "100%", maxHeight: "360px"}}
                                    />
                                </a>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {shownRun?.video ? (
                        <div className={"col-12 col-lg-6"}>
                            <div className={"page-widget mt-3"}>
                                <h2>Video</h2>
                                <div className={"video-wrapper"}>
                                    <video
                                        className={"video blurEffect"}
                                        key={shownRun.no.toString() + hippodrome.id}
                                        controls
                                        id={"result-video"}
                                        style={{width: "100%"}}
                                    >
                                        <source
                                            src={shownRun && shownRun.video ? shownRun.video.toString().replace("http://", "https://") : null}
                                            type="video/mp4"
                                        />
                                    </video>
                                    <div className="video-content" id={"videoContent"}>
                                        <div
                                            className="play"
                                            onClick={() => {
                                                let stream: any = document.getElementById("result-video");
                                                let videoContent: any = document.getElementById("videoContent");
                                                stream.play();
                                                stream.classList.remove("blurEffect");
                                                videoContent.classList.add("d-none");
                                            }}
                                        >
                                            ►
                                        </div>
                                        <h1>Yarışı izle</h1>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            )}

            <Modal
                isOpen={showRunInfo !== false}
                onRequestClose={() => {
                    setShowRunInfo(false);
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        width: "940px",
                        maxHeight: "calc(100% - 40px)",
                        marginRight: "-50%",
                        maxWidth: " calc( 100% - 40px )",
                        padding: "15px",
                        transform: "translate(-50%, -50%)",
                        background: "var(--bs-white)",
                        borderRadius: "var(--bs-border-radius-xl)",
                        border: 0,
                    },
                    overlay: {
                        zIndex: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10px)",
                    },
                }}
                contentLabel=""
            >
                <img
                    alt={"kapat"}
                    src={iconClose}
                    className="modal-close"
                    onClick={() => {
                        setShowRunInfo(false);
                    }}
                />
                <br/>
                {shownRun && (
                    <div className={"row"}>
                        <div className="col-12 col-lg-7">
                            <h3>
                                <span>{shownRun.no}. Koşu</span> - <span>{shownRun.time}</span>
                            </h3>
                            {shownRun.gender_detail ? (
                                <>
                                    <span>{shownRun.gender_detail}, </span>
                                </>
                            ) : null}
                            <span className="course">{shownRun.course}, </span>
                            <span>{shownRun.distance}m</span>
                            <span>{shownRun.group}</span>
                            {shownRun.gender ? (
                                <>
                                    <span>{shownRun.gender}, </span>
                                </>
                            ) : null}
                            {shownRun.type_detail ? (
                                <>
                                    <span>{shownRun.type_detail}, </span>
                                </>
                            ) : null}

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th className="text-right">İkramiyeler</th>
                                    <th className="text-right">Primler</th>
                                </tr>
                                </thead>
                                <tbody>
                                {shownRun.prices &&
                                    shownRun.prices.map((prices: any, key: any) => {
                                        return (
                                            <tr key={`iprices${key}`}>
                                                <td>{key + 1}</td>
                                                <td className="text-right">
                                                    {CurrencyFormat(prices)}
                                                    {shownRun.currency}
                                                </td>
                                                <td className="text-right">
                                                    {CurrencyFormat(shownRun.bonuses[key])} {shownRun.currency}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <video controls style={{width: "100%"}}>
                                <source
                                    src={shownRun && shownRun.video ? shownRun.video.toString().replace("http://", "https://") : null}
                                    type="video/mp4"
                                />
                            </video>

                            {shownRun.photo_finish ? (
                                <>
                                    <h3>Fotofiniş</h3>
                                    <a
                                        href={shownRun.photo_finish.toString().replace("http://", "https://")}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            alt={"fotofiniş"}
                                            src={shownRun.photo_finish.toString().replace("http://", "https://")}
                                            style={{width: "100%"}}
                                        />
                                    </a>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )}
            </Modal>

            <Modal
                isOpen={showTrainingVideo !== false}
                onRequestClose={() => {
                    setShowTrainingVideo(false);
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        width: "940px",
                        maxHeight: "calc(100% - 40px)",
                        marginRight: "-50%",
                        maxWidth: " calc( 100% - 40px )",
                        padding: "15px",
                        transform: "translate(-50%, -50%)",
                        background: "var(--bs-white)",
                        borderRadius: "var(--bs-border-radius-xl)",
                        border: 0,
                    },
                    overlay: {
                        zIndex: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10px)",
                    },
                }}
                contentLabel=""
            >
                <img
                    alt={"kapat"}
                    src={iconClose}
                    className="modal-close"
                    onClick={() => {
                        setShowTrainingVideo(false);
                    }}
                />
                <div className={"mt-2"}>
                    <h2>
                        {hippodrome?.location} {showTrainingVideo.no}. koşu detayı - {showTrainingVideo.time}
                    </h2>
                    <p>{showTrainingVideo.info}</p>
                </div>
                <video controls autoPlay style={{width: "100%"}}>
                    <source
                        src={
                            showTrainingVideo && showTrainingVideo.video
                                ? showTrainingVideo.video.toString().replace("http://", "https://")
                                : null
                        }
                        type="video/mp4"
                    />
                </video>
            </Modal>

            <Modal
                isOpen={mobileRunModal !== false}
                onRequestClose={() => {
                    setMobileRunModal(false);
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        width: "940px",
                        maxHeight: "calc(100% - 40px)",
                        marginRight: "-50%",
                        maxWidth: " calc( 100% - 40px )",
                        padding: "15px",
                        transform: "translate(-50%, -50%)",
                        background: "var(--bs-white)",
                        borderRadius: "var(--bs-border-radius-xl)",
                        border: 0,
                    },
                    overlay: {
                        zIndex: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10px)",
                    },
                }}
                contentLabel=""
            >
                <div>
                    <img
                        alt={"kapat"}
                        src={iconClose}
                        className="modal-close"
                        onClick={() => {
                            setMobileRunModal(false);
                        }}
                    />
                    <br/>
                    <h2>
                        {hippodrome?.location} {mobileRunModal.no}. koşu detayı - {mobileRunModal.time}
                    </h2>
                    <p>{mobileRunModal.info}</p>
                    {type === "fotofiniş" && (
                        <>
                            {shownRun?.photo_finish ? (
                                <a
                                    href={shownRun.photo_finish.toString().replace("http://", "https://")}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        alt={"fotofiniş"}
                                        src={shownRun.photo_finish.toString().replace("http://", "https://")}
                                        style={{width: "100%"}}
                                    />
                                </a>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                    {type === "ikramiyeler" && (
                        <>
                            {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true && (
                                <div className="row m-0">
                                    <div className="col-12">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                            {shownRun.bets
                                                .filter((x: any) => x.bet.includes("GANYAN"))
                                                .map((bet: any, betIndex: number) => {
                                                    return (
                                                        <tr key={`betsIndex-${betIndex}`}>
                                                            <td>
                                                                <b>{bet.bet}</b> {bet.result}
                                                            </td>
                                                            <td className="text-end fw-semibold">{bet.amount}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-12">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                            {shownRun.bets
                                                .filter((x: any) => !x.bet.includes("GANYAN"))
                                                .map((bet: any, betIndex2: number) => {
                                                    return (
                                                        <tr key={`betsIndex-${betIndex2}`}>
                                                            <td>
                                                                <b>{bet.bet}</b> {bet.result}
                                                            </td>
                                                            <td className="text-end fw-semibold">{bet.amount}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
