import {useEffect, useRef, useState} from "react";
import "./statistics.css";
import {useParams} from "react-router-dom";

export default function Statistics() {
    const params: any = useParams();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [hippodromeKey, setHipodromeKey] = useState("");

    useEffect(() => {
        if (params.hippodrome) {
            setHipodromeKey(params.hippodrome);
        }
    }, [params]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === "RESIZE_IFRAME") {
                if (iframeRef.current) {
                    iframeRef.current.style.height = `${event.data.height}px`;
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <iframe
            ref={iframeRef}
            // onLoad={handleIframeLoad}
            src={`https://tjk-statistics-web.bitrelic.io/?hipodrom=${hippodromeKey}`}
            style={{width: "100%", border: "none", minHeight: "100vh"}}
            title="Resizable Iframe"
        />
    );
}
