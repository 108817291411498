import {useForceUpdate} from "../../../services/misc.functions";
import React, {useEffect} from "react";
import {betSlipActionSubject, getLegsSelections, tjkBetSlipOptions} from "../../../services/betslip.service";
import {isMobile} from "../../../App";

export function BettingDataTabs(prop:any){

  const selectBettingColumn = prop.selectBettingColumn;
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res:any) => {
      forceUpdate();
    });
    return () => {
      betSlipActionSubject_subscription.unsubscribe();
    }
  }, []);


  // console.log("tjkBetSlipOptions", tjkBetSlipOptions)


  if(!tjkBetSlipOptions.bettingSubType){
    return (<></>)
  }

  // console.log("tjkBetSlipOptions.bettingSubType.id)", tjkBetSlipOptions.bettingSubType.id)

  let tabs:any = [];
  for (let i = Number(tjkBetSlipOptions.bettingSubType.id); i < Number(tjkBetSlipOptions.bettingSubType.id)+tjkBetSlipOptions.bettingType.column; i+=1) {
    tabs.push(i)
  }

  let columnType = tjkBetSlipOptions.bettingType.columnLabel === 'Koşu' ? 'run' : 'horse';
  let betSlipLegs = getLegsSelections();

  return (<>
    <div className="betting-run-tabs badge-tabs">
      <ul className="nav nav-tabs">
        {tabs.map((run:any, keyRun:number)=>{
          let columnNo = keyRun+1;
          return (<li key={`runItem-${keyRun}`} className={`nav-item ${columnNo===tjkBetSlipOptions.column?'active':''}`}>
            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none"><use xlinkHref="#tabshape"></use></svg>
            <a className={`nav-link position-relative ${betSlipLegs[keyRun].length > 0 ? 'show-badge' : ''} ${tjkBetSlipOptions.bettingType.columnLabel == 'Ayak' && isMobile ? 'ayak' : ''}`}  onClick={()=>{ selectBettingColumn(columnNo)}}>
              {columnType === 'run' ? tjkBetSlipOptions.bettingSubType.label.split('-')[columnNo-1]: columnNo}{ tjkBetSlipOptions.bettingType.columnLabel == 'Ayak' && isMobile ? columnNo===tjkBetSlipOptions.column  && `.${tjkBetSlipOptions.bettingType.columnLabel}` : `.${tjkBetSlipOptions.bettingType.columnLabel}`}
              {betSlipLegs[keyRun].length > 0 &&
                  <span className={"tab-badge"}>
                {betSlipLegs[keyRun].length}
              </span>}
            </a>
            <svg viewBox="0 0 80 60" preserveAspectRatio="none"><use xlinkHref="#tabshape"></use></svg>
          </li>)
        })}
      </ul>
    </div>
  </>)
}
