import { env } from "../constants/global.vars";
import { ApiService } from "./http.service";
import {
  cartStore,
  cartToggleSubject,
  raffleCategories,
  raffleDraws,
  raffleDrawSubject,
  raffleSubject,
  recoveredCartItems,
} from "../store/raffle.store";
import { toast } from "react-toastify";
import { Cart } from "../models/cart.model";
import _ from "lodash";
import { RaffleModel } from "../models/raffle.model";
import { gtmSelectItem } from "./misc.functions";

export async function getRaffleDraws() {
  const api = new ApiService();
  return await api.start("post", env.accounting_api + "/somos/api/draw/", {}, false).then((res: any) => {
    raffleDraws.splice(0, raffleDraws.length);
    for (let item of res.data) {
      if (recoveredCartItems.includes(item.id)) {
        var _index = cartStore.items.findIndex((el: any) => el.item.id === item.id);
        if (_index > -1) {
          item.is_added = true;
          item.cartIndex = _index;
        } else {
          item.is_added = false;
          item.cartIndex = null;
        }
      } else {
        item.is_added = false;
        item.cartIndex = null;
      }
      item.ticket_price = Number(item.ticket_price);
      raffleDraws.push(item);
    }
    raffleSubject.next({ action: "loaded" });
    return res;
  });
}

export function addToCart(item: any, quantity: number, itemType: string = "raffle", showToast: boolean = true) {
  if (![true, 1].includes(item.status)) {
    toast.warning("Çekiliş Satışa kapalı");
    return null;
  }

  if (quantity > 100) {
    quantity = 100;
    toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
  }
  if (cartStore.items.length >= 10) {
    toast.warning("Tek seferde en fazla 10 çekiliş ekliyebilirsiniz.");
    return false;
  }

  let obj: any;
  obj = cartStore.items.find((el: any) => el.itemType === itemType && el.item.id === item.id);
  if (obj) {
    obj.quantity = Number(obj.quantity) + Number(quantity);

    if (obj.quantity > 100) {
      obj.quantity = 100;
      toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
    }
  } else {
    obj = new Cart(item, quantity, itemType);
    cartStore.items.push(obj);
  }

  item.is_added = true;
  item.cartIndex = cartStore.items.findIndex((el: Cart) => el.item.id === obj.item.id);
  // Storage.set('cartStore', cartStore)
  cartToggleSubject.next({ action: "add", item: obj });
  if (showToast) {
    toast.success("Çekiliş Sepetinize Eklendi.");
  }
}

export function removeFromCart(item: Cart, index: number) {
  cartStore.items.splice(index, 1);
  // Storage.set('cartStore', cartStore)
  raffleDraws.filter((el: any) => {
    if (el.id === item.item.id) {
      el.is_added = false;
      el.cartIndex = null;
    }
    return item;
  });
  updateIndexes();
  cartToggleSubject.next({ action: "remove", list: [item.item.id] });
  // gtmRemoveFromCart([item], 'Çekiliş');
}

export function updateIndexes() {
  for (const cartItem of cartStore.items) {
    cartItem.item.cartIndex = cartStore.items.findIndex((m: Cart) => m.item.id === cartItem.item.id);
  }
  for (const item of raffleDraws) {
    var _index = cartStore.items.findIndex((m: Cart) => m.item.id === item.id);
    if (_index === -1) {
      _index = null;
    }
    if (item.cartIndex !== _index) {
      item.cartIndex = _index;
      raffleDrawSubject.next({ action: "update", id: item.id });
    }
  }
}

export function updateCart(item: Cart, index: any, quantity: number) {
  if (quantity) {
    quantity = parseInt(quantity.toString());
  }

  index = cartStore.items.findIndex((el: Cart) => el.item.id === item.item.id);
  if (quantity <= 0 && quantity.toString() !== "") {
    removeFromCart(item, index);
    return null;
  }

  if (quantity > 100) {
    quantity = 100;
    toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
  }

  cartStore.items[index].quantity = quantity;
  cartStore.items[index].item.cartIndex = index;
  // Storage.set('cartStore', cartStore)
  cartToggleSubject.next({ action: "update", item: item });
}

export function clearCart() {
  let listTrigger: any[] = [...cartStore.items.map((el: Cart) => el.item.id)];
  cartStore.items = [];
  cartStore.couponCode = null;
  cartStore.pointUsage = false;
  // Storage.set("cartStore", cartStore);
  raffleDraws.filter((item: any) => {
    item.is_added = false;
    item.cartIndex = null;
    return item;
  });
  setTimeout(() => {
    cartToggleSubject.next({ action: "clear", list: listTrigger });
  }, 500);
}

export async function orderComplete() {
  let payload: any = {
    unit: !cartStore.pointUsage ? "0" : "2",
    discount_coupon_code: cartStore.couponCode ? cartStore.couponCode.code : null,
    ticket: cartStore.items.map((el: Cart) => {
      return { draw_no: el.item.no, multiply: el.quantity };
    }),
  };

  const api = new ApiService();
  return await api
    .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kupon-olustur/", payload, true)
    .then((res: any) => {
      if (res.status === true) {
        try {
          var items = [];
          for (const item of cartStore.items) {
            items.push({
              item_id: item.item.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
              item_name: item.item.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
              item_category: "{{Çekiliş}}",
              price: item.item.draw_price,
              quantity: item.quantity,
            });
          }
        } catch (e: any) {
          console.log(e);
        }
      }
      return res;
    })
    .catch((err: any) => {
      return { status: false, error: "timeout", message: err, data: null };
    });
}

export async function checkCouponCode(code: any) {
  const api = new ApiService();
  return await api
    .start("post", `${env.accounting_api}/misc/api/check-discount-coupons/`, { data: code }, true)
    .then((res: any) => {
      if (res.status === true) {
        return res;
      }
    })
    .catch((err: any) => {
      return { status: false, error: "timeout", message: err, data: null };
    });
}

export async function getRaffleCategories() {
  const api = new ApiService();
  return await api
    .start("get", env.accounting_api + "/somos/api/draw-categories/", null, false)
    .then((res: any) => {
      if (res && res.status) {
        raffleCategories.splice(0, raffleCategories.length);
        raffleCategories.push(..._.orderBy(res.data, ["sort"], ["asc"]));
      }
      return [];
    })
    .catch((err: any) => {
      // fixme log error
      // console.log(err)
      return [];
    });
}

export function openRaffleDetail(raffle: RaffleModel) {
  gtmSelectItem([raffle], "Çekiliş");
  raffleDrawSubject.next({ action: "open", id: raffle.id });
}
